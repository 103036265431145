import { messageActions } from 'modules/react-message';
import Immutable from 'seamless-immutable';
import apiFetch from 'utils/fetch';
import * as appActions from './app';

const cfg = process.env;

// action types
export const actionTypes = {
  UPDATE_SURVEYS: 'volkno/surveys/UPDATE_SURVEYS',
  UPDATE_ANSWERS: 'volkno/surveys/UPDATE_ANSWERS',
  IS_BUSY: 'volkno/surveys/IS_BUSY',
};

// reducers
const initialState = Immutable({
  list: [],
  data: {},
  answers: {},
  busy: true,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_SURVEYS:
      return state.merge(
        {
          ...action.payload,
          busy: false,
        },
        { deep: true }
      );

    case actionTypes.UPDATE_ANSWERS:
      return state.set('answers', action.payload);

    case actionTypes.IS_BUSY:
      return state.merge(
        {
          busy: true,
        },
        { deep: true }
      );

    default:
      return state;
  }
}

// action creators
export function getSurveys() {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    dispatch({ type: actionTypes.IS_BUSY });
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/surveys`,
    };
    apiFetch('get', options, dispatch)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: actionTypes.UPDATE_SURVEYS,
            payload: resp.json.surveys,
          });
        } else {
          dispatch(messageActions.displayError(resp.json.error));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
export function getAnswers(surveyId) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/surveys/${surveyId}`,
    };
    apiFetch('get', options, dispatch)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: actionTypes.UPDATE_ANSWERS,
            payload: resp.json.answers,
          });
        } else {
          dispatch(messageActions.displayError(resp.json.error));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
export function submitAnswers(surveyId, answers, cb = () => {}) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/surveys/${surveyId}`,
      body: {
        answers,
      },
    };
    apiFetch('post', options, dispatch)
      .then((resp) => {
        if (resp.status === 200) {
          if (resp.json.newCampaign) {
            dispatch(appActions.get());
          }
          cb();
        } else {
          dispatch(messageActions.displayError(resp.json.error));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
