import constants from './constants';

export default {
  displayError(error) {
    console.error(error);//eslint-disable-line
    return { type: constants.DISPLAY_ERROR, error };
  },
  clearError() {
    return { type: constants.CLEAR_ERROR };
  },
  displaySuccess(success) {
    return { type: constants.DISPLAY_SUCCESS, success };
  },
  clearSuccess() {
    return { type: constants.CLEAR_SUCCESS };
  },
  clearAll() {
    return { type: constants.CLEAR_ALL };
  },
};
