import React from 'react';
import PropTypes from 'prop-types';
import 'styles/core.scss';
import { messageActions } from 'modules/react-message';
import * as openActions from 'ducks/open';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { facebookLogin } from 'modules/react-login/ducks/login';
import DocumentMeta from 'react-document-meta';
import cookie from 'react-cookie';

const cfg = process.env;

@withRouter
class CoreLayout extends React.Component {
  static propTypes = {
    children: PropTypes.element,
    location: PropTypes.object,
    params: PropTypes.object,
    route: PropTypes.object,
    router: PropTypes.object,
    dispatch: PropTypes.func,
  };
  static childContextTypes = {
    url: PropTypes.object,
    router: PropTypes.object,
  };
  getChildContext() {
    return {
      url: {
        path: this.props.location.pathname,
        search: this.props.location.search,
        query: this.props.location.query,
        hash: this.props.location.hash,
        params: this.props.params,
        route: this.props.route,
      },
      router: this.props.router,
    };
  }
  getPathFromHash() {
    const { hash = '' } = this.props.location;
    const hashClipped = hash.substr(1, hash.length);
    return hashClipped;
  }

  componentWillMount() {
    const { dispatch, router } = this.props;
    const hash = this.getPathFromHash();
    const { code = '', promo = '' } = this.props.location.query;
    if (code.length) {
      dispatch(
        facebookLogin({ code, redirect_uri: cfg.REACT_APP_CLIENT_HOST }, () => {
          window.FB.logout();
        })
      );
    }
    if (promo.length > 0 && /^[a-zA-Z0-9-_]+$/.test(promo)) {
      cookie.save('promo', promo, { path: '/' });
    }
    if (hash) {
      router.replace(hash === '_=_' ? '/' : hash);
    }
    dispatch(openActions.get());
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.props.dispatch(messageActions.clearAll());
    }
  }

  render() {
    const { children } = this.props;
    const hash = this.getPathFromHash();
    if (hash) {
      return null;
    }
    return (
      <div className="H-100">
        <DocumentMeta title="volKno" />
        {children}
      </div>
    );
  }
}

export default connect()(CoreLayout);
