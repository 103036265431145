import React from 'react';
import PropTypes from 'prop-types';

export default function formsyErrorFix(Component) {
  return class FormsyErrorHandlingFix extends React.Component {
    static propTypes = {
      getErrorMessage: PropTypes.func,
      isRequired: PropTypes.func,
      isPristine: PropTypes.func,
      showError: PropTypes.func,
      showRequired: PropTypes.func,
      getValue: PropTypes.func,
      title: PropTypes.string,
    };
    getMessage() {
      const {
        getErrorMessage,
        isRequired,
        title = 'value',
        validations,
        getValue,
      } = this.props;
      if (validations === 'PASSWORD') {
        let pw = getValue() || '';
        if (pw.length < 8) {
          return 'Password must be at least 8 characters';
        }
        if (!/\d+/.test(pw)) {
          return 'Password must be contain a number';
        }
        if (!/[a-z]/.test(pw.toLowerCase())) {
          return 'Password must be contain a letter';
        }
      }
      return (
        getErrorMessage() ||
        (isRequired() && 'Enter ' + title.toLowerCase()) ||
        false
      );
    }
    getErrorText() {
      const {
        isRequired,
        isPristine,
        showError,
        showRequired,
        getValue,
      } = this.props;
      if (isPristine()) {
        return false;
      }
      if (isRequired()) {
        return showError() || showRequired() ? this.getMessage() : false;
      } else {
        return getValue() && showError() ? this.getMessage() : false;
      }
    }
    // Override formsy's showError because it returns true as soon as
    // the input is invalid (which for required fields is immediately)
    // Instead we return true if the field is invalid AND the form
    // has attempted to be submitted
    onShowError() {
      return (
        !this.props.disabled &&
        !this.props.isValid() &&
        this.props.isFormSubmitted()
      );
    }
    render() {
      return (
        <Component
          {...this.props}
          showError={this.onShowError.bind(this)}
          getErrorText={this.getErrorText.bind(this)}
        />
      );
    }
  };
}
