import { combineReducers } from 'redux';
import { reducers as reactLoginReducer } from 'modules/react-login';
import { messageReducer } from 'modules/react-message';
import app from '../ducks/app';
import media from '../ducks/media';
import results from '../ducks/results';
import earnings from '../ducks/earnings';
import cart from '../ducks/cart';
import survey from '../ducks/survey';
import open from '../ducks/open';
import surveys from '../ducks/surveys';
import { volknoSurveyReducer } from 'ducks/volkno-surveys/volkno-surveys';

export const reducers = (asyncReducers) => {
  return combineReducers({
    app,
    media,
    results,
    earnings,
    cart,
    survey, // TBD - after refactor to use surveys (surveyjs)
    surveys,
    open,
    loginState: reactLoginReducer,
    message: messageReducer,
    volknoSurvey: volknoSurveyReducer,
    ...asyncReducers,
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;
  store.asyncReducers[key] = reducer;
  store.replaceReducer(reducers(store.asyncReducers));
};

export default reducers;
