import apiFetch from '../utils/fetch';
import cookie from 'react-cookie';
import { messageActions } from 'modules/react-message';
import * as appActions from 'ducks/app';
const cfg = process.env;

// constants
export const actionTypes = {
  ATTEMPT: 'account/login/ATTEMPT',
  FAIL: 'account/login/FAIL',
  SUCCESS: 'account/login/SUCCESS',
  LOGOUT: 'account/login/LOGOUT',
  SET_CINT_URLS: 'account/login/SET_CINT_URLS',
};

// reducers
const initialState = {
  isLoggingIn: false,
  isLoggedIn: !!cookie.load('jwtToken'),
  userData: {},
  cintURLs: {},
  publicLoginError: false,
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ATTEMPT:
      return {
        ...state,
        isLoggingIn: true,
        userData: {},
        publicLoginError: false,
      };

    case actionTypes.FAIL:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        userData: {},
        publicLoginError: action.payload.publicLoginError,
      };

    case actionTypes.SET_CINT_URLS:
      return {
        ...state,
        cintURLs: action.payload,
      };

    case actionTypes.SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        publicLoginError: false,
        userData: action.payload,
      };

    case actionTypes.LOGOUT:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        publicLoginError: false,
        userData: {},
      };

    default:
      return state;
  }
}

// action creators
export function logout(cb = () => {}) {
  cookie.remove('jwtToken', { path: '/' });
  return (dispatch) => {
    dispatch({ type: actionTypes.LOGOUT });
    cb();
  };
}

export function loginError(publicLoginError) {
  cookie.remove('jwtToken', { path: '/' });
  return {
    type: actionTypes.FAIL,
    payload: { publicLoginError: !!publicLoginError },
  };
}

export function loginSuccess(token) {
  return (dispatch) => {
    cookie.save('jwtToken', token, { path: '/' });
    const tokenSplit = token.split('.');
    const data = JSON.parse(atob(tokenSplit[1]));
    dispatch({ type: actionTypes.SUCCESS, payload: data });
  };
}

export function setCINTUrls(data) {
  return {
    type: actionTypes.SET_CINT_URLS,
    payload: data,
  };
}

export function loginAttempt() {
  return { type: actionTypes.ATTEMPT };
}

export function loginCheck() {
  const jwtToken = cookie.load('jwtToken');
  return (dispatch) => {
    dispatch(loginAttempt());
    return apiFetch(dispatch, cfg.REACT_APP_API_BASE + '/account/status', {
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }).then((response) => {
      if (response.status === 200) {
        dispatch(loginSuccess(response.json.token));
      } else {
        dispatch(logout());
      }
    });
  };
}

export function loginCall(userData, onFailure) {
  return (dispatch) => {
    dispatch(loginAttempt());
    dispatch(appActions.clearAudience());
    dispatch(messageActions.clearAll());
    return apiFetch(dispatch, cfg.REACT_APP_API_BASE + '/account/login', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        app: userData.app,
        email: userData.email.trim(),
        password: userData.password,
        promo: cookie.load('promo'),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(loginSuccess(response.json.token));
        } else if (response.json.error === 'expired_pw') {
          dispatch(logout());
          dispatch(messageActions.displayError(response.json.error));
          throw new Error('Need to fix redirect code');
        } else {
          dispatch(messageActions.displayError(response.json.error));
          dispatch(loginError());
          onFailure(response);
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
        dispatch(loginError(true));
      });
  };
}

function doPublicLogin(dispatch, cintURLs) {
  apiFetch(dispatch, cfg.REACT_APP_API_BASE + '/account/publicLogin', {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      url: window.location.href,
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(loginSuccess(response.json.token));
      } else {
        console.log('response.json.error', response.json.error);
        if (response.json.error === 'invalid_values') {
          window.location.href = cintURLs.failURL;
        }
        dispatch(messageActions.displayError(response.json.error));
        dispatch(loginError(true));
      }
    })
    .catch((error) => {
      dispatch(messageActions.displayError(error));
      dispatch(loginError(true));
    });
}

export function publicLogin(query) {
  return (dispatch) => {
    dispatch(loginAttempt());
    dispatch(messageActions.clearAll());
    apiFetch(dispatch, cfg.REACT_APP_API_BASE + '/account/cintUrls', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        RID: query.RID,
        RSFN: query.RSFN,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setCINTUrls(response.json));
          doPublicLogin(dispatch, response.json);
        } else {
          dispatch(messageActions.displayError(response.json.error));
          dispatch(loginError(true));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
        dispatch(loginError(true));
      });
  };
}

export function confirmEmail(hash) {
  return (dispatch) => {
    dispatch(loginAttempt());
    dispatch(messageActions.clearAll());
    return apiFetch(
      dispatch,
      cfg.REACT_APP_API_BASE + '/account/confirmEmail',
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          hash,
        }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch(loginSuccess(response.json.token));
        } else {
          dispatch(messageActions.displayError(response.json.error));
          dispatch(loginError());
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
        dispatch(loginError());
      });
  };
}

export function facebookLogin(payload, onFailure) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    dispatch(appActions.clearAudience());
    return apiFetch(
      dispatch,
      cfg.REACT_APP_API_BASE + '/account/facebookLogin',
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...payload,
          promo: cookie.load('promo'),
        }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch(loginSuccess(response.json.token));
        } else {
          dispatch(messageActions.displayError(response.json.error));
          dispatch(loginError());
          onFailure(response);
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
        dispatch(loginError());
      });
  };
}
