import { SurveyConfig, SurveyResult } from 'modules/survey-volkno/types';

export enum SurveyLoadingStatus {
  LOADING = 'loading',
  FINISHED = 'finished',
  ERROR = 'error',
}

export interface VolknoSurveysState {
  surveys: {
    [key: string]: {
      survey?: SurveyConfig;
      status: SurveyLoadingStatus;
    };
  };
  surveyResults: {
    surveyId: string;
    meta: {
      mediaId: number;
      mediaItemId: number;
    };
    result: SurveyResult;
    uploaded: boolean;
  }[];
}
