import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SurveyConfig, SurveyResult } from 'modules/survey-volkno/types';
import { SurveyLoadingStatus, VolknoSurveysState } from './types';
import { VolknoSurveyApi, volknoSurveyApiFactory } from './volkno-surveys-api';

const defaultVolknoSurveyApi = volknoSurveyApiFactory();

const initialState: VolknoSurveysState = {
  surveys: {},
  surveyResults: [],
};

const fetchSurveyByIdThunkFactory = (
  surveyApi: VolknoSurveyApi = defaultVolknoSurveyApi
) =>
  createAsyncThunk<SurveyConfig, string>(
    'volknoSurvey/fetchById',
    async (surveyId) => {
      return surveyApi.fetchById(surveyId);
    }
  );

const submitSurveyThunkFactory = (
  surveyApi: VolknoSurveyApi = defaultVolknoSurveyApi
) =>
  createAsyncThunk<
    void,
    {
      surveyId: string;
      result: SurveyResult;
      mediaId: number;
      mediaItemId: number;
    }
  >(
    'volknoSurvey/submitResult',
    async ({ surveyId, result, mediaId, mediaItemId }) =>
      surveyApi.submitResult(surveyId, result, mediaId, mediaItemId)
  );

const fetchSurveyById = fetchSurveyByIdThunkFactory();
const submitSurvey = submitSurveyThunkFactory();
const volknoSurveySlice = createSlice({
  name: 'volknoSurvey',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchSurveyById.fulfilled,
      (state, { payload: surveyConfig }) => {
        state.surveys[surveyConfig.id] = {
          survey: surveyConfig,
          status: SurveyLoadingStatus.FINISHED,
        };
      }
    );
    builder.addCase(
      fetchSurveyById.pending,
      (state, { meta: { arg: surveyId } }) => {
        if (!state.surveys[surveyId]) {
          state.surveys[surveyId] = {
            status: SurveyLoadingStatus.LOADING,
          };
        }
      }
    );
    builder.addCase(
      fetchSurveyById.rejected,
      (state, { meta: { arg: surveyId } }) => {
        state.surveys[surveyId] = {
          status: SurveyLoadingStatus.ERROR,
        };
      }
    );
    builder.addCase(
      submitSurvey.pending,
      (
        state,
        {
          meta: {
            arg: { mediaId, mediaItemId, result, surveyId },
          },
        }
      ) => {
        state.surveyResults.push({
          meta: {
            mediaId,
            mediaItemId,
          },
          result,
          surveyId,
          uploaded: false,
        });
      }
    );
    builder.addCase(
      submitSurvey.fulfilled,
      (
        state,
        {
          meta: {
            arg: { surveyId },
          },
        }
      ) => {
        const surveyResultIndex = state.surveyResults.findIndex(
          (s) => surveyId === s.surveyId
        );
        state.surveyResults[surveyResultIndex].uploaded = true;
      }
    );
  },
});

const volknoSurveyReducer = volknoSurveySlice.reducer;

export { volknoSurveyReducer, fetchSurveyById, submitSurvey };
