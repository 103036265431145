import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as appActions from 'ducks/app';
import { Form, Input, DateSelect, Select } from 'modules/react-forms';
import { formatErrors } from 'modules/utils-validation';
import * as earningsActions from 'ducks/earnings';
import './styles.scss';

class ProfileSettings extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      fname: props.app.user.fname || '',
      lname: props.app.user.lname || '',
    };
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    reader.onloadend = () => {
      this.props.dispatch(appActions.uploadProfileImg(file, reader.result));
    };
    reader.readAsDataURL(file);
  }
  handleSubmit(data, resetForm, handleErrors) {
    const payload = Object.keys(data).reduce((prev, curr) => {
      // eslint-disable-next-line no-prototype-builtins
      if (data[curr] && data[curr].hasOwnProperty('value')) {
        prev[curr] = data[curr].value;
      } else {
        prev[curr] = data[curr];
      }
      return prev;
    }, {});
    payload.ethnicity = payload.ethnicity.map((o) => (o.value ? o.value : o));
    const { dispatch } = this.props;
    function onSuccess() {
      dispatch(earningsActions.getAll());
      dispatch(appActions.get());
    }
    function onFailure(resp) {
      if (resp.status === 406) {
        handleErrors(formatErrors(resp.json.errorData));
      }
    }
    dispatch(earningsActions.alertShown(null, 'PROFILE')); // prevent future incentive alert
    dispatch(appActions.saveUserInfo(payload, onSuccess, onFailure));
  }
  render() {
    const { user, optionData } = this.props.app;
    const bday = user.birthdate ? new Date(user.birthdate).getTime() : null;
    const ethnicityValueMap = (optionData.ethnicities || []).reduce(
      (prev, curr) => {
        prev[curr.value] = curr.label;
        return prev;
      },
      {}
    );
    let ethnicities = [];
    try {
      // handle array of strings
      ethnicities = JSON.parse(user.ethnicity);
    } catch {
      // Handle single values
      if (String(user.ethnicity).length === 1) {
        ethnicities.push(user.ethnicity);
      }
    }
    return (
      <Form
        onValidSubmit={this.handleSubmit.bind(this)}
        className="profile-settings"
      >
        <div className="row">
          <div className="col-sm-2 mobile-center">
            <label htmlFor="upload" className="upload-container">
              <div className="user-pic">
                {user.profile_img ? (
                  <img alt="" src={user.profile_img} className="D-b W-100" />
                ) : (
                  <i className="fa fa-user"></i>
                )}
              </div>
              <div className="upload-txt">Upload Photo</div>
              <input
                onChange={this.handleImageChange.bind(this)}
                type="file"
                id="upload"
                style={{ display: 'none' }}
              />
            </label>
          </div>
          <div className="col-sm-10">
            <div className="row">
              <div className="col-sm-8">
                <Input
                  title="Email"
                  type="email"
                  name="email"
                  defaultValue={user.email}
                  placeholder="Enter email"
                  validations="EMAIL"
                  validationError="Enter email"
                  disabled
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <Input
                  title="First Name"
                  type="input"
                  name="fname"
                  defaultValue={this.state.fname}
                  placeholder="Enter first name"
                  validations="TEXT"
                  validationError="Enter first name"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <Input
                  title="Last Name"
                  type="input"
                  name="lname"
                  defaultValue={this.state.lname}
                  placeholder="Enter last name"
                  validations="TEXT"
                  validationError="Enter last name"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <DateSelect
                  name="birthdate"
                  value={bday}
                  title="Date of Birth"
                  startYear={1900}
                  yearDir="DESC"
                  validations="TIMESTAMP"
                  validationError="Enter birthdate"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <Select
                  title="Gender"
                  name="sex"
                  value={user.sex}
                  placeholder="Select sex"
                  clearable={false}
                  required
                >
                  {optionData.genders}
                </Select>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <Select
                  title="Race / Ethnicity"
                  name="ethnicity"
                  placeholder="Select all that apply"
                  value={ethnicities}
                  multi
                  valueRenderer={(o) => (
                    <span>{ethnicityValueMap[o.value]}</span>
                  )}
                  clearable
                >
                  {optionData.ethnicities}
                </Select>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <Select
                  title="Relationship Status"
                  name="relationship"
                  value={user.relationship}
                  placeholder="Select status"
                  clearable={false}
                  required
                >
                  {optionData.relationship}
                </Select>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <Select
                  title="Parental Status"
                  name="parent"
                  value={user.parent}
                  clearable={false}
                  required
                >
                  {optionData.parentalStatus}
                </Select>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <Select
                  title="Household Income"
                  name="income"
                  value={user.income}
                  placeholder="Select household income"
                  clearable={false}
                  required
                >
                  {optionData.income}
                </Select>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <Select
                  title="How often would you like to receive emails?"
                  name="email_updates"
                  value={user.email_updates}
                  clearable={false}
                  required
                >
                  {[
                    {
                      value: 'Daily',
                      label: 'Daily',
                    },
                    {
                      value: 'Weekly',
                      label: 'Weekly',
                    },
                    {
                      value: 'Monthly',
                      label: 'Monthly',
                    },
                  ]}
                </Select>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <button
                  type="submit"
                  className="btn btn-primary My-10 mobile-100"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default connect((state) => ({
  app: state.app,
}))(ProfileSettings);
