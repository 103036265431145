import React from 'react';

export const DataManagementPage: React.FC = () => {
  return (
    <div className="page-margin">
      <h2>Data Management and Data Privacy</h2>
      <p>
        You may access or erase your data. You can request this at any time by
        emailing us at{' '}
        <a href="mailto:dataprivacy@volkno.com">dataprivacy@volkno.com</a>
      </p>
      <p>
        We store data until we don't need it to provide our services or until
        your account is deleted - whichever happens first.
      </p>
    </div>
  );
};
