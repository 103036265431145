import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { Link } from 'react-router';
import { Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as appActions from 'ducks/app';
import * as mediaActions from 'ducks/media';
import * as earningsActions from 'ducks/earnings';
import './styles.scss';

class Header extends React.Component {
  static propTypes = {
    app: PropTypes.object,
    toggleSidebar: PropTypes.func,
    docked: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    logout: PropTypes.any,
  };
  static contextTypes = {
    router: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      newCampaign: this.needsNotification(props),
    };
  }
  componentWillReceiveProps(nextProps) {
    const { dispatch } = nextProps;
    const needsToBeNotified = this.needsNotification(nextProps);
    if (!this.state.newCampaign && needsToBeNotified) {
      this.setState({
        newCampaign: true,
      });
      dispatch(earningsActions.manualAlert('CAMPAIGN_UNLOCKED'));
    }
  }
  needsNotification(props) {
    const {
      app: { audiences = {} },
    } = props;
    return (audiences.list || []).some(
      (userAudienceId) => !audiences.data[userAudienceId].notified
    );
  }
  componentWillMount() {
    const {
      app: { audiences = {}, audience },
    } = this.props;
    const audienceInList = (audiences.list || []).some(
      (userAudienceId) => audiences.data[userAudienceId].hash === audience
    );
    if (!audienceInList) {
      this.props.dispatch(appActions.clearAudience());
    }
  }
  onAudienceChange(e) {
    const { dispatch } = this.props;
    dispatch(mediaActions.clear());
    dispatch(appActions.setAudience(e.value));
    if (this.state.newCampaign) {
      dispatch(
        appActions.audienceNotified(() => {
          dispatch(appActions.get());
        })
      );
    }
    this.setState({ newCampaign: false });
    this.context.router.push('/?r=' + Date.now());
  }
  closeTooltip() {
    this.setState({
      newCampaign: false,
    });
  }
  optionRenderer(option) {
    return (
      <div>
        {option.label}
        {!option.notified ? <i className="fa fa-star"></i> : null}
      </div>
    );
  }
  render() {
    const {
      toggleSidebar,
      docked,
      logout,
      isLoggedIn,
      surveys,
      app: { audiences = {}, audience },
    } = this.props;

    const { newCampaign } = this.state;
    const options = (audiences.list || []).map((userAudienceId) => ({
      value: audiences.data[userAudienceId].hash,
      label: audiences.data[userAudienceId].name,
      notified: audiences.data[userAudienceId].notified,
    }));
    const newSurveyCnt = surveys.list.reduce((prev, surveyId) => {
      prev += surveys.data[surveyId].completed ? 0 : 1;
      return prev;
    }, 0);
    return (
      <header>
        {!docked ? (
          <div className="sb-toggle-icon" onClick={toggleSidebar}>
            <i className="fa fa-bars"></i>
            {newSurveyCnt ? (
              <span className="notify">{newSurveyCnt}</span>
            ) : null}
          </div>
        ) : null}

        <div className="left-actions"></div>
        <div className="right-actions">
          <div className="links">
            <Link to="/help">Help</Link>
            {isLoggedIn ? (
              <span>
                <span className="vrule"></span>
                <span onClick={logout} className="link">
                  Log Out
                </span>
              </span>
            ) : null}
          </div>
          {options.length > 1 ? (
            <div
              className="select-audience"
              onClick={this.closeTooltip.bind(this)}
            >
              <i className="fa fa-exchange" title="Switch audiences"></i>
              <ReactSelect
                value={audience}
                options={options}
                placeholder="Select a campaign"
                onChange={this.onAudienceChange.bind(this)}
                optionRenderer={this.optionRenderer.bind(this)}
                clearable={false}
                allowCreate={false}
                className="Select-white"
                searchable
              />
              {newCampaign ? (
                <div className="tt-contain">
                  <Tooltip
                    placement="bottom"
                    className="in new-campaign-tt"
                    id="new-campaign-tt"
                  >
                    You have a new campaign!
                  </Tooltip>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </header>
    );
  }
}
export default connect((state) => ({
  app: state.app,
  surveys: state.surveys,
}))(Header);
