import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function multiplyComponent(Component) {
  return class Mulitply extends React.Component {
    static propTypes = {
      value: PropTypes.array,
      minNumToDisplay: PropTypes.number,
    };
    constructor(props) {
      super(props);
      this.state = this.getStateFromProps(props);
    }
    getStateFromProps(props) {
      return {
        fields: this.padLength(props.value || []),
      };
    }
    shouldComponentUpdate(nextProps) {
      if (!_.eq(this.props.value, nextProps.value)) {
        this.updateState(nextProps);
        return false;
      }
      return true;
    }
    updateState(props) {
      this.setState(this.getStateFromProps(props));
    }
    padLength(currentFields) {
      const { minNumToDisplay = 0 } = this.props;
      const remaining = minNumToDisplay - currentFields.length || 0;
      for (let i = 0; i < remaining; i++) {
        currentFields.push({
          tempId: 'tmp_' + Date.now() + '_' + i,
        });
      }
      return currentFields;
    }
    repeatComponent(componentHandler) {
      return this.state.fields.map(componentHandler);
    }
    getState() {
      return this.state.fields;
    }
    deleteComponent(index) {
      let { fields } = this.state;
      fields.splice(index, 1);
      fields = this.padLength(fields);
      this.setState({
        fields: fields,
      });
    }
    addComponent(data) {
      this.setState({
        fields: [
          ...this.state.fields,
          data || {
            tempId: 'tmp_' + Date.now(),
          },
        ],
      });
    }
    moveUp(index) {
      const { fields } = this.state;
      if (index === 0) {
        return;
      }
      const currentField = fields[index];
      fields[index] = fields[index - 1];
      fields[index - 1] = currentField;
      this.setState({
        fields: [...fields],
      });
    }
    moveDown(index) {
      const { fields } = this.state;
      if (index === fields.length - 1) {
        return;
      }
      const currentField = fields[index];
      fields[index] = fields[index + 1];
      fields[index + 1] = currentField;
      this.setState({
        fields: [...fields],
      });
    }
    render() {
      return (
        <Component
          {...this.props}
          moveDown={this.moveDown.bind(this)}
          moveUp={this.moveUp.bind(this)}
          deleteComponent={this.deleteComponent.bind(this)}
          addComponent={this.addComponent.bind(this)}
          repeatComponent={this.repeatComponent.bind(this)}
          getState={this.getState.bind(this)}
          updateState={this.updateState.bind(this)}
        />
      );
    }
  };
}
