import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import { Hot, Not } from 'components/common/Icons';

class ChallengeHotNot extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onHotClick = () => {
    const { location, onChange } = this.props;

    onChange('HOT_OR_NOT', 'hot', null, location);
  };

  onNotClick = () => {
    const { location, onChange } = this.props;

    onChange('HOT_OR_NOT', 'not', null, location);
  };

  render() {
    const { location } = this.props;
    return (
      <div
        className={`slidein-onhover Mb-20 hotnot-container ${location || ''}`}
      >
        <Hot className="D-ib Va-m Mr-20 hot-btn" onClick={this.onHotClick} />
        <Not className="D-ib Va-m" onClick={this.onNotClick} />
      </div>
    );
  }
}

export default ChallengeHotNot;
