import React from 'react';
import PropTypes from 'prop-types';
import { InputHidden } from 'modules/react-forms';
import multiplyComponent from 'components/utils/multiplyComponent';

@multiplyComponent
class Option extends React.Component {
  static propTypes = {
    repeatComponent: PropTypes.func,
    moveUp: PropTypes.func,
    moveDown: PropTypes.func,
  };
  render() {
    const { repeatComponent, moveUp, moveDown } = this.props;
    return (
      <div>
        {repeatComponent(function (value, index) {
          const idx = value.value;
          return (
            <div className="row sortable-option" key={'option_key_' + idx}>
              <div className="col-8">
                {value.label}
                <InputHidden name={'most_often[' + index + ']'} value={idx} />
              </div>
              <div className="col-4 Ta-r">
                <span
                  className="fa fa-chevron-up Cur-p Fz-18 Mr-15"
                  onClick={moveUp.bind(this, index)}
                ></span>
                <span
                  className="fa fa-chevron-down Cur-p Fz-18"
                  onClick={moveDown.bind(this, index)}
                ></span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
export default Option;
