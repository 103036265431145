import React from 'react';
import PropTypes from 'prop-types';
import { createMessageComponent } from 'modules/react-message';
import Footer from 'components/common/Footer';
import strings from 'config/strings';

const MessageBar = createMessageComponent(strings);

class OneColumn extends React.Component {
  static propTypes = {
    children: PropTypes.element,
  };
  render() {
    return (
      <div className="layout-1col H-100">
        <MessageBar />
        <div className="container">
          <div className="row">{this.props.children}</div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default OneColumn;
