import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import cookie from 'react-cookie';
import formsyErrorFix from './formsyErrorFix';
import cx from 'classnames';
import { messageActions } from 'modules/react-message';
import { connect } from 'react-redux';

// formsy file upload

@formsyErrorFix
class FileUpload extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    folder: PropTypes.string,
    setValue: PropTypes.func,
    dispatch: PropTypes.func,
    getValue: PropTypes.func,
    isFormDisabled: PropTypes.func,
    getErrorText: PropTypes.func,
    title: PropTypes.any,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    public: PropTypes.bool,
    imageSizes: PropTypes.object,
    uploadAPI: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      percentUploaded: null,
    };
  }
  upload(fileName, fileType, fileData) {
    const self = this;
    return new Promise((resolve, reject) => {
      self.xhr = new XMLHttpRequest();
      self.xhr.open('POST', self.props.uploadAPI, true);
      self.xhr.onload = function () {
        if (this.status === 200) {
          try {
            const results = JSON.parse(this.response);
            resolve(results.uploadId);
          } catch (e) {
            reject('file_upload_invalid_success_resp', e);
          }
        } else {
          try {
            const results = JSON.parse(this.response);
            reject(results.error);
          } catch (e) {
            reject('file_upload_invalid_error_resp', e);
          }
        }
        self.setState({
          percentUploaded: 100,
        });
      };
      self.xhr.upload.addEventListener('loadstart', () => {}, false);
      self.xhr.upload.addEventListener(
        'progress',
        (e) => {
          if (e.total !== 0) {
            self.setState({
              // dont base off 100% because user still needs to
              // wait for server-side s3 upload before submitting
              percentUploaded: parseInt((e.loaded / e.total) * 90, 10),
            });
          }
        },
        false
      );
      self.xhr.setRequestHeader('Content-Type', 'application/json');
      // TODO: since it's a cookie now, shouldnt we just read from that on server?
      self.xhr.setRequestHeader(
        'Authorization',
        'Bearer ' + cookie.load('jwtToken')
      );
      self.xhr.send(
        JSON.stringify({
          fileData: fileData,
          fileName: fileName,
          fileType: fileType,
          public: self.props.public,
          imageSizes: self.props.imageSizes,
          folder: self.props.folder || '',
        })
      );
      self.setState({
        percentUploaded: 1,
      });
    });
  }
  cancelUpload() {
    if (this.xhr) {
      this.xhr.abort();
    }
    this.setState({
      percentUploaded: null,
    });
    this.props.setValue(null);
  }
  onChange(e) {
    const reader = new FileReader();
    const file = e.target.files[0];
    const { dispatch } = this.props;
    if (file) {
      reader.onloadend = () => {
        this.upload(file.name, file.type, reader.result)
          .then((uploadId) => {
            this.props.setValue(uploadId);
          })
          .catch((errCode) => {
            dispatch(messageActions.displayError(errCode));
            this.props.setValue(null);
          });
      };
      reader.readAsDataURL(file);
    } else {
      this.props.setValue(null);
    }
  }
  render() {
    const {
      name,
      className,
      getErrorText,
      isFormDisabled,
      disabled,
      title,
    } = this.props;
    const { percentUploaded } = this.state;
    const hasError = getErrorText();
    return (
      <div
        className={cx('form-group', className, { 'has-error': hasError })}
        data-test={name}
      >
        <label htmlFor={name}>{title}</label>
        <div className="form-control">
          {percentUploaded !== null ? (
            <div className="row Mt-15">
              <div className="col-11">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={percentUploaded}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: percentUploaded + '%' }}
                  >
                    {percentUploaded}%
                  </div>
                </div>
              </div>
              <div className="col-1 Ta-c">
                <div
                  className="delete-icon"
                  onClick={this.cancelUpload.bind(this)}
                ></div>
              </div>
            </div>
          ) : (
            <div className="Mt-15">
              <input
                type="file"
                name={name}
                id={name}
                onChange={this.onChange.bind(this)}
                disabled={isFormDisabled() || disabled}
              />
            </div>
          )}
        </div>
        <span className="validation-error">{hasError}</span>
      </div>
    );
  }
}
export default connect()(withFormsy(FileUpload));
