import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 100%;
`;

export const QuestionTitle = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const DoneButton = styled(Button)`
  align-self: flex-end;
  margin-top: 1rem;
  background-color: #cb2128;
  margin: 10px;
  padding: 5px;
  font-size: 14px;
  min-width: 75px;
  width: 10vw;
  border-radius: 20px;
  border: 1px solid transparent;
  box-shadow: inset 0 0px 0px 0px #cb2128;

  &:hover {
    box-shadow: inset 0 0 16px 6px #cb2128;
    /* border: 1px solid #cb2128;
    background-color: #ffffff;
    color: #cb2128; */
  }
`;
