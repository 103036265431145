import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Input } from 'modules/react-forms';
import * as resetActionsDuck from '../../ducks/reset';
import * as loginActionsDuck from '../../ducks/login';
import { formatErrors } from 'modules/utils-validation';
import { Link } from 'react-router';

const mapStateToProps = (state) => ({
  resetSent:
    state.loginState &&
    state.loginState.reset &&
    state.loginState.reset.resetSent,
});
const mapDispatchToProps = (dispatch) => ({
  resetActions: bindActionCreators(resetActionsDuck, dispatch),
  loginActions: bindActionCreators(loginActionsDuck, dispatch),
});
export class ResetView extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    loginActions: PropTypes.object,
    resetActions: PropTypes.object,
    resetSent: PropTypes.bool,
    path: PropTypes.string,
    app: PropTypes.string,
  };
  static contextTypes = {
    url: PropTypes.object,
  };
  onResetSend(data, resetForm, handleErrors) {
    const { app, loginActions, resetActions } = this.props;
    const resetToken = this.context.url.query.h;
    function onFailure(resp) {
      if (resp.status === 406) {
        handleErrors(formatErrors(resp.json.errorData));
      }
    }
    loginActions.logout();
    resetActions.sendReset(
      {
        app,
        password: data.password1,
        resetToken: resetToken,
      },
      onFailure
    );
  }
  render() {
    const { resetSent, path } = this.props;
    const { new_account = false } = this.context.url.query;
    const title = new_account ? 'Set a password.' : 'Reset password.';
    if (resetSent) {
      return (
        <div>
          <div className="row">
            <h1>{title}</h1>
          </div>
          <div className="row Ta-c">
            Your password has been {new_account ? 'set' : 'reset'}
            <Link to={`${path}?action=login`} className="btn btn-primary Mt-20">
              Login
            </Link>
          </div>
        </div>
      );
    }
    return (
      <Form onValidSubmit={this.onResetSend.bind(this)}>
        <div className="row">
          <h1>{title}</h1>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Input
              type="password"
              name="password1"
              value={undefined}
              title="Password"
              validations="PASSWORD"
              validationError="Enter valid password"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Input
              type="password"
              name="password2"
              value={undefined}
              title="Verify Password"
              validations="equalsField:password1"
              validationError="Passwords do not match"
              info="Must be 8-16 characters, and contain at least 1 letter and number"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <button type="submit" className="btn btn-primary My-10">
              Reset Password
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetView);
