import { useFetchHook } from 'utils/useFetchHook';

export const useGetMediaUrl = (mediaItemId, options = {}) => {
  const { isLoading, data, error } = useFetchHook(
    `${process.env.REACT_APP_API_BASE}/media/media-item/${mediaItemId}/get-url`,
    options
  );

  return { isLoading, data, error };
};
