import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'components/common/Loader';
import './styles.scss';
const itemsPerPage = 20;

const loading = (
  <div className="Ta-c P-30">
    <Loader />
  </div>
);

class ProfileActivity extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    earnings: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  nextPage() {
    const { earnings } = this.props;
    const pages = Math.ceil(earnings.list.length / itemsPerPage);
    if (this.state.activePage + 1 <= pages) {
      this.setState({
        activePage: this.state.activePage + 1,
      });
    }
  }

  prevPage() {
    this.setState({
      activePage: this.state.activePage - 1 || 1,
    });
  }

  render() {
    const { earnings, busy } = this.props;
    const { activePage } = this.state;
    const startIndex = activePage * itemsPerPage - itemsPerPage;
    const endIndex = activePage * itemsPerPage - 1;
    const results = (
      <div>
        <ul className="profile-activity">
          {earnings.list.reduce((prev, id, index) => {
            if (index >= startIndex && index <= endIndex) {
              const info = earnings.data[id] || {};
              let message = (
                <span>
                  You recieved {info.credit} points for completing:{' '}
                  <strong>{info.code.replace(/_/g, ' ').toLowerCase()}</strong>
                </span>
              );
              if (info.credit && info.title) {
                message = (
                  <span>
                    You recieved {info.credit} points for completing the{' '}
                    <span className="Tt-c">
                      {info.code.replace(/_/g, ' ').toLowerCase()}
                    </span>{' '}
                    round for{' '}
                    <strong>
                      {info.title} - {info.subtitle}
                    </strong>
                    .
                  </span>
                );
              } else if (info.debit && info.code === 'GIFT_CARD_PURCHASE') {
                message = (
                  <span>
                    You spent {info.debit} points on <strong>gift cards</strong>
                    .
                  </span>
                );
              }
              prev.push(<li key={info.earning_id}>{message}</li>);
            }
            return prev;
          }, [])}
        </ul>
        <button
          className="btn btn-sml btn-default Mr-15 Mt-30"
          onClick={this.prevPage.bind(this)}
        >
          Previous Page
        </button>
        <button
          className="btn btn-sml btn-default Mt-30"
          onClick={this.nextPage.bind(this)}
        >
          Next Page
        </button>
      </div>
    );
    return <div>{busy ? loading : results}</div>;
  }
}

export default connect((state) => ({
  earnings: state.earnings,
}))(ProfileActivity);
