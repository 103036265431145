import React from 'react';
import PropTypes from 'prop-types';
import MediaExplorer from 'components/common/MediaExplorer';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import './styles.scss';

class Explore extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };
  render() {
    const { user, payableEventAmounts } = this.props.app;
    return (
      <div className="page-home page-margin">
        <h2>We need your feedback!</h2>
        <div className="biline">
          Select a video and give feedback to earn Flow. Redeem Flow in our
          store for <strong>Amazon Gift Cards</strong>!
        </div>
        {!user.birthdate ? (
          <div className="profile-msg">
            <i className="fa fa-exclamation-circle"></i>
            Fill out your profile to earn {
              payableEventAmounts.profile
            } Flow!{' '}
            <Link to="/profile" className="Fw-b Td-u">
              Go to profile page now.
            </Link>
          </div>
        ) : null}
        <MediaExplorer />
      </div>
    );
  }
}
export default connect((state) => ({
  app: state.app,
}))(Explore);
