import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AmazonCard from './AmazonCard';
import numeral from 'numeral';
import * as cartActions from 'ducks/cart';
import * as earningsActions from 'ducks/earnings';
import * as surveyActions from 'ducks/survey';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router';
import './styles.scss';

class Store extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    cart: PropTypes.object,
    earnings: PropTypes.object,
    survey: PropTypes.object,
    app: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      confirmModalOpen: false,
      orderSubmitted: false,
    };
  }
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(surveyActions.getUserPrefs());
  }
  addProduct(productId) {
    const {
      cart: { items },
      dispatch,
    } = this.props;
    const currQty = items[productId] || 0;
    dispatch(cartActions.setQuantity(productId, currQty + 1));
  }
  delProduct(productId) {
    const { dispatch } = this.props;
    dispatch(cartActions.setQuantity(productId, 0));
  }
  openConfirmModal() {
    this.setState({
      confirmModalOpen: true,
      orderSubmitted: false,
    });
  }
  closeConfirmModal() {
    this.setState({
      confirmModalOpen: false,
    });
  }
  submitOrder() {
    const {
      dispatch,
      cart: { items },
    } = this.props;
    const payload = Object.keys(items).map((k) => ({
      gift_card_id: k,
      quantity: items[k],
    }));
    dispatch(
      cartActions.submitOrder({ cart: payload }, () => {
        this.setState(
          {
            orderSubmitted: true,
          },
          () => {
            dispatch(cartActions.clear());
            dispatch(earningsActions.getAll());
          }
        );
      })
    );
  }
  render() {
    const {
      earnings: { total },
      cart: { items, products, busy },
      survey: { user_prefs = {} },
      app: { user = {} },
    } = this.props;

    const hasProfileData = !!user.sex && !!user.ethnicity;
    const hasSurveyData =
      !!user_prefs.favorite_content && !!user_prefs.streaming_platforms;

    const disableStore = !hasProfileData || !hasSurveyData;

    const { confirmModalOpen, orderSubmitted } = this.state;
    const flowSpent = Object.keys(items).reduce(
      (prev, productId) =>
        prev + items[productId] * products.data[productId].flow,
      0
    );
    const remainingBalance = total - flowSpent;
    const itemsInCart = products.list.reduce((prev, product_id) => {
      const p = products.data[product_id];
      const qty = items[product_id] || 0;
      if (qty > 0) {
        prev.push({
          ...p,
          product_id,
          qty,
        });
      }
      return prev;
    }, []);
    return (
      <div className="page-store">
        <div className="page-margin">
          <h2>Store</h2>
        </div>
        <div className="reward-yourself row">
          <div className="col-sm-6 mobile-center">
            <div>
              Reward yourself for all your hard work. Use your flow to purchase
              Amazon.com Gift Cards.
            </div>
            <div>See below.</div>
          </div>
          <div className="col-sm-6 Ta-c">
            <img alt="" src="/images/amazon-logo.png" />
          </div>
        </div>
        {disableStore ? (
          <div className="page-margin unlock-store">
            Unlock the store and earn extra flow by updating your{' '}
            <Link to="/profile">profile</Link> and{' '}
            <Link to="/profile?p=preferences">preferences</Link>!
          </div>
        ) : (
          <div className="page-margin">
            <div className="row">
              <div className="col-sm-9 products">
                <div className="row Mb-30">
                  <div className="col-sm-12 mobile-center">
                    <div className="total-flow">
                      {numeral(total).format('0,0')}
                      <em>Total flow earned</em>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {products.list.map((product_id) => {
                    const p = products.data[product_id];
                    const addDisabled = p.flow > remainingBalance;
                    const noop = () => {};
                    return (
                      <div
                        key={'product_' + product_id}
                        className="col-sm-3 col-6"
                      >
                        <AmazonCard amount={p.amount} />
                        <div className="product-actions mobile-center">
                          <div className="flow">
                            {numeral(p.flow).format('0,0')}
                            <em>flow</em>
                          </div>
                          <div
                            className={cx('add', { disabled: addDisabled })}
                            onClick={
                              addDisabled
                                ? noop
                                : this.addProduct.bind(this, product_id)
                            }
                          >
                            <button className="add-btn">+</button>
                            <span>add to cart</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-sm-3 cart">
                <div className="sprite sprite-cart Mx-a"></div>
                <h3>Shopping Cart</h3>
                <hr />
                {itemsInCart.length > 0 ? (
                  <table className="W-100">
                    <thead>
                      <tr>
                        <th className="heading">Item</th>
                        <th className="Ta-c heading">Qty</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsInCart.map((item) => (
                        <tr key={'p_row_' + item.product_id}>
                          <td className="W-80">
                            Amazon ${numeral(item.amount).format('0,0')}
                          </td>
                          <td className="Ta-c">{item.qty}</td>
                          <td className="Ta-r">
                            <div
                              onClick={this.delProduct.bind(
                                this,
                                item.product_id
                              )}
                              className="del"
                            >
                              ×
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : null}
                <hr />
                <table className="W-100">
                  <tbody>
                    <tr>
                      <td className="heading">Total</td>
                      <td className="Ta-r">
                        {numeral(flowSpent).format('0,0')} pts
                      </td>
                    </tr>
                    <tr>
                      <td className="heading">Earned</td>
                      <td className="Ta-r">
                        {numeral(total).format('0,0')} pts
                      </td>
                    </tr>
                    <tr>
                      <td className="heading">Balance</td>
                      <td className="Ta-r">
                        {numeral(remainingBalance).format('0,0')} pts
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="cart-actions">
                  <button
                    onClick={this.openConfirmModal.bind(this)}
                    disabled={flowSpent === 0}
                    className="btn btn-primary red"
                  >
                    Redeem
                  </button>
                </div>
              </div>
            </div>
            <p className="Pt-30 Fz-11 C-grey">
              *Amazon.com is not a sponsor of this promotion. Except as required
              by law, Amazon.com Gift Cards ("GCs") cannot be transferred for
              value or redeemed for cash. GCs may be used only for purchases of
              eligible goods on Amazon.com or certain of its affiliated
              websites. GCs cannot be redeemed for purchases of gift cards.
              Purchases are deducted from the GC balance. To redeem or view a GC
              balance, visit "Your Account" on Amazon.com. Amazon is not
              responsible if a GC is lost, stolen, destroyed or used without
              permission. For complete terms and conditions, see
              www.amazon.com/gc-legal. GCs are issued by ACI Gift Cards LLC, a
              Washington corporation. All Amazon ®, ™ & © are IP of Amazon.com,
              Inc. or its affiliates. No expiration date or service fees.
            </p>
          </div>
        )}

        <Modal
          show={confirmModalOpen}
          className="confirm-order-modal"
          onHide={this.closeConfirmModal.bind(this)}
        >
          {orderSubmitted ? (
            <div>
              <Modal.Header closeButton>
                <Modal.Title>
                  <i className="fa fa-shopping-cart"></i>
                  <div>Order Submitted!</div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="Ta-c Fz-18 Px-20">
                  Your Amazon.com Gift Card(s) should be arriving in your email
                  inbox in about an hour.
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="congrats-actions Ta-c">
                  <button
                    className="btn btn-primary red"
                    onClick={this.closeConfirmModal.bind(this)}
                  >
                    Close
                  </button>
                </div>
              </Modal.Footer>
            </div>
          ) : (
            <div>
              <Modal.Header closeButton>
                <Modal.Title>
                  <i className="fa fa-shopping-cart"></i>
                  <div>Confirm your order</div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="Ta-c Fz-22 Px-20">
                  Are you sure you want to spend{' '}
                  <strong>{numeral(flowSpent).format('0,0')}</strong> flow?
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="congrats-actions Ta-c">
                  <button
                    className="btn btn-link"
                    onClick={this.closeConfirmModal.bind(this)}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={busy}
                    className="btn btn-primary red"
                    onClick={this.submitOrder.bind(this)}
                  >
                    Confirm
                  </button>
                </div>
              </Modal.Footer>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}
export default connect((state) => ({
  cart: state.cart,
  earnings: state.earnings,
  survey: state.survey,
  app: state.app,
}))(Store);
