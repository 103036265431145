import React from 'react';
import './styles.scss';

export default function (props) {
  return (
    <div className="page-help page-margin">
      <h2>Help</h2>
      <div className="biline">
        Our help page is currently under development. Please email any questions
        or concerns to:{' '}
        <a href="mailto:support@volkno.com">support@volkno.com</a>
      </div>
    </div>
  );
}
