import React from 'react';
import MediaExplorer from 'components/common/MediaExplorer';
import './styles.scss';

export default function (props) {
  return (
    <div className="page-home page-margin">
      <h2>My Collection</h2>
      <div className="biline">View your favorited items here.</div>
      <MediaExplorer showFavorites />
    </div>
  );
}
