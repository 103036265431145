import React from 'react';
import styled from '@emotion/styled';

const StyledNumericInput = styled.input`
  background-color: transparent;
  border-bottom: 1px solid red;
  color: white;
  font-size: 3rem;
  line-height: 7rem;
  text-align: center;
  &:focus {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const NumericInputQuestionComponent: React.FC<{
  onAnswer: (answer: number) => any;
}> = ({ onAnswer }) => {
  return (
    <StyledNumericInput
      placeholder="ex. 123"
      type="number"
      onChange={(e) => onAnswer(parseFloat(e.currentTarget.value))}
    />
  );
};
