import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as resultsActions from 'ducks/results';
import * as earningsActions from 'ducks/earnings';

import ChallengeTagIt from '../../Video/ChallengeTagIt';

import './styles.scss';

class TagIt extends React.Component {
  static propTypes = {
    // Redux state
    results: PropTypes.object,

    dispatch: PropTypes.func,
    sendExtraFeedback: PropTypes.func,
  };

  static contextTypes = {
    url: PropTypes.object,
  };

  state = {
    sharedTags: [],
  };

  componentWillMount() {
    const { dispatch } = this.props;
    const { mediaId, itemId } = this.context.url.params;

    dispatch(resultsActions.getSummaryResults(mediaId, itemId, 'tagit'));
  }

  componentWillUnmount() {
    const { sendExtraFeedback, dispatch } = this.props;
    const { sharedTags } = this.state;

    // Send feedback only if any tag is selected
    if (sharedTags.length > 0) {
      sendExtraFeedback('tagit', sharedTags, () => {
        dispatch(earningsActions.getAll());
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.results.busy &&
      !nextProps.results.busy &&
      nextProps.results.summary.tagit
    ) {
      this.setState({
        sharedTags: nextProps.results.summary.tagit,
      });
    }
  }

  syncTags = (tags) => {
    this.setState({
      sharedTags: tags,
    });
  };

  render() {
    const { sharedTags } = this.state;
    return (
      <div className="tag-it">
        <h1>Tag It</h1>

        <ChallengeTagIt
          location="video_summary"
          tags={sharedTags}
          extended
          tagsPerCol={6}
          syncTags={this.syncTags}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  results: state.results,
});

export default connect(mapStateToProps)(TagIt);
