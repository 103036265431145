import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { DefaultPlayer as Video } from 'react-html5video';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import cx from 'classnames';

import { getDevice, getOrientation } from 'utils';
import * as resultsActions from 'ducks/results';
import * as earningsActions from 'ducks/earnings';
import * as mediaActions from 'ducks/media';

import {
  Hot,
  Not,
  Like,
  Excited,
  Funny,
  Angry,
  Joy,
  Confused,
  OMG,
  Sad,
  Ouch,
} from 'components/common/Icons';
import Tooltip from 'components/common/Tooltip';
import Reminder from 'components/common/Reminder';

import ChallengeHotNot from './ChallengeHotNot';
import ChallengeEmoji from './ChallengeEmoji';

import './styles.scss';
import { VideoSurvey } from './VideoSurveyOrchestrator/VIdeoSurvey';

const minSecondsWatchedToEarnFlow = 10;
const minPercentWatchedToEarnFlow = 30;

class VideoPage extends React.Component {
  static propTypes = {
    nextSubpage: PropTypes.func,
    media: PropTypes.object,
    item: PropTypes.object,
    app: PropTypes.object,
    videoUrl: PropTypes.string,
    showUserIdOverlay: PropTypes.bool,
    allowSeeking: PropTypes.bool,
    query: PropTypes.object,
    cintURLs: PropTypes.object,
  };

  state = {
    fullscreen: false,
    runAnim: '',
    sharedTags: [],
    feedbackGiven: false,
    currentVideoTime: 0,
  };

  constructor(props) {
    super(props);

    this.results = [];
    this.animTimer = null;
    this.secondsPlayed = 0;
  }

  componentDidMount() {
    const {
      item: { media_item_id },
      media: { media_id },
    } = this.props;
    const video = this.videoRef.current.videoEl;
    // document.getElementsByClassName('video__el')[0]

    $(document.body).on('keydown', this.handleKeyDown);

    this.videoCounter = setInterval(() => {
      if (!video.paused) {
        this.secondsPlayed += 1;
      }
    }, 1000);

    video.addEventListener('loadedmetadata', this.onMetaDataLoaded);

    video.addEventListener('pause', () => {
      mediaActions.mediaItemAction(
        media_id,
        media_item_id,
        'PAUSE',
        Math.floor(video.currentTime)
      );
    });

    video.addEventListener('play', () => {
      mediaActions.mediaItemAction(
        media_id,
        media_item_id,
        'PLAY',
        Math.floor(video.currentTime)
      );
    });

    video.addEventListener('ended', () => {
      mediaActions.mediaItemAction(
        media_id,
        media_item_id,
        'END',
        Math.floor(video.currentTime)
      );
    });

    let seekTimeout;
    video.addEventListener('seeking', () => {
      clearTimeout(seekTimeout);
      seekTimeout = setTimeout(() => {
        mediaActions.mediaItemAction(
          media_id,
          media_item_id,
          'SEEK',
          Math.floor(video.currentTime)
        );
      }, 500);
    });
    video.addEventListener('timeupdate', () => {
      this.setState({ ...this.state, currentVideoTime: video.currentTime });
    });

    this.saveProgressTimer = setInterval(() => {
      if (this.saveProgress) {
        let vidPrg = JSON.parse(window.localStorage.videoProgress || '{}');
        const video = this.videoRef.current.videoEl;
        if (Object.keys(vidPrg).length > 50) {
          vidPrg = {};
        }
        vidPrg[media_item_id] = video.currentTime;
        window.localStorage.setItem('videoProgress', JSON.stringify(vidPrg));
      }
    }, 5000);
  }

  onMetaDataLoaded = () => {
    const { media_item_id } = this.props.item;
    const video = this.videoRef.current.videoEl;
    this.saveProgress = video.duration > 300;
    if (this.saveProgress) {
      const vidPrg = JSON.parse(window.localStorage.videoProgress || '{}');
      if (vidPrg[media_item_id]) {
        //video.currentTime = vidPrg[media_item_id];
      }
    }
  };

  componentWillUnmount() {
    const video = this.videoRef.current.videoEl;

    video.removeEventListener('loadedmetadata', this.onMetaDataLoaded);

    clearTimeout(this.saveProgressTimer);
    clearTimeout(this.animTimer);
    clearTimeout(this.videoCounter);

    $(document.body).off('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 27 && this.state.fullscreen) {
      this.toggleFullscreen();
    }
  };

  toggleFullscreen = () => {
    this.setState({
      fullscreen: !this.state.fullscreen,
    });
  };

  onFullscreenRequest = (evt) => {
    evt.preventDefault();
    this.toggleFullscreen();
  };
  collectFeedback = (code, challengeKey, challengeValue, pageLocation) => {
    const videoEl = this.videoRef.current.videoEl;
    this.results.push({
      code: code,
      challenge_key: challengeKey,
      challenge_value: challengeValue,
      media_time_secs: videoEl.currentTime,
      page_location: pageLocation,
    });

    clearTimeout(this.animTimer);

    this.setState(
      {
        runAnim: code + '-' + challengeKey,
        feedbackGiven: true,
      },
      () => {
        this.animTimer = setTimeout(() => {
          this.setState({ runAnim: '' });
        }, 600);
      }
    );
  };

  roundDone = () => {
    const { nextSubpage } = this.props;

    this.sendFeedback();
    nextSubpage();
  };

  skipRound = () => {
    const {
      item: { media_item_id },
      media: { media_id },
    } = this.props;
    const video = this.videoRef.current.videoEl;

    mediaActions.mediaItemAction(
      media_id,
      media_item_id,
      'SKIP_ROUND',
      Math.floor(video.currentTime)
    );

    this.roundDone();
  };

  sendFeedback() {
    const videoEl = this.videoRef.current.videoEl;
    const { media, item, dispatch } = this.props;
    const percentWatched = (100 * this.secondsPlayed) / videoEl.duration;

    if (
      process.env.NODE_ENV === 'development' ||
      (this.secondsPlayed >= minSecondsWatchedToEarnFlow &&
        percentWatched >= minPercentWatchedToEarnFlow)
    ) {
      dispatch(
        resultsActions.send(
          {
            media_id: media.media_id,
            media_item_id: item.media_item_id,
            poster_id: media.poster_id,
            page_path: window.location.pathname,
            device: getDevice(),
            device_orientation: getOrientation(),
            percent_watched: parseInt(percentWatched, 10),
            results: this.results,
          },
          () => {
            dispatch(earningsActions.getAll());
          }
        )
      );
    }
  }

  render() {
    const {
      media,
      item,
      app: {
        genres,
        user: { user_id },
      },
      showUserIdOverlay = true,
      allowSeeking = true,
      query,
      cintURLs,
    } = this.props;
    const { RID: externalUserSessionId } = query; // from CINT (external/public users)
    const { fullscreen, runAnim, feedbackGiven } = this.state;
    const genre =
      genres.data &&
      genres.data[media.genres[0]] &&
      genres.data[media.genres[0]].name;
    const tips = [
      'Hit "HOT" or "NOT" during each scene to express which parts you like and dislike.',
      'Tap the emoji that best describes how you feel during each scene.',
    ];
    // For some reason the controls appear in the
    // sequence they are added to this array. Hence the weird
    // if statement between
    const playerControls = ['PlayPause'];
    if (allowSeeking) {
      playerControls.push('Seek');
    }
    playerControls.push('Time', 'Volume', 'Fullscreen');

    this.videoRef = React.createRef();
    const pauseVideo = () =>
      this.videoRef.current && this.videoRef.current.videoEl.pause();

    const playVideo = () =>
      this.videoRef.current && this.videoRef.current.videoEl.play();
    const videoSurveyProps = {
      pauseVideo,
      playVideo,
      videoTimeLocation: this.state.currentVideoTime,
    };
    return (
      <div className="media-video row">
        {externalUserSessionId ? (
          <div className="Bb P-0 col-12 Ta-r">
            <Link className="back-btn Px-0" href={cintURLs.exitURL}>
              Terminate Session
              <i className="fa fa-close Ml-10" />
            </Link>
          </div>
        ) : (
          <div className="Bg-3 P-0 col-12">
            <Link className="back-btn" to="/">
              <i className="fa fa-angle-left" />
              Back
            </Link>
          </div>
        )}

        <div className="col-12 P-0">
          <div className={cx('video-container', { fullscreen })}>
            {fullscreen && (
              <div className="video-challenge">
                <div className="challenge-overlay">
                  <div className="row flex-nowrap">
                    <div className="col-xs-4 Ta-c">
                      <ChallengeHotNot
                        location="video_overlay"
                        onChange={this.collectFeedback}
                      />
                    </div>
                    <div className="col-xs-8 mobile-center Ta-r">
                      <ChallengeEmoji
                        location="video_overlay"
                        onChange={this.collectFeedback}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="anim-overlay">
              <ReactCSSTransitionGroup
                transitionName="anim-challenge"
                transitionEnterTimeout={500}
                transitionLeave={false}
              >
                {runAnim === 'HOT_OR_NOT-hot' && <Hot className="anim-targ" />}

                {runAnim === 'HOT_OR_NOT-not' && <Not className="anim-targ" />}

                {runAnim === 'EMOJI-like' && <Like className="anim-targ" />}

                {runAnim === 'EMOJI-excited' && (
                  <Excited className="anim-targ" />
                )}

                {runAnim === 'EMOJI-funny' && <Funny className="anim-targ" />}

                {runAnim === 'EMOJI-angry' && <Angry className="anim-targ" />}

                {runAnim === 'EMOJI-joy' && <Joy className="anim-targ" />}

                {runAnim === 'EMOJI-confused' && (
                  <Confused className="anim-targ" />
                )}

                {runAnim === 'EMOJI-omg' && <OMG className="anim-targ" />}

                {runAnim === 'EMOJI-sad' && <Sad className="anim-targ" />}

                {runAnim === 'EMOJI-ouch' && <Ouch className="anim-targ" />}
              </ReactCSSTransitionGroup>
            </div>
            {showUserIdOverlay && user_id && (
              <div className="userid-overlay">{btoa(user_id)}</div>
            )}
            <VideoSurvey
              {...videoSurveyProps}
              media={this.props.media}
              item={this.props.item}
            >
              <Video
                ref={this.videoRef}
                onEnded={this.roundDone}
                autoPlay={window.location.port !== '3000'}
                playsInline
                controls={playerControls}
                onFullscreenClick={this.onFullscreenRequest}
              >
                <source src={this.props.videoUrl} type={item.mime_type_1} />
              </Video>
            </VideoSurvey>
          </div>

          <div className="info">
            <div className="row">
              <div className="col-md-3 title mobile-center Px-40 Mb-20">
                {media.title}

                {item.subtitle !== media.title && (
                  <span className="Fw-n subtitle">
                    <span className="dash"> - </span>
                    {item.subtitle}
                  </span>
                )}

                <div className="Fw-n Fz-13">
                  {media.release_date.split('-')[0]} | {genre}
                </div>
              </div>

              {!fullscreen && (
                <div className="col-md-4 Ta-c">
                  <ChallengeHotNot
                    location="below_video"
                    onChange={this.collectFeedback}
                  />
                </div>
              )}
              {!fullscreen && (
                <div className="col-md-5 mobile-center Ta-r">
                  <ChallengeEmoji
                    location="below_video"
                    onChange={this.collectFeedback}
                  />
                </div>
              )}
            </div>

            <div className="row Mt-20">
              <div className="col-lg-2 Pl-40 mobile-center">
                <span className="link" onClick={this.skipRound}>
                  Skip
                </span>
                <span className="Mx-5">|</span>
                <Tooltip placement="bottom" tips={tips} text="Help" />
              </div>
              <div className="col-lg-8 Ta-c">
                <Reminder hide={feedbackGiven}>
                  Give feedback as you watch
                </Reminder>
              </div>
              <div className="col-lg-2"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  cintURLs: state.loginState.login.cintURLs,
});

export default connect(mapStateToProps)(VideoPage);
