import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as surveysActions from 'ducks/surveys';
import * as earningsActions from 'ducks/earnings';
import * as Survey from 'survey-react';
import Loader from 'components/common/Loader';
import './styles.scss';

Survey.Survey.cssType = 'bootstrap';

class SurveyDetails extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    surveys: PropTypes.object,
  };
  static contextTypes = {
    url: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      finished: false,
    };
  }
  componentWillMount() {
    const { surveyId } = this.context.url.params;
    this.props.dispatch(surveysActions.getAnswers(surveyId));
    this.props.dispatch(surveysActions.getSurveys());
  }

  onComplete(survey) {
    const { surveyId } = this.context.url.params;
    const { surveys } = this.props;
    const questionData = surveys.data[surveyId] || {};
    const answersStr = JSON.stringify(survey.data);
    this.props.dispatch(
      surveysActions.submitAnswers(surveyId, answersStr, () => {
        this.setState({ finished: true });
        this.props.dispatch(
          earningsActions.getAll(() => {
            this.props.dispatch(
              earningsActions.manualAlert('SURVEY_DONE', {
                flowEarned: questionData.flow,
              })
            );
          })
        );
      })
    );
  }
  render() {
    const { surveyId } = this.context.url.params;
    const { finished } = this.state;
    const { surveys } = this.props;
    const questionData = surveys.data[surveyId] || {};
    const answerData = surveys.answers || {};
    const completed = !!questionData.completed;
    const surveyUrl = `${questionData.survey_url}?hash=${questionData.hash}`;
    if (!questionData.survey_id) {
      return <Loader />;
    }
    return (
      <div className="page-surveys survey-details page-margin">
        <div className="row">
          <div className="col-md-7 mobile-center">
            <h2>{questionData.title}</h2>
          </div>
          <div className="col-md-5 Ta-r mobile-center earnings">
            {completed ? <i className="fa fa-check-circle Mr-10" /> : null}
            <span className="Fw-b">{questionData.flow} Flow</span>
          </div>
          <div className="col-md-12">
            <p>{questionData.description}</p>
          </div>
          <div className="col-md-12 survey-area">
            {finished ? (
              <h3>Thank you for completing the survey!</h3>
            ) : questionData.survey_url ? (
              <iframe
                title="survey"
                src={surveyUrl}
                frameBorder="0"
                height="1000"
                width="100%"
              ></iframe>
            ) : (
              <Survey.Survey
                json={JSON.parse(questionData.survey_json)}
                data={JSON.parse(answerData.answer_json || '{}')}
                onComplete={this.onComplete.bind(this)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default connect((state) => ({
  app: state.app,
  surveys: state.surveys,
}))(SurveyDetails);
