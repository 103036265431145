import { messageActions } from 'modules/react-message';
import Immutable from 'seamless-immutable';
import apiFetch from 'utils/fetch';

const cfg = process.env;

// TODO: refactor this away in favor of surveys duck and endpoint (using surveyjs now)

// action types
export const actionTypes = {
  IS_BUSY: 'volkno/survey/IS_BUSY',
  UPDATE_USER_PREFS: 'volkno/survey/UPDATE_USER_PREFS',
};

// reducers
const initialState = Immutable({
  user_prefs: {},
  busy: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.IS_BUSY:
      return state.merge(
        {
          busy: action.payload,
        },
        { deep: true }
      );

    case actionTypes.UPDATE_USER_PREFS:
      return state.setIn(['user_prefs'], action.payload);

    default:
      return state;
  }
}

// action creators
export function updateUserPrefs(
  data,
  onSuccess = () => {},
  onFailure = () => {}
) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/survey/userPrefs`,
      body: data,
    };
    apiFetch('put', options, dispatch)
      .then((resp) => {
        if (resp.status !== 200) {
          onFailure(resp);
          dispatch(messageActions.displayError(resp.json.error));
        } else {
          onSuccess();
          dispatch(messageActions.displaySuccess('info_saved'));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
export function getUserPrefs() {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_BUSY, payload: true });
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/survey/userPrefs`,
    };
    apiFetch('get', options, dispatch)
      .then((resp) => {
        if (resp.status !== 200) {
          dispatch(messageActions.displayError(resp.json.error));
        } else {
          dispatch({
            type: actionTypes.UPDATE_USER_PREFS,
            payload: resp.json.user_prefs,
          });
        }
        dispatch({ type: actionTypes.IS_BUSY, payload: false });
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
