import { SurveyConfig } from 'modules/survey-volkno/types';
import { MediaItemData } from 'types/media';
import { useFetchHook } from 'utils/useFetchHook';

export const useMediaSurveys = (
  item: MediaItemData
): {
  surveys: { survey: SurveyConfig; time: number; tolerance: number }[];
  loading: boolean;
} => {
  const url = `${process.env.REACT_APP_SURVEY_API_BASE}/media/${item.media_item_id}/surveys`;
  const { isLoading: loading, data, error } = useFetchHook<{
    [key: string]: SurveyConfig;
  }>(url);
  let surveys: { survey: SurveyConfig; time: number; tolerance: number }[] = [];
  if (data) {
    surveys = Object.entries(data).map(([time, survey]) => ({
      time: parseInt(time),
      survey,
      tolerance: 1,
    }));
  }
  if (error) {
    console.log(error);
  }
  return {
    surveys,
    loading,
  };
};
