import React, { Component } from 'react';

import { Excited, Angry, Joy, OMG, Sad, Ouch } from 'components/common/Icons';

import './styles.scss';

class ChallengeEmoji extends Component {
  onClick = (icon) => () => {
    const { location, onChange } = this.props;

    onChange('EMOJI', icon, null, location);
  };

  render() {
    return (
      <div className="Fz-11 slidein-onhover emoji-choices Ta-c W-100 Mb-20">
        <div>
          <Angry onClick={this.onClick('angry')} />
          Angry
        </div>
        <div>
          <Ouch onClick={this.onClick('ouch')} />
          Scared
        </div>
        <div>
          <Sad onClick={this.onClick('sad')} />
          Sad
        </div>
        <div>
          <OMG onClick={this.onClick('omg')} />
          Surprised
        </div>
        <div>
          <Excited onClick={this.onClick('excited')} />
          Excited
        </div>
        <div>
          <Joy onClick={this.onClick('joy')} />
          Happy
        </div>
      </div>
    );
  }
}

export default ChallengeEmoji;
