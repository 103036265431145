import { Route, IndexRoute } from 'react-router';
import React from 'react';
import requireAuth from 'components/utils/requireAuth';
import CoreLayout from 'components/layouts/CoreLayout';
import OneColumn from 'components/layouts/OneColumn';
import OneColumnNoChrome from 'components/layouts/OneColumnNoChrome';
import TwoColumn from 'components/layouts/TwoColumn';
import Home from 'components/pages/Home';
import Help from 'components/pages/Help';
import Contact from 'components/pages/Contact';
import Welcome from 'components/pages/Welcome';
import About from 'components/pages/About';
import Media from 'components/pages/Media';
import Collection from 'components/pages/Collection';
import Surveys from 'components/pages/Surveys';
import SurveyDetails from 'components/pages/Surveys/SurveyDetails';
import Store from 'components/pages/Store';
import Profile from 'components/pages/Profile';
import Terms from 'components/pages/Legal/Terms';
import GDPR from 'components/pages/Legal/GDPR';
import Privacy from 'components/pages/Legal/Privacy';
import DMCA from 'components/pages/Legal/DMCA';
import { DataManagementPage } from 'components/pages/Legal/DataManagement';
import FourOFour from 'components/pages/404';
/*
  Nesting - represents Parent/Child component nesting
  Paths - are treated independent of nesting in our implementation and
          should always be absolute paths (starting from / path)

  Note regarding parent/child nesting: If the parent does not include {this.props.children}
   anywhere, the child component will not be displayed at all.

  Naming conventions for paths:
    /dashes-are-good/

  Naming conventions for params (be explicit!):
    Wrong
    /:id/

    Right:
    /:subjectId/
*/
export default (
  store // eslint-disable-line
) => (
  <Route component={CoreLayout}>
    <Route path="/welcome" component={OneColumn}>
      <IndexRoute component={Welcome} />
    </Route>
    <Route path="/cint/:mediaId/:itemId" component={OneColumnNoChrome}>
      <IndexRoute component={Media} />
    </Route>
    <Route path="/" component={requireAuth(TwoColumn)}>
      <IndexRoute component={Home} />
      <Route path="/media/:mediaId/:itemId" component={Media} />
      <Route path="/collection" component={Collection} />
      <Route path="/surveys" component={Surveys} />
      <Route path="/surveys/:surveyId" component={SurveyDetails} />
      <Route path="/store" component={Store} />
      <Route path="/profile" component={Profile} />
      <Route path="/terms-accept" component={Terms} />
      <Route path="/verify" />
    </Route>
    <Route path="/" component={TwoColumn}>
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} />
      <Route path="/terms" component={Terms} />
      <Route path="/gdpr" component={GDPR} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/data-management" component={DataManagementPage} />
      <Route path="/dmca" component={DMCA} />
      <Route path="/help" component={Help} />
      <Route path="*" component={FourOFour} />
    </Route>
  </Route>
);
