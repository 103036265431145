import React from 'react';
import { Link } from 'react-router';
//import './styles.scss';

export default class FourOFour extends React.Component {
  render() {
    return (
      <div className="404-page Ta-c Py-50">
        <h1>404: Page Not Found</h1>
        <p>
          You{"'"}ve mis-typed the url or you{"'"}ve clicked an invalid link.
        </p>
        <p>
          <Link to="/">Click here to go back to home page.</Link>
        </p>
      </div>
    );
  }
}
