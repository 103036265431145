import { logout as logoutAction } from '../ducks/login';

// TODO: use the main app's fetch function
export default function apiFetch(dispatch, url, options) {
  return new Promise(function (resolve, reject) {
    fetch(url, options)
      .then(function (response) {
        return new Promise(function (jsonResolve, jsonReject) {
          if (response.status === 401) {
            jsonReject(401);
          } else {
            response
              .json()
              .then(function (jsonData) {
                jsonResolve({
                  status: response.status,
                  json: jsonData,
                });
              })
              .catch(jsonReject);
          }
        });
      })
      .then(resolve)
      .catch((error) => {
        if (error === 401) {
          dispatch(logoutAction());
        } else {
          reject(error);
        }
      });
  });
}
