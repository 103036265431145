import { SurveyConfig, SurveyResult } from 'modules/survey-volkno/types';
import apiFetch from 'utils/fetch';

export interface VolknoSurveyApi {
  fetchById: (surveyId: string) => Promise<SurveyConfig>;
  submitResult: (
    surveyId: string,
    result: SurveyResult,
    mediaId: number,
    mediaItemId: number
  ) => Promise<void>;
}

interface HttpResponse<T> {
  json: T;
}

export const volknoSurveyApiFactory = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  browserFetch: typeof apiFetch = apiFetch
): VolknoSurveyApi => ({
  fetchById: async (surveyId: string) => {
    const result: HttpResponse<SurveyConfig> = await browserFetch('GET', {
      url: `${process.env.REACT_APP_SURVEY_API_BASE}/survey/${surveyId}`,
    });

    return result.json;
  },
  submitResult: async (surveyId, result, mediaId, mediaItemId) => {
    await browserFetch('POST', {
      url: `${process.env.REACT_APP_SURVEY_API_BASE}/survey/${surveyId}/result`,
      body: {
        result,
        meta: {
          mediaId,
          mediaItemId,
        },
      },
    });
  },
});
