import React from 'react';
import { Link } from 'react-router';
import './styles.scss';

export default class SidebarPublic extends React.Component {
  render() {
    return (
      <div className="sidebar public">
        <div className="volkno">
          <div className="sprite sprite-volkno-logo-white"></div>
        </div>
        <ul className="volkno-nav">
          <li>
            <Link to="/welcome" activeClassName="selected">
              Login / Sign-up
            </Link>
          </li>
          <li>
            <Link to="/about" activeClassName="selected">
              About volKno
            </Link>
          </li>
          <li>
            <Link to="/contact" activeClassName="selected">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}
