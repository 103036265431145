import { messageActions } from 'modules/react-message';
import Immutable from 'seamless-immutable';
import apiFetch from 'utils/fetch';

const cfg = process.env;
// action types
export const actionTypes = {
  UPDATE: 'volkno/media/UPDATE',
  IS_BUSY: 'volkno/media/IS_BUSY',
  ADD_FAVORITE: 'volkno/media/ADD_FAVORITE',
  DEL_FAVORITE: 'volkno/media/DEL_FAVORITE',
  CLEAR: 'volkno/media/CLEAR',
};

// reducers
const initialState = Immutable({
  lists: {},
  data: {},
  busy: true,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAR:
      return Immutable({
        lists: {},
        data: {},
        busy: false,
      });

    case actionTypes.UPDATE:
      return state.merge(action.payload, { deep: true });

    case actionTypes.IS_BUSY:
      return state.merge(
        {
          busy: action.payload,
        },
        { deep: true }
      );

    case actionTypes.ADD_FAVORITE:
      return state.setIn(['data', action.payload, 'favorite'], true);

    case actionTypes.DEL_FAVORITE:
      return state.setIn(['data', action.payload, 'favorite'], false);

    default:
      return state;
  }
}

// action creators
export function clear() {
  return {
    type: actionTypes.CLEAR,
  };
}

export function addFavorite(mediaId, audienceMediaId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_FAVORITE,
      payload: mediaId,
    });
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/media/favorite/${audienceMediaId}`,
    };
    apiFetch('post', options);
  };
}

export function delFavorite(mediaId, audienceMediaId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DEL_FAVORITE,
      payload: mediaId,
    });
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/media/favorite/${audienceMediaId}`,
    };
    apiFetch('delete', options);
  };
}

export function mediaItemAction(mediaId, mediaItemId, action, meta) {
  apiFetch('post', {
    url: `${cfg.REACT_APP_API_BASE}/media/action/${mediaId}/${mediaItemId}`,
    body: {
      action,
      meta,
    },
  });
}

export function get(code, noCache, related, onDone = () => {}) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    dispatch({ type: actionTypes.IS_BUSY, payload: true });
    const query = {};
    if (related) {
      query.related = related;
    }
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/media/list/${code}`,
      query,
    };
    apiFetch('get', options, dispatch, noCache)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: actionTypes.UPDATE,
            payload: resp.json,
          });
        } else {
          dispatch(messageActions.displayError(resp.json.error));
        }
        dispatch({ type: actionTypes.IS_BUSY, payload: false });
        onDone();
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
