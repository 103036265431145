import { messageActions } from 'modules/react-message';
import apiFetch from 'utils/fetch';
import Immutable from 'seamless-immutable';
import * as surveysActions from './surveys';
import * as earningsActions from './earnings';

// eslint-disable-next-line no-undef
const cfg = process.env;
// action types
export const actionTypes = {
  IS_BUSY: 'volkno/results/IS_BUSY',
  UPDATE_SUMMARY: 'volkno/results/UPDATE_SUMMARY',
  SET_RESULTS_LOCALLY: 'volkno/results/SET_RESULTS_LOCALLY',
};

// reducers
const initialState = Immutable({
  busy: false,
  summary: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.IS_BUSY:
      return state.merge(
        {
          busy: action.payload,
        },
        { deep: true }
      );

    case actionTypes.UPDATE_SUMMARY:
      return state.merge(
        {
          summary: action.payload,
          busy: false,
        },
        { deep: true }
      );

    case actionTypes.SET_RESULTS_LOCALLY:
      return state.merge(
        {
          summary: action.payload,
        },
        { deep: true }
      );

    default:
      return state;
  }
}

// action creators
// eslint-disable-next-line @typescript-eslint/no-empty-function
export function send(data, onSuccess = () => {}, onFailure = () => {}, silent) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());

    if (!silent) {
      dispatch({ type: actionTypes.IS_BUSY, payload: true });
    }

    const options = {
      url: `${cfg.REACT_APP_API_BASE}/results`,
      body: data,
    };

    apiFetch('post', options, dispatch)
      .then((resp) => {
        if (resp.status === 200) {
          if (resp.json.newSurvey) {
            dispatch(surveysActions.getSurveys());
            setTimeout(() => {
              dispatch(earningsActions.manualAlert('SURVEY_UNLOCKED'));
            }, 2000);
          }

          onSuccess();
        } else {
          dispatch(messageActions.displayError(resp.json.error));
          onFailure();
        }

        if (!silent) {
          dispatch({ type: actionTypes.IS_BUSY, payload: false });
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}

export function getSummaryResults(mediaId, mediaItemId, challengeKey) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    dispatch({ type: actionTypes.IS_BUSY, payload: true });

    const options = {
      url: `${cfg.REACT_APP_API_BASE}/results/summary/${mediaId}/${mediaItemId}/${challengeKey}`,
    };

    apiFetch('get', options, dispatch)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: actionTypes.UPDATE_SUMMARY,
            payload: {
              [challengeKey]: resp.json.challenge_value,
            },
          });
        } else {
          dispatch(messageActions.displayError(resp.json.error));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}

export function setLocalResults(challengeKey, value) {
  return {
    type: actionTypes.SET_RESULTS_LOCALLY,
    payload: {
      [challengeKey]: value,
    },
  };
}
