import React from 'react';
import './styles.scss';

export default function (props) {
  return (
    <div className="page-contact page-margin">
      <h2>Contact</h2>
      <div className="biline">
        We appreciate any feedback that will help us make this a better
        experience for you. Feel free to contact us at:{' '}
        <a href="mailto:support@volkno.com">support@volkno.com</a>
      </div>
    </div>
  );
}
