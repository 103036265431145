import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import cx from 'classnames';
import formsyErrorFix from './formsyErrorFix';

// Checkbox for formsy

@formsyErrorFix
class Checkbox extends React.Component {
  static propTypes = {
    setValue: PropTypes.func,
    getValue: PropTypes.func,
    isFormDisabled: PropTypes.func,
    onChange: PropTypes.func,
    getErrorText: PropTypes.func,
    value: PropTypes.any,
    text: PropTypes.any,
    name: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };
  onChange(e) {
    this.props.setValue(e.target.checked);
    if (this.props.hasOwnProperty('onChange')) {
      this.props.onChange(e.target.checked);
    }
  }
  render() {
    const {
      getValue,
      value,
      isFormDisabled,
      disabled,
      getErrorText,
      name,
      id,
      text,
      className,
    } = this.props;
    const hasError = getErrorText();
    return (
      <div className={cx('row checkbox', className, { 'has-error': hasError })}>
        <div className="col-1">
          <ReactToggle
            id={id}
            name={name}
            defaultChecked={value}
            value={Boolean(getValue())}
            disabled={isFormDisabled() || disabled}
            onChange={this.onChange.bind(this)}
          />
        </div>
        <div className="col-11">{text}</div>
      </div>
    );
  }
}

// copied/modified from: https://github.com/instructure-react/react-toggle
class ReactToggle extends React.Component {
  static propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.bool,
    id: PropTypes.string,
    'aria-labelledby': PropTypes.string,
    'aria-label': PropTypes.string,
  };

  constructor(props) {
    super(props);
    let checked = false;
    if ('checked' in this.props) {
      checked = this.props.checked;
    } else if ('defaultChecked' in this.props) {
      checked = this.props.defaultChecked;
    }
    this.state = {
      checked: !!checked,
      hasFocus: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if ('checked' in nextProps) {
      this.setState({ checked: !!nextProps.checked });
    }
  }

  handleClick(event) {
    const checkbox = this.refs.input;
    if (event.target !== checkbox) {
      event.preventDefault();
      checkbox.focus();
      checkbox.click();
      return;
    }

    if (!('checked' in this.props)) {
      this.setState({ checked: checkbox.checked });
    }
  }

  handleFocus() {
    this.setState({ hasFocus: true });
  }

  handleBlur() {
    this.setState({ hasFocus: false });
  }

  render() {
    const classes = cx('react-toggle', {
      'react-toggle--checked': this.state.checked,
      'react-toggle--focus': this.state.hasFocus,
      'react-toggle--disabled': this.props.disabled,
    });

    return (
      <div
        className={classes}
        onClick={this.handleClick.bind(this)}
        data-test={this.props.name}
      >
        <div className="react-toggle-track">
          <div className="react-toggle-track-check"></div>
          <div className="react-toggle-track-x"></div>
        </div>
        <div className="react-toggle-thumb"></div>
        <input
          ref="input"
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          className="react-toggle-screenreader-only"
          type="checkbox"
          defaultChecked={this.props.defaultChecked}
          onChange={this.props.onChange}
          id={this.props.id}
          name={this.props.name}
        />
      </div>
    );
  }
}

export default withFormsy(Checkbox);
