import React from 'react';
import cx from 'classnames';
import './styles.scss';
const secondsBeforeReminder = 20;

export default class Reminder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  componentWillMount() {
    this.to = setTimeout(() => {
      this.setState({ show: true });
    }, secondsBeforeReminder * 1000);
  }
  componentWillUnmount() {
    clearTimeout(this.to);
  }
  render() {
    const { children, hide } = this.props;
    const { show } = this.state;
    return (
      <div className="reminder D-ib Ws-nw">
        <div className={cx('reminder-arrow', { bounce: !hide && show })}>
          {children}
        </div>
      </div>
    );
  }
}
