import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as mediaActions from 'ducks/media';
import Loader from 'components/common/Loader';
import Description from './Description';
import Video from './Video';
import Summary from './Summary';
import * as loginActions from 'modules/react-login/ducks/login';

import './styles.scss';
import { useGetMediaUrl } from './hooks/data-fetching/get-media-url';

/*
  media > item > subpage
  URL     URL    STATE
*/

const MediaComponent = ({
  location,
  dispatch,
  params: { mediaId, itemId },
  media: { data, lists },
  location: { query = {} },
  isLoggedIn,
  publicLoginError,
}) => {
  const { noCache = false } = query;
  const isExternalUser = location.pathname.indexOf('/cint') === 0;
  const [state, setState] = useState({ subpage: 0 });
  const getMedia = useCallback(() => {
    dispatch(mediaActions.get('ID-' + mediaId, noCache));
  }, [dispatch, mediaId, noCache]);

  const getRecommended = useCallback(() => {
    dispatch(mediaActions.get('RECOMMENDED', noCache, mediaId));
  }, [mediaId, noCache, dispatch]);

  const nextSubpage = () => {
    setState({
      subpage: state.subpage + 1,
    });
  };

  const restart = () => {
    setState(() => {
      getRecommended();
      return {
        subpage: 0,
      };
    });
  };

  useEffect(() => {
    if (isExternalUser) {
      dispatch(loginActions.publicLogin(query));
    }
  }, [dispatch, isExternalUser, query]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    if (!data[mediaId]) {
      getMedia();
    }

    if (!lists.RECOMMENDED && !isExternalUser) {
      getRecommended();
    }
  }, [
    data,
    mediaId,
    getMedia,
    getRecommended,
    lists.RECOMMENDED,
    isLoggedIn,
    isExternalUser,
  ]);

  const { subpage } = state;
  const recommended = (lists.RECOMMENDED || []).map((mediaId) => data[mediaId]);
  const mediaData = data[mediaId] || {};
  const itemData =
    (mediaData && mediaData.items && mediaData.items.data[itemId]) || false;

  const { isLoading, data: mediaItemInfo } = useGetMediaUrl(
    itemData.media_item_id,
    {
      depends: [itemData.media_item_id],
    }
  );
  const videoUrl = React.useMemo(() => {
    if (mediaData.secure_urls === 1) {
      return mediaItemInfo && mediaItemInfo.url;
    }
    return itemData.url_1;
  }, [mediaData.secure_urls, itemData.url_1, mediaItemInfo]);

  if (publicLoginError) {
    return (
      <div
        className="W-100 Ta-c"
        style={{
          height: '500px',
          display: 'flex',
          'justify-content': 'center',
          'align-items': 'center',
        }}
      >
        An error occurred.
        <br />
        Your link may be stale or invalid.
      </div>
    );
  }

  if (!itemData) {
    return (
      <div className="Ta-c P-30">
        <Loader />
      </div>
    );
  }
  return (
    <div className="page-media">
      {subpage === 0 && (
        <Description
          media={mediaData}
          item={itemData}
          nextSubpage={nextSubpage}
        />
      )}
      {subpage === 1 && !isLoading && videoUrl && (
        <Video
          media={mediaData}
          item={itemData}
          nextSubpage={nextSubpage}
          videoUrl={videoUrl}
          showUserIdOverlay={mediaData.show_user_id_overlay === 1}
          allowSeeking={mediaData.allow_seeking === 1}
          query={query}
        />
      )}
      {subpage === 2 && (
        <Summary
          media={mediaData}
          item={itemData}
          recommended={recommended}
          restart={restart}
          nextSubpage={nextSubpage}
          query={query}
        />
      )}
    </div>
  );
};

MediaComponent.propTypes = {
  // Injected from react-router
  params: PropTypes.object,
  location: PropTypes.object,

  dispatch: PropTypes.func,
  media: PropTypes.object,
  app: PropTypes.object,
};

const mapStateToProps = (state) => ({
  media: state.media,
  app: state.app,
  isLoggedIn: state.loginState.login.isLoggedIn,
  publicLoginError: state.loginState.login.publicLoginError,
});

export default connect(mapStateToProps)(MediaComponent);
