import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';

class Terms extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };
  static contextTypes = {
    router: PropTypes.object,
  };
  render() {
    return (
      <div className="page-terms">
        <div className="page-margin">
          <h2>GDPR Compliance and Your Data Privacy</h2>
          <p>
            At Volkno, we are fully committed to complying with the General Data
            Protection Regulation (GDPR) and respecting your data privacy. We
            understand the importance of your personal data and are dedicated to
            protecting it.
          </p>

          <p>Your Rights Under GDPR:</p>
          <p>
            As a user, the GDPR grants you several rights regarding your
            personal data. These rights include the ability to access, rectify,
            and, under certain conditions, request the deletion of your personal
            data.
          </p>

          <p>Requesting Data Deletion:</p>
          <p>
            If you wish to have your personal data removed from our systems, you
            are entitled to do so. To initiate the process of data deletion,
            please send us an email at{' '}
            <a
              href="mailto:support@volkno.com"
              style={{ textDecoration: 'underline' }}
            >
              support@volkno.com
            </a>{' '}
            with the following information:
          </p>

          <p>Your full name and email address associated with your account</p>
          <p>A clear statement of your request for data deletion</p>
          <p>
            Upon receiving your request, we will promptly verify your identity
            and proceed with processing your request in accordance with GDPR
            regulations. Please note that some data may need to be retained for
            legal or regulatory reasons, but we will inform you of any such
            requirements.
          </p>

          <p>Questions and More Information:</p>
          <p>
            If you have any questions about how we handle your personal data or
            about GDPR compliance, feel free to reach out to us at{' '}
            <a
              href="mailto:support@volkno.com"
              style={{ textDecoration: 'underline' }}
            >
              support@volkno.com
            </a>
            . We are here to help and provide any additional information you
            might need.
          </p>

          <p>
            Thank you for trusting Volkno. Your privacy and data security are
            our top priorities.
          </p>
        </div>
      </div>
    );
  }
}
export default connect((state) => ({
  app: state.app,
}))(Terms);
