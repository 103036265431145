import React, { useCallback, useState } from 'react';
import {
  MultipleChoiceQuestion,
  SurveyOption,
} from 'modules/survey-volkno/types';
import {
  RenderedOptionContainer,
  OptionImage,
  OptionsListContainer,
  OptionContainer,
  QuestionGrid,
} from './styles';
import { randomizeArray } from 'utils/randomizeArray';

const renderOption = (option: SurveyOption) => (
  <RenderedOptionContainer>
    {option.message}
    {option.image && (
      <OptionImage alt={`${option.id}-option-image`} src={option.image} />
    )}
  </RenderedOptionContainer>
);

export const MultipleChoiceQuestionComponent: React.FC<{
  question: MultipleChoiceQuestion;
  onAnswer: (selectedOption: string[]) => any;
  options: SurveyOption[];
  id: string;
}> = ({ question, options, onAnswer = () => {} }) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);
  const orderedOptions = React.useMemo(
    () => (question.randomize ? randomizeArray(options) : options),
    [question.randomize, options]
  );

  const toggleOption = useCallback(
    (option: SurveyOption) => {
      const newSelectedOptions = Array.from(selectedOptions);
      const alreadySelectedOptionIndex = newSelectedOptions.findIndex(
        (v) => v === option.id
      );
      if (alreadySelectedOptionIndex === -1) {
        newSelectedOptions.push(option.id);
      } else {
        newSelectedOptions.splice(alreadySelectedOptionIndex, 1);
      }

      if (
        question.maxAnswersSelected &&
        newSelectedOptions.length > question.maxAnswersSelected
      ) {
        newSelectedOptions.shift();
      }
      setSelectedOptions(newSelectedOptions);
      onAnswer(Array.from(newSelectedOptions));
    },
    [selectedOptions, setSelectedOptions, onAnswer, question]
  );

  return (
    <QuestionGrid>
      <div>
        <OptionsListContainer>
          {orderedOptions.map((option) => (
            <OptionContainer
              onClick={() => toggleOption(option)}
              key={`survey-question-option-${option.id}`}
              className={selectedOptions.includes(option.id) ? 'selected' : ''}
            >
              {renderOption(option)}
            </OptionContainer>
          ))}
        </OptionsListContainer>
      </div>
    </QuestionGrid>
  );
};
