import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.scss';

function Star(props) {
  return (
    <div {...props} className={cx('rate-flame', { selected: props.selected })}>
      <div>
        <div className="sprite sprite-flame-white"></div>
      </div>
    </div>
  );
}

export default class Rate extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      rating: props.rating || 5,
      hoverAt: null,
    };
  }

  handleMouseOver(idx, evt) {
    this.setState({
      ...this.state,
      hoverAt: idx + 1,
    });
  }

  handleMouseOut(idx, evt) {
    this.setState({
      ...this.state,
      hoverAt: null,
    });
  }

  handleClick(idx, evt) {
    this.props.onChange(idx + 1);
    this.setState({
      ...this.state,
      rating: idx + 1,
    });
  }

  render() {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      const rating =
        this.state.hoverAt != null ? this.state.hoverAt : this.state.rating;
      const selected = i < rating;
      stars.push(
        <Star
          key={'rate_' + i}
          selected={selected}
          onMouseOver={this.handleMouseOver.bind(this, i)}
          onMouseOut={this.handleMouseOut.bind(this, i)}
          onClick={this.handleClick.bind(this, i)}
        />
      );
    }
    return <div className="rate-5star">{stars}</div>;
  }
}
