import React from 'react';
import './styles.scss';

export default function (props) {
  let cls = 'joy-icon ';
  cls += props.className ? props.className + ' ' : '';
  if (props.onClick) {
    cls += 'Cur-p';
  }
  return (
    <div className={cls} onClick={props.onClick || function () {}}>
      <div className="sprite sprite-joy"></div>
    </div>
  );
}
