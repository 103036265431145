import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactTelInput from 'react-telephone-input';
import { withFormsy } from 'formsy-react';
import formsyErrorFix from './formsyErrorFix';

// Input for formsy

@formsyErrorFix
class Phone extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    getErrorText: PropTypes.func,
    setValue: PropTypes.func,
    isFormDisabled: PropTypes.func,
    getValue: PropTypes.func,
    disabled: PropTypes.bool,
    title: PropTypes.any,
    value: PropTypes.any,
  };
  constructor(props) {
    super(props);
    this.state = {
      // hack because react-telephone-input fires onChange on componentDidMount
      ignoreFirstOnchange: true,
    };
  }
  onChange(number) {
    if (!this.state.ignoreFirstOnchange) {
      this.props.setValue(number);
    } else {
      this.setState({ ignoreFirstOnchange: false });
    }
  }
  render() {
    const {
      name,
      title,
      className,
      getErrorText,
      isFormDisabled,
      disabled,
      getValue,
    } = this.props;
    const hasError = getErrorText();
    const id = name + '-dd';
    return (
      <div
        className={cx('form-group', className, { 'has-error': hasError })}
        data-test={name}
      >
        <label htmlFor={id}>{title}</label>
        <ReactTelInput
          value={getValue()}
          defaultCountry="us"
          autoFormat
          flagsImagePath="//s3-us-west-2.amazonaws.com/sanguine-prod/public/flags.png"
          onChange={this.onChange.bind(this)}
          disabled={isFormDisabled() || disabled}
        />
        <span className="validation-error">{hasError}</span>
      </div>
    );
  }
}

export default withFormsy(Phone);
