import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import cx from 'classnames';
import formsyErrorFix from './formsyErrorFix';

// Number for formsy

@formsyErrorFix
class Number extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    getErrorText: PropTypes.func,
    setValue: PropTypes.func,
    isFormDisabled: PropTypes.func,
    getValue: PropTypes.func,
    title: PropTypes.any,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.any,
  };
  onChange(e) {
    this.props.setValue(e.currentTarget.value);
  }
  render() {
    const {
      getValue,
      name,
      title,
      className,
      getErrorText,
      isFormDisabled,
      disabled,
    } = this.props;
    const hasError = getErrorText();
    const id = name + '-dd';
    const numberProps = {};
    if (typeof this.props.min !== 'undefined') {
      numberProps.min = this.props.min;
    }
    if (typeof this.props.max !== 'undefined') {
      numberProps.max = this.props.max;
    }
    if (typeof this.props.step !== 'undefined') {
      numberProps.step = this.props.step;
    }
    return (
      <div
        className={cx('form-group', className, { 'has-error': hasError })}
        data-test={name}
      >
        <label htmlFor={id}>{title}</label>
        <input
          className="form-control"
          type="number"
          name={name}
          {...numberProps}
          onChange={this.onChange.bind(this)}
          value={getValue()}
          disabled={isFormDisabled() || disabled}
        />
        <span className="validation-error">{hasError}</span>
      </div>
    );
  }
}

export default withFormsy(Number);
