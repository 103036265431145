import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import cx from 'classnames';
import shuffle from 'lodash/shuffle';

import * as resultsActions from 'ducks/results';
import * as earningsActions from 'ducks/earnings';

import Rate from 'components/common/Rate';
import Loader from 'components/common/Loader';

import './styles.scss';

const PLATFORMS = [
  'Netflix',
  'Hulu',
  'Amazon',
  'YouTube',
  'Theatre',
  'TV',
  'VOD',
  'Facebook',
  'Twitter',
  'HBO Max',
  'Disney +',
  'Apple TV +',
  'Peacock',
  'Paramount +',
  'Pluto TV',
  'Twitch',
  'TikTok',
  'Snapchat',
];

const platformList = shuffle(PLATFORMS).concat('Other');

class DemandIt extends Component {
  static propTypes = {
    busy: PropTypes.bool,
    result: PropTypes.object,

    dispatch: PropTypes.func,
    sendExtraFeedback: PropTypes.func,
  };

  state = {
    platforms: [],
    rating: 5,
  };

  componentWillMount() {
    const {
      params: { mediaId, itemId },
      dispatch,
    } = this.props;

    dispatch(resultsActions.getSummaryResults(mediaId, itemId, 'demandit'));
  }

  componentWillUnmount() {
    const { dispatch, sendExtraFeedback } = this.props;
    const { platforms, rating } = this.state;

    // Send feedback only if any platform is selected
    if (platforms.length > 0) {
      sendExtraFeedback(
        'demandit',
        {
          platforms,
          rating,
        },
        () => {
          dispatch(earningsActions.getAll());
        }
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const { busy } = this.props;

    if (busy && !nextProps.busy && nextProps.summary) {
      this.setState(
        nextProps.summary.asMutable({
          deep: true,
        })
      );
    }
  }

  togglePlatform = (platform) => () => {
    const { platforms } = this.state;
    const platformIndex = platforms.indexOf(platform);

    if (platformIndex === -1) {
      platforms.push(platform);
    } else {
      platforms.splice(platformIndex, 1);
    }

    this.setState({ platforms });
  };

  onRateChange = (rating) => {
    this.setState({ rating });
  };

  render() {
    const { platforms, rating } = this.state;
    const { busy } = this.props;

    return (
      <div className="demand-it">
        <h1>Demand It</h1>

        <div className="row first">
          <div className="col-sm-5 Mb-15">How much do you want to see it?</div>
          <div className="col-sm-7">
            {busy ? (
              <Loader />
            ) : (
              <Rate onChange={this.onRateChange} rating={rating} />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-5 Mb-15">Where do you want to see it?</div>
          <div className="col-sm-7">
            {busy ? (
              <Loader />
            ) : (
              platformList.map((platform, i) => (
                <div
                  key={platform}
                  onClick={this.togglePlatform(platform)}
                  className={cx('tag D-ib Mr-20', {
                    selected: platforms.indexOf(platform) > -1,
                  })}
                >
                  {platform}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  busy: state.results.busy,
  summary: state.results.summary.demandit,
});

export default compose(withRouter, connect(mapStateToProps))(DemandIt);
