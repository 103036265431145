import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoginComponent } from 'modules/react-login';
import { Link } from 'react-router';
import { Form, Input, FacebookLogin } from 'modules/react-forms';
import * as createActions from 'modules/react-login/ducks/create';
import { formatErrors } from 'modules/utils-validation';
import './styles.scss';

class Welcome extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
  };
  static contextTypes = {
    url: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      confirmEmail: false,
    };
  }
  handleCreateCall(data, resetForm, handleErrors) {
    const { dispatch, app } = this.props;
    const host = window.location.protocol + '//' + window.location.hostname;
    const returnURL = host + '/welcome?action=login&confirm=%TOKEN%';
    const onSuccess = () => {
      this.setState({
        confirmEmail: true,
      });
    };
    const onFailure = (resp) => {
      if (resp.status === 406) {
        handleErrors(formatErrors(resp.json.errorData));
      }
    };
    data.app = app;
    dispatch(createActions.createCall(data, returnURL, onSuccess, onFailure));
  }
  render() {
    let { action = 'create' } = this.context.url.query;
    const { confirmEmail } = this.state;
    return (
      <div className="welcome-page container-fluid">
        <section>
          <div className="row logo-area">
            <div className="col-6 mobile-0px-padding">
              <div className="sprite sprite-volkno-logo-red"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm-6 order-sm-2 order-1 P-0 Pos-r Z-1 P-0 Pos-r Z-1">
                <LoginComponent path="/welcome" app="volkno" action={action} />
              </div>
              <div className="col-sm-6 order-sm-1 order-2">
                <h1 className="red-bar">What's Hot?</h1>
                <p className="vlead">
                  Watch and Rate Videos for New Movies, TV Shows, and More.
                </p>
                <p className="desc">
                  Do you want to have an impact on which movies and TV shows get
                  made and released? Want to let studios know what you want to
                  see and how you want to see it? Even better, want to get paid
                  for doing it? Then VolKno is for you! Simply watch videos,
                  give feedback and get paid for doing it. On VolKno you decide
                  what’s hot. Create an account to get started today!
                </p>
                <ul className="media-icons">
                  <li>
                    <div className="lp-icons lp-ico-film"></div>
                    Movies
                  </li>
                  <li>
                    <div className="lp-icons lp-ico-tv"></div>
                    TV Shows
                  </li>
                  <li>
                    <div className="lp-icons lp-ico-game"></div>
                    Games
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="section-2">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="red-bar">Watch Videos. Give Feedback.</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 order-2">
                <h3>
                  <span>1</span>Watch Videos.
                </h3>
                <p className="desc">
                  VolKno has videos for new Movies, TV Shows, Digital Content,
                  Video Games and more. You simply have to watch and provide
                  feedback on what you like/dislike and how it makes you feel.
                  VolKno captures your feedback and rewards you with Flow
                  (VolKno reward points).
                </p>
                <div className="callout">
                  <div className="lp-icons lp-ico-videos"></div>
                  <span>
                    We've developed simple, fun, interactive tools for you to
                    give feedback and share your opinions while watching videos.
                  </span>
                </div>
              </div>
              <div className="col-md-6 order-1">
                <img alt="" src="/images/lp-1.jpg" className="lp-img" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-3">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3>
                  <span>2</span>Give Feedback. Earn Flow.
                </h3>
                <p className="desc">
                  What's Flow? It's our reward point system. Your activity and
                  quality of feedback directly impact the amount of Flow you
                  earn and at what rate you earn it. Just give quality feedback
                  and you will earn more Flow faster as well as gain access to
                  exclusive content.
                </p>
                <div className="callout">
                  <div className="lp-icons lp-ico-feedback"></div>
                  <span>
                    Your feedback is measured against like feedback to determine
                    overall quality. Better quality feedback equals more flow.
                  </span>
                </div>
              </div>
              <div className="col-md-6 Ta-r">
                <img alt="" src="/images/lp-2.jpg" className="lp-img" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-4">
          <div className="container">
            <div className="row">
              <div className="col-md-6 order-2">
                <h3>
                  <span>3</span>Redeem Flow. Get Paid.
                </h3>
                <p className="desc">
                  You can redeem your Flow for popular gift cards in our store.
                  Once redeemed you will receive the gift card via email.
                </p>
                <div className="callout">
                  <div className="lp-icons lp-ico-giftcard"></div>
                  <span style={{ padding: '8px 10px' }}>
                    Amazon and soon many more.
                  </span>
                </div>
              </div>
              <div className="col-md-6 order-1">
                <img alt="" src="/images/lp-3.jpg" className="lp-img" />
              </div>
            </div>
          </div>
        </section>

        <section className="signup">
          <div className="container">
            <div className="row">
              <div className="col-md-12 ready">
                Ready to start earning flow? Create an Account
                <div className="us-only">
                  <img
                    alt=""
                    src="/images/us-flag.svg"
                    height="12"
                    width="16"
                  />
                  US residents only
                </div>
              </div>
            </div>
            <div className="row">
              {confirmEmail ? (
                <div className="col-md-12">
                  <h1>Confirm your email</h1>
                  <p>
                    You will receive an email from us momentarily. Please click
                    the link in that email to activate your account.
                  </p>
                </div>
              ) : (
                <Form
                  onValidSubmit={this.handleCreateCall.bind(this)}
                  className="signup-form"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-lg-3">
                        <Input
                          type="input"
                          title="Email Address"
                          name="email"
                          iconClass="fa fa-envelope-o"
                          value={undefined}
                          validations="EMAIL"
                          validationError="Enter valid email"
                          required
                        />
                      </div>
                      <div className="col-12 col-lg-3">
                        <Input
                          type="password"
                          title="Password"
                          name="password"
                          iconClass="fa fa-lock"
                          maxLength={16}
                          value={undefined}
                          validations="PASSWORD"
                          validationError="Enter valid password"
                          required
                        />
                      </div>
                      <div className="col-12 col-lg-3 Pt-15">
                        <button type="submit" className="btn btn-primary My-10">
                          Create Account
                        </button>
                      </div>
                      <div className="col-12 col-lg-1 Pt-30 Ta-c or-fb">
                        or sign in with
                      </div>
                      <div className="col-12 col-lg-2 Pt-30">
                        <FacebookLogin />
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </section>

        <section className="mission">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="red-bar">Bridging the Gap</h2>
                <p className="desc">
                  volKno (vol-kay-no) works with top content creators to bridge
                  the gap between you, the audience, and the content they
                  create. The end result is more of the Movies, TV Shows and
                  Games you want to see/play.
                </p>
                <p className="desc">
                  <Link to="/about">Learn More &gt;</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default connect()(Welcome);
