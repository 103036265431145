import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'components/common/Loader';
import { Form, Textarea, Input } from 'modules/react-forms';
import * as appActions from 'ducks/app';
import {
  Modal,
  FormGroup,
  FormCheck as Radio,
  FormLabel,
} from 'react-bootstrap';
import './styles.scss';

const loading = (
  <div className="Ta-c P-30">
    <Loader />
  </div>
);

class AddressVerification extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };
  static contextTypes = {
    url: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      tempAddress: null,
      checkedAddress: null,
      resubmit: false,
      invalidToken: false,
      showSuccessModal: false,
      showError: null,
    };

    this.closeModal = this.closeModal.bind(this);
  }
  componentWillMount() {
    this.fetchUserData();
  }
  fetchUserData() {
    this.props.dispatch(
      appActions.get(
        {
          noCache: false,
          silent: true,
        },
        () => {}
      )
    );
  }
  handleSearchSubmit(data, resetForm, handleErrors) {
    const payload = {
      address: data.address,
    };
    const { dispatch, app } = this.props;
    const onSuccess = (resp) => {
      const addressesFiltered = resp.address.reduce((prev, address) => {
        address.parts = {};
        address.address_components.forEach((component) => {
          component.types.forEach((type) => {
            address.parts[type] = {
              short: component.short_name,
              long: component.long_name,
            };
          });
        });
        if (
          app.validCountries.indexOf(address.parts.country.short) > -1 &&
          address.parts.hasOwnProperty('route') &&
          (address.parts.hasOwnProperty('street_number') ||
            address.parts.hasOwnProperty('subpremise'))
        ) {
          prev.push(address);
        }
        return prev;
      }, []);
      this.setState({ tempAddress: addressesFiltered });
    };
    const onFailure = (resp) => {
      this.setState({ showError: resp.json.errorData });
    };
    dispatch(appActions.searchAddress(payload, onSuccess, onFailure));
  }
  onModalCheck(el, index) {
    this.setState({ checkedAddress: index });
  }
  handleModalSubmit(data, formReset, handleErrors) {
    const { checkedAddress, tempAddress } = this.state;
    if (checkedAddress === null || !tempAddress[checkedAddress]) {
      return;
    }
    const selectedAddress = tempAddress[checkedAddress];
    const {
      street_number = {},
      route = {},
      subpremise = {},
      company = {},
      locality = {},
      sublocality = {},
      neighborhood = {},
      country = {},
      administrative_area_level_1 = {},
      postal_code = {},
    } = selectedAddress.parts;

    const payload = {
      formatted_address: selectedAddress.formatted_address,
      address_id: selectedAddress.place_id,
      street1: `${street_number.long || ''} ${route.long}`.trim(),
      street2: subpremise.long || null,
      company: company.short || null,
      city: locality.long || sublocality.long || neighborhood.long,
      country: country.short,
      state: administrative_area_level_1.short,
      postal_code: postal_code.short,
    };

    const { dispatch } = this.props;
    const onSuccess = (resp) => {
      this.fetchUserData();
      this.closeModal();
      this.setState({ showSuccessModal: true });
    };
    const onFailure = (resp) => {
      this.setState({ showError: true });
    };
    dispatch(appActions.updateAddress(payload, onSuccess, onFailure));
  }
  verifyAddress(data, resetForm, handleErrors) {
    const onSuccess = () => {
      this.closeCodeModal();
      this.fetchUserData();
    };
    const onFailure = (resp) => {
      this.setState({ invalidToken: true });
    };
    const { dispatch } = this.props;
    dispatch(appActions.verifyAddress(data, onSuccess, onFailure));
  }
  closeCodeModal() {
    this.setState({ verifyModal: false, invalidToken: false });
  }
  closeModal() {
    this.setState({ tempAddress: null, resubmit: false, showError: null });
  }
  getVerifyModal(address) {
    const maxAddresses = 5;
    return (
      <Modal
        show={true}
        size="lg"
        className="address-modal"
        onHide={this.closeModal.bind(this)}
      >
        <Form
          onValidSubmit={this.handleModalSubmit.bind(this)}
          className="address-picker"
        >
          <Modal.Header closeButton>
            <div>
              <Modal.Title>Verify Address</Modal.Title>
              <div>Choose your address.</div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              {address.length ? (
                <div className="row Mx-5 choose-address-list">
                  {address.map((addressObj, index) => {
                    if (index >= maxAddresses) {
                      return null;
                    }
                    return (
                      <div
                        key={`address_option_${index}`}
                        className="W-20 mobile-100 D-ib Va-t addressBox"
                      >
                        <FormGroup>
                          <FormLabel>
                            <Radio
                              onChange={(isChecked) =>
                                this.onModalCheck(isChecked, index)
                              }
                              id={`address_${index}`}
                              name={'address'}
                              label={addressObj.formatted_address}
                              type="radio"
                            />
                          </FormLabel>
                        </FormGroup>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="Ta-c">
                  <strong>No valid US address found.</strong>
                  <br />
                  <br />
                  Please check you entered the correct address.
                  <br />
                  P.O. boxes are not allowed.
                </div>
              )}
              {this.state.showError ? (
                <div>
                  <span className={'error'}>
                    Error updating address. Please refresh the page and try
                    again.
                  </span>
                </div>
              ) : null}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-actions">
              <button
                type="button"
                onClick={this.closeModal.bind(this)}
                className="btn btn-info My-10 mobile-100 Mx-10"
              >
                NO, RE-ENTER
              </button>
              <button
                type="submit"
                disabled={this.state.checkedAddress === null}
                className="btn btn-primary My-10 mobile-100 Mx-10"
              >
                YES, SAVE
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
  getCodeInputModal(address) {
    return (
      <Modal
        show
        size="lg"
        className="code-modal form-theme-lite"
        onHide={this.closeCodeModal.bind(this)}
      >
        <Form onValidSubmit={this.verifyAddress.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Verify Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please allow 1-2 weeks to recieve a postcard with a pin. The
              postcard will be sent to the address you provided.
            </p>
            <Input
              title="Enter PIN"
              type="input"
              name="token"
              validations="NUMBER"
              validationError="Enter Pin"
              maxLength={6}
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-actions">
              <button
                type="submit"
                className="btn btn-primary My-10 Mx-5 mobile-100"
              >
                <span aria-hidden="true">SUBMIT</span>
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
  getSuccessModal() {
    return (
      <Modal
        show
        size="lg"
        className="success-modal"
        onHide={() => this.setState({ showSuccessModal: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>Thank You</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            You will receive a postcard in the mail within 5-7 business days
            with a pin number to verify your address.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-actions">
            <button
              type="button"
              onClick={() => this.setState({ showSuccessModal: false })}
              className="btn btn-primary My-10 Mx-5 mobile-100"
            >
              <span aria-hidden="true">DONE</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
  render() {
    const { app, busy } = this.props;
    const user = app.userAddress;
    let content;
    if (this.state.showSuccessModal) {
      content = this.getSuccessModal();
    } else if (this.state.verifyModal) {
      content = this.getCodeInputModal();
    } else if (user.formatted_address && !this.state.resubmit) {
      content = (
        <div className="profile-address row">
          <div className="col-sm-7 mailingAddress">
            {!user.valid_address ? (
              <div className="desc">
                <img
                  alt=""
                  src="/images/postcard-view.png"
                  width="120"
                  className="F-r Mb-20 Ml-10"
                />
                To validate your address, enter the pin found on the postcard
                that you will receive in 1-2 weeks below:
              </div>
            ) : null}
            <label className="Bt Pt-20 W-100">Mailing Address</label>
            <div className="C-fff formattedAddress">
              {user.formatted_address}
              {user.valid_address ? (
                <div className="Fz-16 Fw-b">
                  <i className="fa fa-check-circle Mt-20" /> VERIFIED
                </div>
              ) : null}
            </div>
            {!user.valid_address ? (
              <button
                type="button"
                onClick={() => this.setState({ verifyModal: true })}
                className="btn btn-primary My-10 Mx-10 mobile-100"
              >
                <span aria-hidden="true">VERIFY ADDRESS</span>
              </button>
            ) : null}
            {user.canReverifyAddress ? (
              <button
                type="button"
                onClick={() => this.setState({ resubmit: true })}
                className="btn btn-info My-10 Mx-10 mobile-100"
              >
                CHANGE ADDRESS
              </button>
            ) : (
              <div className="Mt-20 Pt-20 Bt Fz-12">
                <strong>Moved or made a mistake?</strong>
                <br />
                You can revalidate your address once every{' '}
                {app.minDaysBeforeAddressReverify + 1} days.
              </div>
            )}
          </div>
        </div>
      );
    } else {
      content = (
        <Form
          onValidSubmit={this.handleSearchSubmit.bind(this)}
          className="address-verification"
        >
          <div className="row">
            <div className="col-sm-7">
              <div className="desc">
                <img
                  alt=""
                  src="/images/postcard-view.png"
                  width="120"
                  className="F-r Mb-20 Ml-20"
                />
                In order to redeem your gift cards, you must verify your mailing
                address. Type full mailing address below:
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-7">
              <Textarea
                title="Enter your mailing address"
                type="input"
                name="address"
                value={user.address}
                placeholder="Enter mailing address"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-7">
              <button
                type="submit"
                className="btn btn-primary My-10 mobile-100"
              >
                Lookup
              </button>
            </div>
          </div>
        </Form>
      );
    }
    return (
      <div>
        {busy ? loading : <div className="address-verification">{content}</div>}
        {this.state.tempAddress
          ? this.getVerifyModal(this.state.tempAddress)
          : null}
      </div>
    );
  }
}

export default connect((state) => ({
  app: state.app,
}))(AddressVerification);
