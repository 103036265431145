import React from 'react';
import PropTypes from 'prop-types';
import raf from 'raf';
import ease from 'ease-component';
import numeral from 'numeral';

export default class Counter extends React.Component {
  static propTypes = {
    begin: PropTypes.number,
    time: PropTypes.number,
    end: PropTypes.number,
    easing: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.begin,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.end !== nextProps.end) {
      this.go();
    }
    return true;
  }

  componentWillUnmount() {
    this.stop = true;
  }

  componentDidMount() {
    this.go();
  }

  go() {
    this.stop = false;
    this.start = Date.now();
    raf(this.animate.bind(this));
  }

  animate() {
    if (this.stop) return;
    raf(this.animate.bind(this));
    this.draw();
  }

  draw() {
    let { time, begin, end, easing } = this.props;
    easing = easing && easing in ease ? easing : 'outCube';
    let now = Date.now();
    if (now - this.start >= time) {
      this.stop = true;
    }
    let percentage = (now - this.start) / time;
    percentage = percentage > 1 ? 1 : percentage;
    let easeVal = ease[easing](percentage);
    let val = begin + (end - begin) * easeVal;

    this.setState({ value: val });
  }

  render() {
    return <span>{numeral(Math.round(this.state.value)).format('0,0')}</span>;
  }
}
