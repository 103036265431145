import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

class Profile extends React.Component {
  static propTypes = {
    close: PropTypes.func,
  };
  static contextTypes = {
    router: PropTypes.object,
  };
  goToSurveys() {
    this.props.close();
    setTimeout(() => {
      this.context.router.push(`/surveys`);
    }, 1000);
  }
  render() {
    const { flowEarned } = this.props;
    return (
      <div>
        <h1 className="Mt-30 Ta-c">
          <i className="fa fa-check-circle" />
          Congratulations!
          <br />
          You've earned
        </h1>
        <div className="massive-text">
          {flowEarned}
          <span>pts</span>
        </div>
        <Modal.Footer>
          <div className="alert-actions">
            <button
              className="btn btn-primary"
              onClick={this.goToSurveys.bind(this)}
            >
              Done
            </button>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}
export default connect()(Profile);
