export default {
  ERROR_bad_credentials: 'Incorrect username or password.',
  ERROR_suspended_account: 'Sorry, your account has been suspended.',
  ERROR_expired_pw: 'Your password has expired. Create a new one.',
  ERROR_401: 'You have been logged out due to inactivity.',
  ERROR_no_user_found: 'No user found.',
  ERROR_pw_hash_fail: 'An error occured. Try again later.',
  ERROR_bad_pw: 'Password is invalid.',
  ERROR_user_exists: 'User already exists. Try logging in to continue.',
  ERROR_mail_error: 'Error sending email. Try again later.',
  ERROR_reset_token_expired: 'Password reset request expired. Try again.',
  ERROR_reset_pw_same: 'Password must be different from previous password.',
  ERROR_pwds_dont_match: 'New passwords do not match.',
  SUCCESS_pw_request_sent: 'Check your email to reset your password.',
  SUCCESS_pw_reset: 'Your password has been reset.',
};
