import React from 'react';
import './styles.scss';

export default function () {
  return (
    <div className="page-dmca">
      <div className="page-margin">
        <h2>DMCA</h2>

        <p className="Mt-30">
          <b>VolKno Inc.</b>
        </p>

        <p>
          VolKno Inc. (the "Company") respects the intellectual property rights
          of third parties and responds to allegations that copyrighted material
          has been posted, uploaded or shared on or through online services
          operated by the Company (the "Services") without authorization from
          the copyright holder in accordance with the safe harbors set forth in
          the Digital Millennium Copyright Act ("DMCA"). The Company will also,
          in appropriate circumstances and at its discretion, disable and/or
          terminate the accounts of users who may infringe or repeatedly
          infringe the copyrights of others in accordance with the DMCA.{' '}
        </p>

        <p></p>

        <p>
          <b>A. Notification of Alleged Copyright Infringement</b>
        </p>

        <p>
          <b></b>
        </p>

        <p>
          If you believe that your work has been copied and made available
          through the Services in a way that constitutes copyright infringement,
          you may send a written document to the Company's Designated Agent (as
          set forth below) that contains the following (a "Notice"):
        </p>

        <p></p>

        <p>
          1. A description of the copyrighted work that you claim has been
          infringed.{' '}
        </p>

        <p></p>

        <p>
          2. Identification of the URL or other specific location that contains
          the material that you claim infringes your copyright described in Item
          1 above. You must provide us with reasonably sufficient information to
          locate the allegedly infringing material.{' '}
        </p>

        <p></p>

        <p>
          3. An electronic or physical signature of the owner of the copyright
          or of the person authorized to act on behalf of the owner of the
          copyright.
        </p>

        <p></p>

        <p>
          4. A statement by you that you have a good faith belief that the
          disputed use is not authorized by the copyright owner, its agent, or
          applicable law.{' '}
        </p>

        <p></p>

        <p>
          5. A statement by you that the information contained in your Notice is
          accurate and that you attest under the penalty of perjury that you are
          the copyright owner or that you are authorized to act on the owner's
          behalf.{' '}
        </p>

        <p></p>

        <p>
          6. Your name, mailing address, telephone number, and email address.
        </p>

        <p></p>

        <p>
          The Company's Designated Agent for Notice of claims of copyright
          infringement can be reached as follows:
        </p>

        <p></p>

        <p>VolKno, Inc.</p>

        <p>Attn: Brent Romagnolo</p>

        <p>8033 Sunset Blvd. #359 </p>

        <p>West Hollywood CA 90046</p>

        <p>[Address]</p>

        <p>Phone: [_________]</p>

        <p>Fax: [__________]</p>

        <p>E-Mail: [__________]</p>

        <p></p>

        <p>
          Please note that you may be liable for damages, including court costs
          and attorneys fees, if you misrepresent that content uploaded by a
          Services user is infringing your copyright.
        </p>

        <p></p>

        <p>
          Upon receiving a proper Notice, the Company will remove or disable
          access to the allegedly infringing material and notify the alleged
          infringer of your claim. We will also advise the alleged infringer of
          the DMCA Counter Notice Procedure described below in Section B by
          which the alleged infringer may respond to your claim and request that
          we restore this material.{' '}
        </p>

        <p></p>

        <p>
          <b>B. Counter Notice Procedure</b>
        </p>

        <p>
          <b></b>
        </p>

        <p>
          If you believe your own copyrighted material has been removed from the
          Services in error, you may submit a written Counter Notice to our
          Designated Agent (as identified above) that includes the following:{' '}
        </p>

        <p></p>

        <p>
          1. Identification of the material that has been removed or disabled
          and the location at which the material appeared before it was removed
          or disabled.{' '}
        </p>

        <p></p>

        <p>
          2. A statement that you consent to the jurisdiction of the Federal
          District Court in which your address is located, or if your address is
          outside the United States, the Central District of California or any
          other judicial district in which the Company may be found.{' '}
        </p>

        <p></p>

        <p>
          3. A statement that you will accept service of process from the party
          that filed the Notice or the party's agent.{' '}
        </p>

        <p></p>

        <p>4. Your name, address and telephone number. </p>

        <p></p>

        <p>
          5. A statement under penalty of perjury that you have a good faith
          belief that the material in question was removed or disabled as a
          result of mistake or misidentification of the material to be removed
          or disabled.{' '}
        </p>

        <p></p>

        <p>6. Your physical or electronic signature. </p>

        <p></p>

        <p>
          If you send our Designated Agent a valid, written Counter Notice
          meeting the requirements described above, we will restore your removed
          or disabled material within 10 to 14 business days from the date we
          receive your Counter Notification, unless our Designated Agent first
          receives notice from the party filing the original Notice informing us
          that such party has filed a court action to restrain you from engaging
          in infringing activity related to the material in question.
        </p>

        <p></p>

        <p>
          Please note that if you misrepresent that the disabled or removed
          content was removed by mistake or misidentification, you may be liable
          for damages, including costs and attorney's fees.
        </p>

        <p></p>

        <p>
          <b>C. Repeat Infringer Policy</b>
        </p>

        <p></p>

        <p>
          The Company may withdraw all rights and privileges relating to the
          Services from any user who is deemed to be a repeat infringer. This
          determination will be based on the number of "strikes" against the
          user. A "strike" is counted against a user each time there is either:
          (i) an adjudication by a court, arbitrator or other tribunal of
          competent jurisdiction that the user has engaged in copyright
          infringement of any kind in relation to the Services; or (ii) the
          Company has actual knowledge, regardless of any such adjudication,
          that the user has engaged in any such copyright infringement.{' '}
        </p>

        <p></p>

        <p>
          Each adjudication or instance of knowledge counts as a separate
          strike. If an adjudication or instance of knowledge pertains to
          multiple instances of copyright infringement, it can count as multiple
          strikes. The Company has adopted a "three strikes and you're out"
          policy under which a user who accumulates three strikes is considered
          a repeat infringer and may be subject to account termination.{' '}
        </p>
      </div>
    </div>
  );
}
