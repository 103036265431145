var validator = require('validator');
var moment = require('moment');

// TEST IF VALID (NOT IF INVALID)
var validators = {
  UUID: function (value) {
    return validator.isUUID(value);
  },
  SLUG: function (value) {
    return value && /^[a-zA-Z0-9-_]+$/.test(value);
  },
  ALPHA: function (value) {
    // [a-zA-Z]
    return validator.isAlpha(value);
  },
  DATA_URI: function (value) {
    return validator.isDataURI(value);
  },
  PASSWORD: function (value) {
    return (
      value && /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&]{8,}$/.test(value)
    );
  },
  ALPHANUM: function (value) {
    // [a-zA-Z0-9]
    // eslint-disable-next-line
    return value && /^[\w\-\.\s]+$/.test(value);
  },
  URL_PATH: function (value) {
    // [a-zA-Z0-9]
    // eslint-disable-next-line
    return value && /^(\/([\w#!:.?+=&%@!\-\/])+)$/.test(value);
  },
  TEXT: function (value) {
    return String(value).length > 0; // improve or get rid of this
  },
  STATE: function (value) {
    return validator.isAlpha(value) && value.length === 2;
  },
  EMAIL: function (value) {
    const trimmedVal = value.trim();
    return validator.isEmail(trimmedVal);
  },
  OBJECT: function (value) {
    return typeof value === 'object';
  },
  TIMESTAMP: function (value) {
    // Date.now(), could be other types as well(?)
    return value && moment(Number(value)).isValid();
  },
  NUMBER: function (value) {
    return validator.isNumeric(String(value));
  },
  NUM2DIGIT: function (value) {
    var val = String(value);
    return validator.isNumeric(val) && val.length === 2;
  },
  ARRAY: function (value) {
    return Array.isArray(value);
  },
  PHONE: function (value) {
    if (value && value.length) {
      var phoneStart = value.split('ext')[0];
      return phoneStart.replace(/\D/g, '').length >= 10;
    }
    return false;
  },
  ZIP: function (value) {
    if (validator.isNumeric(String(value))) {
      return String(value).length === 5; // us
    } else if (validator.isAlphanumeric(value)) {
      return String(value).length === 6; // canada
    }
    return false;
  },
  BOOLEAN: function (value) {
    return validator.isBoolean(String(value));
  },
  GEO: function (value) {
    // eg., "-118.464001"
    // eslint-disable-next-line
    return /^(\-?\d+(\.\d+)?)$/.test(value);
  },
  GEO_FULL: function (value) {
    // eg., "34.174933, -118.464001"
    // eslint-disable-next-line
    return /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/.test(value);
  },
  CCNUM: function (value) {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) {
      return false;
    }

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0;
    var nDigit = 0;
    var bEven = false;
    value = value.replace(/\D/g, '');

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n);
      nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }
    return nCheck % 10 === 0;
  },
};
function clientSide() {
  return typeof window !== 'undefined';
}
function log(str) {
  if (!clientSide()) {
    console.log(str);
  }
}
function validate(reqData, validatorMapRaw) {
  var validatorMap = Object.assign({}, validatorMapRaw);
  var errors = [];
  var errorValues = [];
  var missingValidationTypes = [];
  var allProps = clientSide()
    ? {
        data: reqData,
      }
    : {
        query: reqData.query,
        params: reqData.params,
        body: reqData.body,
      };

  function iterateMap(props, map) {
    Object.keys(map).forEach(function (keyRaw) {
      // Nx
      var optional = keyRaw.indexOf('__optional') > 0;
      var key = keyRaw.replace(/__optional/, '');
      var validationType = map[keyRaw];
      var hasValue = false;
      if (!Array.isArray(props)) {
        props = [props];
      }
      props.forEach(function (prop) {
        if (prop && prop.hasOwnProperty(key)) {
          hasValue = true;
          var value = prop[key];
          if (typeof validationType === 'object') {
            iterateMap(value, validationType);
          } else {
            if (!validators[validationType]) {
              missingValidationTypes.push(validationType);
            } else {
              if (
                !validators[validationType](value) &&
                !(optional && !String(value || '').length)
              ) {
                errors.push(key);
                errorValues.push(value);
              }
            }
          }
        }
      });
      if (hasValue) {
        delete map[keyRaw];
      }
    });
  }
  Object.keys(allProps).forEach(function (reqPath) {
    // 3x
    iterateMap(allProps[reqPath], validatorMap);
  });
  // merge invalid props with missing props
  errors = errors.concat(
    Object.keys(validatorMap).filter(function (key) {
      return key.indexOf('__optional') === -1;
    })
  );
  if (missingValidationTypes.length > 0 || errors.length > 0) {
    log('Validation issues for: ' + reqData.path || '');
    if (errors.length > 0) {
      log('Invalid values passed:');
      log(
        errors
          .map(function (prop, index) {
            return prop + ' = ' + errorValues[index];
          })
          .join('\n')
      );
    }
    if (missingValidationTypes.length > 0) {
      log('Missing validation types:');
      log(missingValidationTypes.join('\n'));
    }
  }
  if (errors.length > 0) {
    return errors;
  }
  return false;
}
function validatePromise(reqData, validatorMap) {
  return new Promise(function (resolve, reject) {
    var errors = validate(reqData, validatorMap);
    if (!errors) {
      return resolve();
    } else {
      return reject(
        clientSide()
          ? errors
          : {
              status: 406,
              code: 'invalid_values',
              data: errors,
            }
      );
    }
  });
}

function formatErrors(invalidFields) {
  const errors = {};
  invalidFields.forEach(function (field) {
    errors[field] = 'Invalid value';
  });
  return errors;
}

export {
  validatePromise,
  validate,
  formatErrors,
  validators as validatorFunctions,
};
