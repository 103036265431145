import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import messageActions from './redux/actions';

export default function (moreStrings) {
  const strings = {
    SUCCESS_default: 'Success!',
    ERROR_default: 'An error occured.',
    ...moreStrings,
  };
  const mapStateToProps = (state) => ({
    successCode: state.message.success,
    errorCode: state.message.error,
  });
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(messageActions, dispatch),
  });
  class MessageBar extends React.Component {
    static propTypes = {
      actions: PropTypes.object,
      successCode: PropTypes.any,
      errorCode: PropTypes.any,
    };
    render() {
      const { errorCode, successCode, actions } = this.props;
      let errorText;
      let successText;
      if (errorCode) {
        errorText = strings['ERROR_' + errorCode];
        if (!errorText) {
          errorText = strings.ERROR_default;
        }
      }
      if (successCode) {
        successText = strings['SUCCESS_' + successCode];
        if (!successText) {
          successText = strings.SUCCESS_default;
        }
      }
      return (
        <div className="message-bar">
          {errorText ? (
            <div
              style={{
                backgroundColor: 'red',
                color: 'white',
                position: 'relative',
                padding: '4px',
              }}
            >
              {/*eslint-disable-next-line*/}
              {errorText} <a href='#' style={{ display: 'block', position: 'absolute', right: '20px', textDecoration: 'none', top: '5px', color: 'white' }} onClick={actions.clearError}>x</a>
            </div>
          ) : null}
          {successText ? (
            <div
              style={{
                backgroundColor: 'green',
                color: 'white',
                position: 'relative',
                padding: '4px',
              }}
            >
              {/*eslint-disable-next-line*/}
              {successText} <a href='#' style={{ display: 'block', position: 'absolute', right: '20px', textDecoration: 'none', top: '5px', color: 'white' }} onClick={actions.clearSuccess}>x</a>
            </div>
          ) : null}
        </div>
      );
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(MessageBar);
}
