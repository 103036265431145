import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

class SurveyUnlocked extends React.Component {
  static propTypes = {
    close: PropTypes.func,
  };
  static contextTypes = {
    router: PropTypes.object,
  };
  goToSurveys() {
    this.props.close();
    setTimeout(() => {
      this.context.router.push(`/surveys`);
    }, 250);
  }
  render() {
    return (
      <div>
        <h1 className="Mt-30 Ta-c">
          <i className="fa fa-unlock-alt" />
          Congratulations!
          <br />
        </h1>
        <div className="Fz-22">You've unlocked a new survey!</div>
        <Modal.Footer>
          <div className="alert-actions">
            <button
              className="btn btn-primary"
              onClick={this.goToSurveys.bind(this)}
            >
              Go to Surveys
            </button>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}
export default connect()(SurveyUnlocked);
