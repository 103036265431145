import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cx from 'classnames';
import { withFormsy } from 'formsy-react';
import formsyErrorFix from './formsyErrorFix';

const months = [
  {
    value: 1,
    label: 'January',
    days: 31,
  },
  {
    value: 2,
    label: 'February',
    days: 28,
    leapYearDays: 29,
  },
  {
    value: 3,
    label: 'March',
    days: 31,
  },
  {
    value: 4,
    label: 'April',
    days: 30,
  },
  {
    value: 5,
    label: 'May',
    days: 31,
  },
  {
    value: 6,
    label: 'June',
    days: 30,
  },
  {
    value: 7,
    label: 'July',
    days: 31,
  },
  {
    value: 8,
    label: 'August',
    days: 31,
  },
  {
    value: 9,
    label: 'September',
    days: 30,
  },
  {
    value: 10,
    label: 'October',
    days: 31,
  },
  {
    value: 11,
    label: 'November',
    days: 30,
  },
  {
    value: 12,
    label: 'December',
    days: 31,
  },
];

@formsyErrorFix
class DateSelect extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    setValue: PropTypes.func,
    getErrorText: PropTypes.func,
    getValue: PropTypes.func,
    className: PropTypes.string,
    title: PropTypes.any,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    startYear: PropTypes.number,
    endYear: PropTypes.number,
    yearDir: PropTypes.string,
    isRequired: PropTypes.func,
    isFormDisabled: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = this.getStateFromProps(props);
  }
  shouldComponentUpdate(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState(this.getStateFromProps(nextProps));
      return false;
    }
    return true;
  }
  getStateFromProps(props) {
    const value = props.value;
    const d = new Date(Number(value));
    return {
      month: value && d.getMonth() + 1,
      day: value && d.getDate(),
      year: value && d.getFullYear(),
    };
  }
  updateValue() {
    const date = this.state;
    if (date.month && date.day && date.year) {
      const newDateStr =
        date.month.value + '/' + date.day.value + '/' + date.year.value;
      this.props.setValue(new Date(newDateStr).getTime());
    } else {
      this.props.setValue(false); // this makes it not pristine
    }
  }
  onMonthChange(val) {
    this.setState(
      {
        month: val,
      },
      this.updateValue
    );
  }
  onDayChange(val) {
    this.setState(
      {
        day: val,
      },
      this.updateValue
    );
  }
  onYearChange(val) {
    this.setState(
      {
        year: val,
      },
      this.updateValue
    );
  }
  isLeapYear(year) {
    return new Date(year, 1, 29).getMonth() === 1;
  }
  getDays(selectedMonth, selectedYear) {
    let days = 31;
    const dayOptions = [];
    months.some((month) => {
      if (month.value === selectedMonth) {
        const isLeapYear = this.isLeapYear(selectedYear);
        days =
          month.leapYearDays && isLeapYear ? month.leapYearDays : month.days;
        return true;
      }
      return false;
    });
    for (let d = 1; d <= days; d++) {
      dayOptions.push({
        value: d,
        label: String(d),
      });
    }
    return dayOptions;
  }
  getYears() {
    const defaultYear = new Date().getFullYear();
    const {
      startYear = defaultYear - 100,
      endYear = defaultYear,
      yearDir = 'ASC',
    } = this.props;
    const years = [];
    for (let y = startYear; y <= endYear; y++) {
      years.push({
        value: y,
        label: String(y),
      });
    }
    if (yearDir === 'DESC') {
      years.reverse();
    }
    return years;
  }
  render() {
    const {
      name,
      title,
      className,
      getErrorText,
      showError,
      isFormDisabled,
      disabled,
    } = this.props;
    const errorText = getErrorText();
    const id = name + '-ds';
    const date = this.state;
    const days = this.getDays(date.month, date.year);
    const years = this.getYears();
    return (
      <div className={cx('form-group', className)} data-test={name}>
        <label htmlFor={id}>{title}</label>
        <div className="row">
          <div
            className={cx('col-4', {
              'has-error': errorText && showError() && !date.month,
            })}
          >
            <Select
              name={'month-' + id}
              value={date.month}
              options={months}
              placeholder={'Month'}
              onChange={this.onMonthChange.bind(this)}
              clearable={false}
              disabled={isFormDisabled() || disabled}
            />
          </div>
          <div
            className={cx('col-4', {
              'has-error': errorText && showError() && !date.day,
            })}
          >
            <Select
              name={'day-' + id}
              value={date.day}
              options={days}
              placeholder={'Day'}
              onChange={this.onDayChange.bind(this)}
              clearable={false}
              disabled={isFormDisabled() || disabled}
            />
          </div>
          <div
            className={cx('col-4', {
              'has-error': errorText && showError() && !date.year,
            })}
          >
            <Select
              name={'year-' + id}
              value={date.year}
              options={years}
              placeholder={'Year'}
              onChange={this.onYearChange.bind(this)}
              clearable={false}
              disabled={isFormDisabled() || disabled}
            />
          </div>
          {showError() ? (
            <div className="col-12 validation-error">{errorText}</div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withFormsy(DateSelect);
