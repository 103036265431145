import { messageActions } from 'modules/react-message';
import apiFetch from 'utils/fetch';
import Immutable from 'seamless-immutable';
import * as appActions from './app';
const cfg = process.env;

// TODO: move gift card call from bootstrap call to here and only call from store

// action types
export const actionTypes = {
  IS_BUSY: 'volkno/cart/IS_BUSY',
  SET_PRODUCT_QTY: 'volkno/cart/SET_PRODUCT_QTY',
  CLEAR_CART: 'volkno/cart/CLEAR_CART',
};

// reducers
const initialState = Immutable({
  items: {},
  products: {},
  busy: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.IS_BUSY:
      return state.merge(
        {
          busy: action.payload,
        },
        { deep: true }
      );

    case actionTypes.SET_PRODUCT_QTY:
      return state.setIn(
        ['items', action.payload.productId],
        action.payload.quantity
      );

    case actionTypes.CLEAR_CART:
      return state.set('items', {});

    case appActions.actionTypes.UPDATE_INFO:
      let { list, data } = action.payload.gift_cards;
      return state.merge(
        {
          products: {
            list,
            data,
          },
        },
        { deep: true }
      );

    default:
      return state;
  }
}

// action creators
export function setQuantity(productId, quantity) {
  return {
    type: actionTypes.SET_PRODUCT_QTY,
    payload: {
      productId,
      quantity,
    },
  };
}
export function clear() {
  return {
    type: actionTypes.CLEAR_CART,
  };
}
export function submitOrder(data, onSuccess = () => {}, onFailure = () => {}) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    dispatch({ type: actionTypes.IS_BUSY, payload: true });
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/order`,
      body: data,
    };
    apiFetch('post', options, dispatch)
      .then((resp) => {
        if (resp.status === 200) {
          onSuccess();
        } else {
          onFailure();
          dispatch(messageActions.displayError(resp.json.error));
        }
        dispatch({ type: actionTypes.IS_BUSY, payload: false });
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
