import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as earningsActions from 'ducks/earnings';
import Settings from './Settings';
import Preferences from './Preferences';
import Activity from './Activity';
import AddressVerification from './AddressVerification';
import './styles.scss';
const options = [
  {
    key: 'SETTINGS',
    value: 'Settings',
  },
  {
    key: 'PREFERENCES',
    value: 'Preferences',
  },
  {
    key: 'ACTIVITY',
    value: 'Activity',
  },
  {
    key: 'ADDRESS_VERIFICATION',
    value: 'Address Verification',
  },
];

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: 'SETTINGS',
    };
  }
  static contextTypes = {
    url: PropTypes.object,
  };

  handleClick(selectedKey) {
    this.setState({
      tabSelected: selectedKey,
    });
  }
  componentWillMount() {
    this.props.dispatch(earningsActions.getAll());
    const {
      query: { p },
    } = this.context.url;
    if (p === 'address') {
      this.handleClick('ADDRESS_VERIFICATION');
    }
    if (p === 'preferences') {
      this.handleClick('PREFERENCES');
    }
  }
  render() {
    const { tabSelected } = this.state;
    return (
      <div className="page-profile page-margin">
        <h2>My Profile</h2>
        <Nav
          variant="pills"
          activeKey={tabSelected}
          onSelect={this.handleClick.bind(this)}
        >
          {options.map((option) => (
            <Nav.Item key={'navkey_' + option.key}>
              <Nav.Link eventKey={option.key}>{option.value}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        {tabSelected === 'SETTINGS' ? <Settings /> : null}
        {tabSelected === 'PREFERENCES' ? <Preferences /> : null}
        {tabSelected === 'ACTIVITY' ? <Activity /> : null}
        {tabSelected === 'ADDRESS_VERIFICATION' ? (
          <AddressVerification />
        ) : null}
      </div>
    );
  }
}
export default connect()(Profile);
