import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input } from 'modules/react-forms';
import * as loginActions from '../../ducks/login';
import { Link } from 'react-router';
import { formatErrors } from 'modules/utils-validation';

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginState.login.isLoggedIn,
  isLoggingIn: state.loginState.login.isLoggingIn,
});
export class LoginView extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    isLoggingIn: PropTypes.bool,
    path: PropTypes.string,
    app: PropTypes.string,
  };
  static contextTypes = {
    url: PropTypes.object,
    router: PropTypes.object,
  };
  componentWillMount() {
    const { confirm = '' } = this.context.url.query;
    const { dispatch } = this.props;
    if (confirm.length) {
      dispatch(loginActions.confirmEmail(confirm));
    }
  }
  handleLoginCall(data, resetForm, handleErrors) {
    const { dispatch, app } = this.props;
    function onFailure(resp) {
      if (resp.status === 406) {
        handleErrors(formatErrors(resp.json.errorData));
      }
    }
    data.app = app;
    dispatch(loginActions.loginCall(data, onFailure));
  }
  render() {
    const { isLoggedIn, isLoggingIn, path } = this.props;
    if (isLoggingIn) {
      return <div className="row">Logging in...</div>;
    } else if (isLoggedIn) {
      const nextUrl = window.localStorage.getItem('nextUrl');
      window.localStorage.removeItem('nextUrl');
      this.context.router.push(nextUrl || '/');
      return (
        <div className="row Ta-c Py-30">
          <div>You are logged in!</div>
          Redirecting to: <Link to="/">/</Link>
        </div>
      );
    } else {
      return (
        <div>
          <Form onValidSubmit={this.handleLoginCall.bind(this)}>
            <div className="row">
              <div className="col-sm-12">
                {/* BEGIN HACK - To disable autocomplete's ugly color treatment */}
                <input style={{ display: 'none' }} type="text" name="email" />
                <input
                  style={{ display: 'none' }}
                  type="password"
                  name="password"
                />
                {/* END HACK */}
                <Input
                  type="input"
                  title="Email Address"
                  name="email"
                  iconClass="fa fa-envelope-o"
                  value={undefined}
                  validations="EMAIL"
                  validationError="Enter valid email"
                  required
                  data-cy="login-email"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Input
                  type="password"
                  title="Password"
                  name="password"
                  iconClass="fa fa-lock"
                  value={undefined}
                  validations="PASSWORD"
                  validationError="Enter valid password"
                  required
                  data-cy="login-password"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <button
                  type="submit"
                  className="btn btn-primary My-10"
                  data-cy="login-btn"
                >
                  Sign in
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 Ta-c Mb-10 Mt-20 C-777 Fz-11">
                <div className="Fw-b">Previously logged in with Facebook?</div>{' '}
                We no longer support Facebook login but you can still access
                your account. Just{' '}
                <Link
                  to={path + '?action=forgot'}
                  style={{ textDecoration: 'underline' }}
                >
                  set a password
                </Link>{' '}
                using the email associated with your Facebook account, then
                login above.
              </div>
            </div>
          </Form>
          {/*<div className="row">*/}
          {/*  <div className="col-sm-12">*/}
          {/*    <FacebookLogin text="Login" />*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="row">
            <div className="col-sm-12 bottom-links">
              <Link to={path + '?action=forgot'}>Forgot password?</Link>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default connect(mapStateToProps)(LoginView);
