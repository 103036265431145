/* eslint-disable no-case-declarations,@typescript-eslint/no-empty-function */
import { messageActions } from 'modules/react-message';
import cookie from 'react-cookie';
import Immutable from 'seamless-immutable';
import apiFetch from 'utils/fetch';
import * as surveysActions from './surveys';
import * as earningsActions from './earnings';
const maxKBSizeProfileImg = 500000; // 0.5 mb
const acceptedProfileImgMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];

// eslint-disable-next-line no-undef
const cfg = process.env;

function getAudienceIdFromToken(hash) {
  if (!hash) {
    return null;
  }
  const audienceRaw = window.atob(hash);
  return parseInt(audienceRaw.split('|')[0].split('^')[2], 10) || 1;
}

// action types
export const actionTypes = {
  SET_AUDIENCE: 'volkno/app/SET_AUDIENCE',
  CLEAR_AUDIENCE: 'volkno/app/CLEAR_AUDIENCE',
  AUDIENCE_NOTIFIED: 'volkno/app/AUDIENCE_NOTIFIED',
  UPDATE_INFO: 'volkno/app/UPDATE_INFO',
  UPDATE_PROFILE_IMG: 'volkno/app/UPDATE_PROFILE_IMG',
  IS_BUSY: 'volkno/app/IS_BUSY',
  ACCEPTED_TERMS: 'volkno/app/ACCEPTED_TERMS',
};

// reducers
const initialState = Immutable({
  audience: cookie.load('audience') || '',
  audienceId: getAudienceIdFromToken(cookie.load('audience') || ''),
  genres: [],
  levels: {},
  user: {
    profile_img: '',
    terms: 1,
  },
  userAddress: {},
  busy: true,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.IS_BUSY:
      return state.merge(
        {
          busy: action.payload,
        },
        { deep: true }
      );

    case actionTypes.ACCEPTED_TERMS:
      return state.setIn(['user', 'terms'], 1);

    case actionTypes.SET_AUDIENCE:
      cookie.save('audience', action.payload, { path: '/' });
      return state
        .set('audienceId', getAudienceIdFromToken(action.payload))
        .set('audience', action.payload);

    case actionTypes.CLEAR_AUDIENCE:
      cookie.remove('audience', { path: '/' });
      return state.set('audience', '').set('audienceId', '');

    case actionTypes.UPDATE_PROFILE_IMG:
      return state.setIn(['user', 'profile_img'], action.payload);

    case actionTypes.UPDATE_INFO:
      let firstId = action.payload.audiences.list[0];
      let defaultAudience =
        firstId && action.payload.audiences.data[firstId].hash;
      let newAudience = state.audience;
      if (!state.audience && defaultAudience) {
        cookie.save('audience', defaultAudience, { path: '/' });
        newAudience = defaultAudience;
      }
      return state
        .merge(action.payload, { deep: true })
        .set('audienceId', getAudienceIdFromToken(newAudience))
        .set('audience', newAudience);
    default:
      return state;
  }
}

// action creators
export function setAudience(audience) {
  return {
    type: actionTypes.SET_AUDIENCE,
    payload: audience,
  };
}
export function clearAudience() {
  return {
    type: actionTypes.CLEAR_AUDIENCE,
  };
}
export function termsAccepted() {
  return {
    type: actionTypes.ACCEPTED_TERMS,
  };
}
export function updateProfileImg(profileImg) {
  return {
    type: actionTypes.UPDATE_PROFILE_IMG,
    payload: profileImg,
  };
}
export function get(options, onSuccess = () => {}) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    if (options && !options.silent) {
      dispatch({ type: actionTypes.IS_BUSY, payload: true });
    }
    options = {
      url: `${cfg.REACT_APP_API_BASE}/bootstrap`,
    };
    apiFetch('get', options, dispatch, options && options.noCache)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: actionTypes.UPDATE_INFO,
            payload: resp.json,
          });
          onSuccess();
        } else {
          dispatch(messageActions.displayError(resp.json.error));
        }
        dispatch({ type: actionTypes.IS_BUSY, payload: false });
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}

export function uploadProfileImg(file, data) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());

    if (acceptedProfileImgMimeTypes.indexOf(file.type) === -1) {
      dispatch(messageActions.displayError('image_filetype_error'));
      return;
    }
    if (file.size > maxKBSizeProfileImg) {
      dispatch(messageActions.displayError('image_filesize_error'));
      return;
    }

    const options = {
      url: `${cfg.REACT_APP_API_BASE}/account/imageUpload`,
      body: {
        data_uri: data,
        fileType: file.type,
      },
    };
    apiFetch('post', options, dispatch)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(updateProfileImg(resp.json.profile_img));
        } else {
          dispatch(messageActions.displayError('image_upload_error'));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}

export function audienceNotified(onSuccess = () => {}) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/account/audienceNotified`,
    };
    apiFetch('put', options, dispatch)
      .then((resp) => {
        if (resp.status === 200) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}

export function saveUserInfo(data, onSuccess = () => {}, onFailure = () => {}) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/account/update`,
      body: data,
    };
    apiFetch('put', options, dispatch)
      .then((resp) => {
        if (resp.status !== 200) {
          onFailure(resp);
          dispatch(messageActions.displayError(resp.json.error));
        } else {
          if (resp.json.newSurvey) {
            dispatch(surveysActions.getSurveys());
            setTimeout(() => {
              dispatch(earningsActions.manualAlert('SURVEY_UNLOCKED'));
            }, 2000);
          }
          onSuccess();
          dispatch(messageActions.displaySuccess('user_updated'));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}

export function updateAddress(
  data,
  onSuccess = () => {},
  onFailure = () => {}
) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/account/updateAddress`,
      body: data,
    };
    apiFetch('put', options, dispatch)
      .then((resp) => {
        if (resp.status !== 200) {
          onFailure(resp);
          dispatch(messageActions.displayError(resp.json.error));
        } else {
          onSuccess();
          dispatch(messageActions.displaySuccess('user_updated'));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}

export function searchAddress(
  data,
  onSuccess = () => {},
  onFailure = () => {}
) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/account/searchAddress`,
      body: data,
    };
    apiFetch('post', options, dispatch)
      .then((resp) => {
        if (resp.status !== 200) {
          onFailure(resp);
          dispatch(messageActions.displayError(resp.json.error));
        } else {
          onSuccess(resp.json);
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
export function verifyAddress(
  data,
  onSuccess = () => {},
  onFailure = () => {}
) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/account/verifyAddress`,
      body: data,
    };
    apiFetch('post', options, dispatch)
      .then((resp) => {
        if (resp.status !== 200) {
          onFailure(resp);
          dispatch(messageActions.displayError(resp.json.error));
        } else {
          onSuccess(resp.json);
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
export function acceptTerms(onSuccess = () => {}) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/account/acceptTerms`,
    };
    apiFetch('put', options, dispatch)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(termsAccepted());
          onSuccess();
        } else {
          dispatch(messageActions.displayError(resp.json.error));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
