import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap';
import * as mediaActions from 'ducks/media';
import * as earningsActions from 'ducks/earnings';
import * as appActions from 'ducks/app';
import MediaListItem from 'components/common/MediaListItem';
import Loader from 'components/common/Loader';
import apiFetch from 'utils/fetch';
import './styles.scss';

// eslint-disable-next-line no-undef
const cfg = process.env;
const options = [
  {
    key: 'ALL',
    value: 'All',
  },
  {
    key: 'NEW',
    value: 'New',
  },
  {
    key: 'SURGING',
    value: 'Surging',
  },
  {
    key: 'COMING_SOON',
    value: 'Coming Soon',
  },
];

class MediaExplorer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    media: PropTypes.object,
    earnings: PropTypes.object,
    showFavorites: PropTypes.bool,
  };
  static contextTypes = {
    url: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: 'ALL',
      lastForcedUpdate: null,
    };
    this.calcMaxFlow = this.calcMaxFlow.bind(this);
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { r } = nextContext.url.query;
    if (r && r !== this.state.lastForcedUpdate) {
      this.setState({ lastForcedUpdate: r });
      this.fetchMedia(true);
    }
  }
  componentWillMount() {
    this.fetchMedia(true);
  }
  shouldComponentUpdate(nextProps) {
    if (nextProps.app.audience !== this.props.app.audience) {
      this.fetchMedia(true);
    }
    return true;
  }
  handleSelect(event) {
    this.handleClick(event.target.value);
  }
  handleClick(selectedKey) {
    this.setState(
      {
        tabSelected: selectedKey,
      },
      this.fetchMedia
    );
  }
  fetchMedia(force) {
    const {
      dispatch,
      media: { lists },
    } = this.props;
    const { noCache = false } = this.context.url.query;
    const { tabSelected } = this.state;
    if (!lists[tabSelected] || force) {
      dispatch(
        mediaActions.get(tabSelected, noCache, false, () => {
          if (this.allCompleted()) {
            this.checkStatus();
          }
        })
      );
    } else {
      if (this.allCompleted()) {
        this.checkStatus();
      }
    }
  }
  calcMaxFlow(numOfItems) {
    const {
      app: { levels, payableEventAmounts },
    } = this.props;
    const summaryTotal = Object.keys(payableEventAmounts).reduce(
      (prev, curr) => {
        if (curr.indexOf('SUMMARY') === 0) {
          prev += payableEventAmounts[curr];
        }
        return prev;
      },
      0
    );

    const defaultFlow = levels.data['HOT_OR_NOT'].flow; // TODO: rename to 'DEFAULT' in levels table and in here
    return numOfItems * (defaultFlow + summaryTotal);
  }
  allCompleted() {
    const {
      media: { lists, data },
      earnings,
      app: { audienceId, numOfPayableEventsPerMediaItem },
    } = this.props;
    if (!lists['ALL'] || !lists['ALL'].length) {
      return false;
    }
    return lists['ALL'].every((mediaId) => {
      const media = data[mediaId];
      return media.items.list.every((itemId) => {
        const groupCode = audienceId + '^' + itemId;
        return (
          earnings.group[groupCode] &&
          earnings.group[groupCode].length >= numOfPayableEventsPerMediaItem
        );
      });
    });
  }
  checkStatus() {
    const { dispatch } = this.props;
    apiFetch(
      'get',
      {
        url: `${cfg.REACT_APP_API_BASE}/status`,
      },
      dispatch
    ).then((resp) => {
      if (resp.status === 200 && resp.json.newCampaign) {
        dispatch(earningsActions.manualAlert('CAMPAIGN_UNLOCKED'));
        dispatch(appActions.get());
      }
    });
  }
  getUnfinishedItemId(media) {
    const {
      app: { audienceId },
      earnings,
    } = this.props;
    let result = media.items.list[0];
    media.items.list.some((itemId) => {
      const groupCode = audienceId + '^' + itemId;
      const completedChallenges = (earnings.group[groupCode] || []).map(
        (c) => c.split('^')[1]
      );
      if (completedChallenges.indexOf('HOT_OR_NOT') === -1) {
        // TODO: rename to 'DEFAULT' in levels table and in here
        result = itemId;
        return true;
      }
      return false;
    });
    return result;
  }
  render() {
    const { tabSelected } = this.state;
    const {
      dispatch,
      media: { lists, data, busy },
      app,
      showFavorites = false,
      earnings,
    } = this.props;
    const { mediaTypes = { list: [], data: {} }, audienceId } = app;
    const allOptions = options.concat(
      mediaTypes.list.map((media_type_id) => ({
        key: media_type_id,
        value: mediaTypes.data[media_type_id].name,
      }))
    );
    const list = lists[tabSelected] || [];
    const listFiltered = list.reduce((prev, media_id) => {
      const m = data[media_id];
      if (showFavorites && !m.favorite) {
        return prev;
      }
      const unfinishedItemId = this.getUnfinishedItemId(m);
      // TODO: fix this nonsense
      prev.push(
        <MediaListItem
          key={'media_' + media_id}
          image={m.poster_url}
          title={m.title}
          maxFlow={this.calcMaxFlow(m.items.list.length)}
          media_id={m.media_id}
          audience_media_id={m.audience_media_id}
          item_id={unfinishedItemId}
          favorite={m.favorite}
          items={m.items}
          audienceId={audienceId}
          dispatch={dispatch}
          earnings={earnings}
          user_id={m.secure_urls ? this.props.app.user.user_id : null}
        />
      );
      return prev;
    }, []);

    const results =
      listFiltered.length > 0 ? (
        <ul className="item-list tablet-center">{listFiltered}</ul>
      ) : (
        <div className="Ta-c P-30">
          {showFavorites ? (
            <span>Nothing saved for this category yet.</span>
          ) : (
            <span>No items for this category. Check back later!</span>
          )}
        </div>
      );

    const loading = (
      <div className="Ta-c P-30">
        <Loader />
      </div>
    );
    return (
      <div className="media-explorer">
        <select value={tabSelected} onChange={this.handleSelect.bind(this)}>
          {allOptions.map((option) => (
            <option key={'select_' + option.key} value={option.key}>
              {option.value}
            </option>
          ))}
        </select>
        <Nav
          variant="pills"
          activeKey={tabSelected}
          onSelect={this.handleClick.bind(this)}
        >
          {allOptions.map((option) => (
            <Nav.Item key={'navkey_' + option.key}>
              <Nav.Link eventKey={option.key}>{option.value}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        {busy ? loading : results}
      </div>
    );
  }
}
export default connect((state) => ({
  app: state.app,
  media: state.media,
  earnings: state.earnings,
}))(MediaExplorer);
