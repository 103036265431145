import React from 'react';
import './styles.scss';

export default function (props) {
  let cls = 'not-icon ';
  cls += props.className ? props.className + ' ' : '';
  if (props.onClick) {
    cls += 'Cur-p';
  }
  return (
    <div className={cls} onClick={props.onClick || function () {}}>
      <div className="Fz-50 Mt-10 Mt-10 Mb-5 Fw-n">&times;</div>
      Not
    </div>
  );
}
