import React from 'react';

export default function () {
  return (
    <div className="ball-beat">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
