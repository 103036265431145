import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as earningsActions from 'ducks/earnings';

import './styles.scss';

class Comment extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    sendExtraFeedback: PropTypes.func,
    closeModal: PropTypes.func,
  };

  state = {
    comment: '',
    sent: false,
  };

  handleChange = (event) => {
    this.setState({
      comment: event.target.value,
      sent: false,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { dispatch, sendExtraFeedback, closeModal } = this.props;
    const { comment } = this.state;

    // Send feedback only if comment is provided
    if (comment) {
      sendExtraFeedback('comment', { comment }, () => {
        dispatch(earningsActions.getAll());
      });
    }

    this.setState({
      comment: '',
      sent: true,
    });

    closeModal();
  };

  render() {
    const { comment, sent } = this.state;

    return (
      <div className="comment">
        <h1>Comment</h1>

        <form onSubmit={this.handleSubmit} className="row">
          <div className="col-12">
            <textarea
              maxLength="1000"
              placeholder="Write a comment..."
              value={comment}
              onChange={this.handleChange}
            />
          </div>

          <div className="col-6 Pt-10">
            {sent && <span>Comment sent!</span>}
          </div>

          <div className="col-6 Ta-r">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!comment.length}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect()(Comment);
