import React from 'react';
import Slider from 'rc-slider';
import cx from 'classnames';
import './styles.scss';
const marks = {
  0: '0',
  2.5: '2.5',
  5: '5',
  7.5: '7.5',
  10: '10',
};

export default function (props) {
  return (
    <div className={cx('overall-rating', { disabled: props.disabled })}>
      <Slider
        min={0}
        max={10}
        step={0.1}
        marks={marks}
        defaultValue={parseFloat(props.value)}
        disabled={props.disabled}
        onAfterChange={props.onRate}
      />
      <div className="legend not">
        <div className="sprite sprite-x-red"></div>Not
      </div>
      <div className="legend hot">
        <div className="sprite sprite-flame-red"></div>Hot
      </div>
    </div>
  );
}
