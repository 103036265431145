import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Input } from 'modules/react-forms';
import { formatErrors } from 'modules/utils-validation';
import * as forgotActions from '../../ducks/forgot';
import { Link } from 'react-router';

const mapStateToProps = (state) => ({
  requestSent: state.loginState.forgot.requestSent,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(forgotActions, dispatch),
});
export class ForgotView extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    requestSent: PropTypes.bool,
    path: PropTypes.string,
    app: PropTypes.string,
  };
  handleResetRequest(data, resetForm, handleErrors) {
    const { path, actions, app } = this.props;
    const host = window.location.protocol + '//' + window.location.hostname;
    const returnURL = host + path + '?action=reset&h=%TOKEN%';
    function onFailure(resp) {
      if (resp.status === 406) {
        handleErrors(formatErrors(resp.json.errorData));
      }
    }
    data.app = app;
    actions.requestReset(data, returnURL, onFailure);
  }
  render() {
    const { requestSent, path } = this.props;
    if (requestSent) {
      return (
        <div>
          <div className="row">
            <h1>Reset Password.</h1>
          </div>
          <div className="row">
            <div className="col-sm-12 reset-request-sent">
              <div>Your request has been submitted.</div>
              <div>
                Please check your email for further instructions to reset your
                password.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <Link to={path} className="btn btn-primary">
                Return to log in.
              </Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <Form onValidSubmit={this.handleResetRequest.bind(this)}>
        <div className="row">
          <h1>Forgot Password?</h1>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Input
              type="input"
              title="Enter Your Email"
              name="email"
              iconClass="fa fa-envelope-o"
              value={undefined}
              validations="EMAIL"
              validationError="Enter valid email"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <button type="submit" className="btn btn-primary My-10">
              Request Reset
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 bottom-links">
            <Link to={path + '?action=login'}>Return to log in.</Link>
          </div>
        </div>
      </Form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotView);
