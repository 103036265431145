import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import FormsyForm, { addValidationRule } from 'formsy-react';
import { validatorFunctions } from 'modules/utils-validation';

function isExisty(value) {
  return value !== null && value !== undefined;
}

Object.keys(validatorFunctions).forEach(function (key) {
  const validationHandler = validatorFunctions[key];
  addValidationRule(key, function (values, value) {
    return !isExisty(value) || validationHandler(value);
  });
});

addValidationRule('isMoreThan', function (values, value, otherField) {
  return (
    !Number(values[otherField]) || Number(value) > Number(values[otherField])
  );
});

addValidationRule('isMoreThan1899', function (values, value) {
  return value > -2208988801000; // 12/31/1899 23:59:59 in ms
});

export default class Form extends React.Component {
  static propTypes = {
    onValidSubmit: PropTypes.func,
    confirmDialog: PropTypes.any, // either Boolean or config Object
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  closeModal() {
    this.setState({
      showModal: false,
    });
  }
  onValidSubmit() {
    if (this.props.confirmDialog) {
      this.setState({
        showModal: true,
        arguments: arguments,
      });
    } else {
      this.props.onValidSubmit.apply(null, arguments);
    }
  }
  executeAction() {
    const self = this;
    this.setState(
      {
        showModal: false,
      },
      function () {
        // bug with page transistioning before close modal code is complete
        // causing show-modal class to remain in body which breaks scrolling
        setTimeout(function () {
          self.props.onValidSubmit.apply(null, self.state.arguments);
        }, 300);
      }
    );
  }
  render() {
    let { confirmDialog } = this.props;
    if (!confirmDialog) {
      confirmDialog = {};
    }
    const ModalHeader = Modal.Header;
    const ModalTitle = Modal.Title;
    const ModalBody = Modal.Body;
    const ModalFooter = Modal.Footer;
    return (
      <div className={this.props.className}>
        <FormsyForm
          {...this.props}
          onValidSubmit={this.onValidSubmit.bind(this)}
        />
        <Modal show={this.state.showModal} onHide={this.closeModal.bind(this)}>
          <ModalHeader closeButton>
            <ModalTitle>{confirmDialog.title || 'Confirm Submit'}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {confirmDialog.desc || 'Are you sure you want do this?'}
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.closeModal.bind(this)}>
              {confirmDialog.cancelButton || 'Cancel'}
            </Button>
            <Button variant="primary" onClick={this.executeAction.bind(this)}>
              {confirmDialog.submitButton || 'Submit'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
