import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import Counter from 'components/common/Counter';
import './styles.scss';

class Sidebar extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    earnings: PropTypes.object,
    path: PropTypes.string,
  };
  static contextTypes = {
    router: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.previousFlow = 0;
  }
  render() {
    const {
      app: { user },
      earnings,
      surveys,
    } = this.props;
    const isIndexActive = this.context.router.isActive({ to: '/' }, true);
    const previousFlow = this.previousFlow;
    const earningsTotal = earnings.total;
    this.previousFlow = earnings.total;
    const newSurveyCnt = surveys.list.reduce((prev, surveyId) => {
      prev += surveys.data[surveyId].completed ? 0 : 1;
      return prev;
    }, 0);
    return (
      <div className="sidebar">
        <div className="volkno">
          <div className="sprite sprite-volkno-logo-white"></div>
          <div className="user-pic">
            {user.profile_img ? (
              <img alt="" src={user.profile_img} className="D-b W-100" />
            ) : (
              <i className="fa fa-user"></i>
            )}
          </div>
        </div>
        <div className="hud">
          {user.fname ? (
            <div className="name">
              {user.fname} {user.lname}
            </div>
          ) : null}
          <div className="flow-value">
            <Counter
              begin={previousFlow}
              end={earningsTotal}
              time={500}
              easing="outQuart"
            />
          </div>
          <div className="flow-text">Flow</div>
        </div>
        <ul className="volkno-nav">
          <li>
            <Link to="/" activeClassName={isIndexActive ? 'selected' : null}>
              Explore
            </Link>
          </li>
          <li>
            <Link to="/collection" activeClassName="selected">
              My Collection
            </Link>
          </li>
          <li>
            <Link to="/surveys" activeClassName="selected" className="Pos-r">
              My Surveys
              {newSurveyCnt ? (
                <span className="notify">{newSurveyCnt}</span>
              ) : null}
            </Link>
          </li>
          <li>
            <Link to="/store" activeClassName="selected">
              Store
            </Link>
          </li>
          <li>
            <Link to="/profile" activeClassName="selected">
              My Profile
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}
export default connect((state) => ({
  app: state.app,
  earnings: state.earnings,
  surveys: state.surveys,
}))(Sidebar);
