import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Line } from 'rc-progress';
import cx from 'classnames';
import * as mediaActions from 'ducks/media';
import './styles.scss';
import { PosterImage } from '../PosterImage';

export default class MediaListItem extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
  constructor(props) {
    super(props);
    this.state = {
      shelfOpen: false,
    };
    this.addFav = this.addFav.bind(this);
    this.delFav = this.delFav.bind(this);
    this.getItemUrl = this.getItemUrl.bind(this);
  }
  openShelf() {
    this.setState({
      shelfOpen: true,
    });
  }
  closeShelf() {
    this.setState({
      shelfOpen: false,
    });
  }
  addFav() {
    const { dispatch, media_id, audience_media_id } = this.props;
    dispatch(mediaActions.addFavorite(media_id, audience_media_id));
  }
  delFav() {
    const { dispatch, media_id, audience_media_id } = this.props;
    dispatch(mediaActions.delFavorite(media_id, audience_media_id));
  }
  getItemUrl(itemId) {
    const { media_id } = this.props;
    return `/media/${media_id}/${itemId}`;
  }
  render() {
    const { shelfOpen } = this.state;
    const {
      image,
      title,
      maxFlow,
      media_id,
      item_id,
      favorite,
      items,
      earnings,
      audienceId,
      user_id,
    } = this.props;
    const itemFlow = {};
    const flowEarned = items.list.reduce((prev, itemId) => {
      const groupCode = audienceId + '^' + itemId;
      if (!earnings.group[groupCode]) {
        return prev;
      }
      const itemTotal = earnings.group[groupCode].reduce((prev2, curr2) => {
        return prev2 + earnings.data[curr2].credit;
      }, 0);
      itemFlow[itemId] = itemTotal;
      return prev + itemTotal;
    }, 0);

    const percent = (100 * flowEarned) / maxFlow;
    const itemsLen = items.list.length;
    return (
      <li className="media-item" onMouseLeave={this.closeShelf.bind(this)}>
        <div className="media-actions">
          <div className="media-actions-overlay">
            {shelfOpen ? (
              <div className="item-shelf">
                {items.list.map((itemId, index) => (
                  <Link
                    key={`shelf-item-${media_id}-${itemId}`}
                    to={this.getItemUrl(itemId)}
                  >
                    <i className="fa fa-play-circle-o"></i>
                    <div className="Fw-b">{items.data[itemId].subtitle}</div>
                    <div className="Fz-10">
                      {itemFlow[itemId] || 0} flow earned
                    </div>
                  </Link>
                ))}
                <div
                  className="close-shelf"
                  onClick={this.closeShelf.bind(this)}
                >
                  &times;
                </div>
              </div>
            ) : (
              <div className="play-overlay">
                <button
                  onClick={favorite ? this.delFav : this.addFav}
                  className={cx('save-btn', { checked: favorite })}
                >
                  <div className="save-icon">
                    <span>+</span>
                    <div className="check-sml"></div>
                  </div>
                  {favorite ? 'Saved' : 'Save'}
                </button>
                {itemsLen > 1 ? (
                  <div
                    className="click-area"
                    onClick={this.openShelf.bind(this)}
                  >
                    <i className="fa fa-play-circle-o"></i>
                    <span>Start</span>
                    <i className="fa fa-list D-b Mx-a Mt-20"></i>
                  </div>
                ) : (
                  <Link className="click-area" to={this.getItemUrl(item_id)}>
                    <i className="fa fa-play-circle-o"></i>
                    <span>Start</span>
                  </Link>
                )}
              </div>
            )}
          </div>
          <PosterImage
            alt=""
            src={image}
            overlayText={user_id && btoa(user_id)}
          />
        </div>
        <div className="media-title">{title}</div>
        <div className="media-info">
          <div className="flow-earned">
            Flow Earned <strong>{flowEarned}</strong>
          </div>
          <Line
            percent={percent > 100 ? 100 : percent}
            strokeWidth="4"
            trailWidth="4"
            strokeColor="#cb2128"
            trailColor="#f5ac99"
          />
        </div>
      </li>
    );
  }
}
