import apiFetch from '../utils/fetch';
import { messageActions } from 'modules/react-message';

const cfg = process.env;

// constants
export const actionTypes = {
  FAIL: 'account/reset/FAIL',
  SUCCESS: 'account/reset/SUCCESS',
};

// reducers
const initialState = {
  resetSent: false,
  hash: '',
  error: null,
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case actionTypes.SUCCESS:
      return {
        ...state,
        resetSent: true,
      };

    default:
      return state;
  }
}

// action creators
export function resetError(error) {
  return {
    type: actionTypes.FAIL,
    payload: error,
  };
}
export function resetSuccess() {
  return {
    type: actionTypes.SUCCESS,
  };
}
export function sendReset(userData, onFailure) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    apiFetch(dispatch, cfg.REACT_APP_API_BASE + '/account/reset', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        app: userData.app,
        resetToken: userData.resetToken,
        password: userData.password,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(messageActions.displaySuccess('pw_reset'));
          dispatch(resetSuccess());
        } else {
          dispatch(messageActions.displayError(response.json.error));
          dispatch(resetError(response.json.error || 'unknown'));
          onFailure(response);
        }
      })
      .catch((error) => {
        dispatch(resetError(error));
      });
  };
}
