import React from 'react';
import './styles.scss';
import { Link } from 'react-router';

export default function () {
  return (
    <footer className="container">
      <div className="row">
        <div className="col-md-6 mobile-center links">
          <span>
            <Link to="/about">About</Link>
            <Link to="/terms">Terms</Link>
            <Link to="/privacy">Privacy</Link>
            <Link to="/contact">Contact</Link>
          </span>
        </div>
        <div className="col-md-6 mobile-center Ta-r P-30">
          <div className="sprite sprite-volkno-logo-white"></div>
          <span className="copyright">
            VolKno Inc, Copyright {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </footer>
  );
}
