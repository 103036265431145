import { messageActions } from 'modules/react-message';
import Immutable from 'seamless-immutable';
import apiFetch from 'utils/fetch';

const cfg = process.env;

// action types
export const actionTypes = {
  UPDATE: 'volkno/open/UPDATE',
  IS_BUSY: 'volkno/open/IS_BUSY',
};

// reducers
const initialState = Immutable({
  isOpen: false,
  busy: true,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE:
      return Immutable({
        isOpen: action.payload,
        busy: false,
      });

    case actionTypes.IS_BUSY:
      return state.merge(
        {
          busy: true,
        },
        { deep: true }
      );

    default:
      return state;
  }
}

// action creators
export function get() {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    dispatch({ type: actionTypes.IS_BUSY });
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/is-open`,
    };
    apiFetch('get', options, dispatch)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: actionTypes.UPDATE,
            payload: resp.json.isOpen,
          });
        } else {
          dispatch(messageActions.displayError(resp.json.error));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
