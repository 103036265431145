import React from 'react';
import './styles.scss';

export default function (props) {
  return (
    <div className="amazon-card">
      <div className="amount">${props.amount}</div>
      <img alt="" src="/images/amazon-card.png" />
      <div className="Ta-c Mt-15">
        Amazon.com
        <br />
        Gift Card
      </div>
    </div>
  );
}
