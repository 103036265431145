import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import './styles.scss';

export default class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }
  nextTip() {
    let { index } = this.state;
    const { tips } = this.props;
    if (tips[index + 1]) {
      ++index;
    } else {
      index = 0;
    }
    this.setState({
      index,
    });
  }
  render() {
    const { index } = this.state;
    const { tips = [], placement, text } = this.props;
    const TheTooltip = (
      <Popover id="popover-positioned-bottom" title="Tip">
        <div>{tips[index]}</div>
        {tips.length > 1 ? (
          <div className="Ta-c">
            <button
              onClick={this.nextTip.bind(this)}
              className="btn btn-text btn-sml Mt-10"
            >
              Next Tip <i className="fa fa-chevron-right"></i>
            </button>
          </div>
        ) : null}
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement={placement}
        overlay={TheTooltip}
      >
        {text ? (
          <span className="Cur-p">{text}</span>
        ) : (
          <i className="volkno-tooltip fa fa-question-circle"></i>
        )}
      </OverlayTrigger>
    );
  }
}
