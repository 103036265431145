/*global FB */
import React from 'react';
import PropTypes from 'prop-types';
import { facebookLogin } from 'modules/react-login/ducks/login';
import { connect } from 'react-redux';

const cfg = process.env;

class FacebookLogin extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
  };

  constructor(props) {
    super(props);
    let isMobile = false;
    try {
      isMobile =
        (window.navigator && window.navigator.standalone) ||
        navigator.userAgent.match('CriOS') ||
        navigator.userAgent.match(/mobile/i);
    } catch (ex) {
      // continue regardless of error
    }
    this.isMobile = isMobile;
  }

  componentWillMount() {
    if (!this.isMobile) {
      window.checkLoginState = this.checkLoginState.bind(this);
      this.loadFacebook();
    }
  }

  loadFacebook() {
    if (window.facebookLoaded) {
      FB.init({
        appId: cfg.REACT_APP_FACEBOOKID,
        cookie: true,
        xfbml: false,
        version: 'v2.5',
      });
      this.checkLoginState();
    } else {
      setTimeout(() => {
        this.loadFacebook();
      }, 50);
    }
  }

  checkLoginState() {
    FB.getLoginStatus((res) => {
      this.statusChangeCallback(res);
    });
  }

  statusChangeCallback(resp) {
    const { dispatch } = this.props;
    if (resp.status === 'connected') {
      const { accessToken } = resp.authResponse;
      dispatch(
        facebookLogin({ accessToken }, () => {
          FB.logout();
        })
      );
    }
  }

  click() {
    const scope = 'public_profile,email,user_friends';

    const params = {
      client_id: cfg.REACT_APP_FACEBOOKID,
      redirect_uri: cfg.REACT_APP_CLIENT_HOST,
      state: 'volknofblogin',
      scope,
    };

    if (this.isMobile) {
      let urlParams = '';
      for (const key in params) {
        if (urlParams !== '') {
          urlParams += '&';
        }
        urlParams += `${key}=${encodeURIComponent(params[key])}`;
      }
      window.location.href = `//www.facebook.com/dialog/oauth?${urlParams}`;
    } else {
      window.FB.login(this.checkLoginState.bind(this), {
        scope,
        auth_type: params.auth_type,
      });
    }
  }
  render() {
    return (
      <button
        className="btn btn-facebook Pos-r"
        onClick={this.click.bind(this)}
      >
        <span className="lp-icons lp-ico-facebook"></span>
        Facebook
      </button>
    );
  }
}
export default connect()(FacebookLogin);
