import React from 'react';

/**
 * Fetches the image at the src given and if an overlay text is provided,
 * it tries to add that text to the image via canvas.
 */
const getImageData = ({ src, overlayText }) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.style.display = 'none';
    const canvas = document.createElement('canvas');
    canvas.style.display = 'none';
    // So umm hacky solution. Sorry.
    // Here's some context but we need that t=0
    // https://stackoverflow.com/questions/44865121/canvas-tainted-by-cors-data-and-s3/44866772#44866772
    img.src = src + '?t=0';
    // This is required else it is assumed to be an insecure op
    img.crossOrigin = 'Anonymous';
    if (!overlayText) {
      // If there is nothing to overlay then don't
      // do this work
      resolve(src);
    }
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const textHeight = img.height / 40;
      ctx.font = `${textHeight}px sans-serif`;
      const { width: textWidth } = ctx.measureText(overlayText);
      const textX = img.width - 1 * textWidth;
      const textY = img.height;
      ctx.fillStyle = `rgba(0,0,0,0.1)`;
      ctx.fillRect(textX, textY - textHeight, textWidth, textHeight);
      ctx.fillStyle = 'rgba(255,255,255,0.5)';
      ctx.fillText(overlayText, textX, textY);
      const imageData = canvas.toDataURL();
      resolve(imageData);
    };
  });
};

export const usePosterImage = ({ src, overlayText }) => {
  const [image, setImageData] = React.useState(src);
  React.useEffect(() => {
    getImageData({ src, overlayText }).then(setImageData);
  }, [src, overlayText, setImageData]);

  return { image };
};
