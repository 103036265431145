import useFetch, { HookOptions } from 'react-fetch-hook';
import { getHeaders } from './fetch';

export const useFetchHook = <T = unknown>(
  url: string,
  options: HookOptions = {},
  noCache = false
): {
  data?: T;
  isLoading: boolean;
  error?: Error;
} => {
  options.headers = getHeaders(options, noCache);
  const { isLoading, data, error } = useFetch<T>(url, options);
  return { isLoading, data, error };
};
