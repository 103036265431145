import React from 'react';
import './styles.scss';

export default function (props) {
  return (
    <div className="page-about page-margin">
      <h2>About</h2>

      <p>
        VolKno (pronounced vol-K-no) was created so you can tell content
        creators what you like and dislike. Not only is it easy to give
        feedback, but you GET PAID to do it.
      </p>

      <p>
        We wanted to create a better way for viewers like you to tell content
        creators what you like and to help them make better content. Traditional
        methods like focus groups and surveys are boring and a waste of your
        valuable time. So we created a game that gives you simple, fun,
        interactive tools to give feedback and express your opinions. Oh yeah,
        and we also pay you to do it.
      </p>

      <p>
        How much you earn from volKno is straight-forward and puts you in
        control. The two primary drivers are how much you play (time &
        engagement) and how good you are (the quality of your feedback). We know
        it sounds too good to be true but it isn’t. It’s actually the only part
        of traditional research methods we’ve kept. Our clients pay us to get
        your opinions and we believe it’s only fair that you get paid too!
      </p>

      <p>
        Your opinions help us and our clients to make better movies, TV shows,
        games and music as well as ensuring that they give you what you want,
        how you want it, when and where you want it.
      </p>

      <p>Now get started and earn some Flow!</p>
    </div>
  );
}
