import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as appActions from 'ducks/app';
import { Link } from 'react-router';
import './styles.scss';

class Terms extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };
  static contextTypes = {
    router: PropTypes.object,
  };
  componentWillMount() {
    const { terms } = this.props.app.user;
    if (!terms) {
      window.fbq('track', 'CompleteRegistration');
      var i = new Image();
      i.src =
        'https://alb.reddit.com/t.gif?q=CgAFAjeQglEAJ38IAAcAAAEACgAJAAAAAhVIwRYA&' +
        's=QML_JMiKNThpVE3bdCZlXNp1ksCxFpXExrZDlbo4TKk=';
    }
  }
  acceptTerms() {
    const { dispatch } = this.props;
    dispatch(
      appActions.acceptTerms(() => {
        this.context.router.push('/');
      })
    );
  }
  render() {
    const { terms } = this.props.app.user;
    return (
      <div className="page-terms">
        <div className="page-margin">
          <h2>Terms and Conditions</h2>

          <div className="biline">
            A valid US address is required in order to redeem your points for
            cash. One account per household allowed.
          </div>

          {!terms ? (
            <p className="Fw-b">
              Please read and accept our terms and conditions below.
            </p>
          ) : null}
          <div className="terms-area">
            <p>Terms of Service</p>

            <p>
              Welcome to the VolKno website (the <b>Service</b>), the media
              research and rewards service operated by VolKno Inc. (the{' '}
              <b>Company</b>). These Terms of Service (this <b>Agreement</b>)
              apply to the customers, members, users, and others who visit and
              use the Service (collectively or individually <b>Users</b>).
            </p>

            <p>
              BEFORE USING THE SERVICE, PLEASE READ THIS AGREEMENT CAREFULLY. By
              CLICKING ON "ACCEPT", you acknowledge that you have read,
              understood, and agree to be bound by thIS AGREEMENT,{' '}
              <b>
                INCLUDING THE PROVISIONS OF SECTION 23 UNDER WHICH YOU AGREE TO
                ARBITRATE CERTAIN CLAIMS INSTEAD OF GOING TO COURT AND AGREE NOT
                TO BRING CLASS ACTIONS CLAIMS
              </b>
              . If you do not agree to ALL OF THE TERMS AND CONDITIONS OF THIS
              AGREEMENT, THEN please do not CLICK "ACCEPT," IN WHICH CASE YOU
              WILL NOT BE ABLE TO use THE SERVICE.
            </p>

            <p>
              <b>
                <span>
                  NOTE TO KIDS under <u>13</u>
                  years of age:{' '}
                </span>
              </b>
              <span>The Service is not FOR persons under the age of 13</span>.
              If you are under 13 years of age, then please do not use the
              Service. Talk to your parents about what sites are appropriate for
              you.{' '}
            </p>

            <p>
              1.<b>How it Works</b>. Users can view video and other content
              provided to the Company by third parties through the Service (
              <b>Content</b>) and answer questions or engage in other activities
              relating to such Content. Users earn reward points (<b>Flow</b>)
              for engaging in such activities that can be redeemed for rewards
              (such as gift cards) on the Service (<b>Rewards</b>). As further
              described in the Company's Privacy Policy (as referenced below),
              the Company analyzes all information submitted to or collected by
              the Company in connection with User interaction with and responses
              to Content (<b>Response Data</b>) and provides the results of such
              analysis to its customers and other third parties.{' '}
            </p>

            <p>
              2.<b>Privacy Policy. </b>The Company's Privacy Policy, found at{' '}
              <span>
                <Link to="/privacy">https://volkno.com/privacy</Link>
              </span>
              , is hereby incorporated into this Agreement. Please read this
              notice carefully for details relating to the collection, use, and
              disclosure of your information by the Company relating to the
              Service, including demographic information you submit and your
              interactions with and responses to Content.
            </p>

            <p>
              3.
              <span>
                <b>Individual Features and Services</b>
              </span>
              .When using the Service, you will be subject to any additional
              posted guidelines or rules applicable to specific services,
              offers, contests and features which may be posted from time to
              time (the <b>Guidelines</b>). All such Guidelines are hereby
              incorporated by reference into this Agreement. Please note that
              additional and/or different conditions and terms of use may apply
              to media, contests, competitions, or services provided through one
              or more of our partners or business associates, and you should
              refer to those before using such services.
            </p>

            <p>
              4.
              <span>
                <b>Modification</b>.{' '}
              </span>
              The Company may make modifications, deletions and/or additions to
              this Agreement (<b>Changes</b>) at any time. Changes will be
              effective: (i) thirty (30) days after the Company provides notice
              of the Changes, whether such notice is provided through the
              Service user interface, is sent to the e-mail address associated
              with your account or otherwise; or (ii) when you opt-in or
              otherwise expressly agree to the Changes or a version of this
              Agreement incorporating the Changes, whichever comes first.{' '}
            </p>

            <p>
              5.
              <span>
                <b>Ownership; Proprietary Rights</b>
              </span>
              . The Service is owned and operated by the Company. The content,
              visual interfaces, information, graphics, design, compilation,
              computer code, products, software, services, and all other
              elements of the Service that are provided by The Company (
              <b>Company Materials</b>) are protected by United States
              copyright, trade dress, patent, and trademark laws, international
              conventions, and all other relevant intellectual property and
              proprietary rights, and applicable laws.{' '}
              <span>
                <b>
                  For clarity, Company Materials do not include User Submissions
                  or any other content owned by and submitted by Users to the
                  Service
                </b>
              </span>
              . All Company Materials contained on the Service are the
              copyrighted property of the Company or its subsidiaries or
              affiliated companies and/or third-party licensors. All trademarks,
              service marks, and trade names are proprietary to the Company or
              its affiliates and/or third-party licensors. You agree not to
              sell, license, distribute, copy, modify, publicly perform or
              display, transmit, publish, edit, adapt, create derivative works
              from, or otherwise make unauthorized use of Company Materials.
            </p>

            <p>
              6.
              <span>
                <b>Viewing Content</b>
              </span>
              . Without limiting the generality of Section 5
              <span>
                <b>
                  , you acknowledge and agree that: (i) all Content is the
                  copyrighted property of third parties and is made available
                  for viewing by you solely on the Service in the manner enabled
                  by the Company; (ii) any copying, downloading, caching,
                  distribution, modification, public display, public
                  performance, broadcast, transmission or other exploitation of
                  the Content of any kind is strictly prohibited and may subject
                  you to civil and/or criminal liability; and (iii) other than
                  Content expressly designated as "Non-Confidential", all
                  Content and all information regarding Content (such as, by of
                  example only, title, plot, actors, actresses and crew) must be
                  kept confidential and will not be disclosed or used by you for
                  any purpose
                </b>
              </span>
              . For the avoidance of doubt, the foregoing prohibits recording or
              photographing any Content in addition to copying any digital data
              files comprising any Content.{' '}
            </p>

            <p>
              7.
              <span>
                <b>Response Data</b>
              </span>
              . You agree to only provide Response Data that is accurate,
              complete and not misleading. All Response Data provide in
              connection with your Service account must be the result of your
              personal use of the Service, and no use of bots, scripts or other
              automated means of providing Response Data is allowed. You agree
              not to (and not to attempt to) interfere with or manipulate any
              Response Data provided by any User or the collection thereof by
              the Service. Without limiting any other rights and remedies of the
              Company, your breach of this Section 7 is grounds for immediate
              termination of this Agreement and cancellation of any and all Flow
              awarded or due to you.{' '}
            </p>

            <p>
              8.
              <span>
                <b>Flow; Rewards</b>
              </span>
              .{' '}
            </p>

            <p>
              8.1.Flow may only be earned through engaging in certain activities
              on the Service, in each case as specified on the Service from time
              to time (such activities, <b>Activities</b>).{' '}
              <span>
                <b>You must be logged into your </b>
              </span>
              Service{' '}
              <span>
                <b>
                  user account through the web browser you are using to engage
                  in Activities at the relevant time in order to be eligible to
                  receive Flow.{' '}
                </b>
              </span>
              The Company has no obligation to issue Flow to you if you are
              unable to engage in any Activity for any reason, if you engage in
              an Activity without being logged into your Service user account or
              if the Company or its applicable third party service provider are
              unable for any reason to track your performance of an Activity.
            </p>

            <p>
              8.2.Flow does not constitute personal property, has no cash value
              and may not be exchanged for cash. Flow may not be transferred to
              other Users under any circumstances, including your death or
              disability. You are solely responsible for determining and
              complying with any tax obligations associated with Flow and
              Rewards. If you fail at any time to earn Flow for a period of six
              (6) months, all accrued Flow may be forfeited.
            </p>

            <p>
              8.3.The Company has the right to modify the terms and conditions
              under which Flow can be earned and/or redeemed for Rewards at any
              time even though such changes may affect what Rewards Users can
              receive in exchange for Flow or the ability to obtain certain
              Rewards. The Company may, among other things, (a) increase or
              decrease the amount of Flow received for engaging in an Activity
              or required for a Reward; (b) withdraw, limit, modify or cancel
              any Reward or the rules for earning, redeeming, retaining or
              forfeiting Flow or Rewards. The amount of Flow required to redeem
              any Reward may be substantially increased, any Reward may be
              withdrawn, and restrictions on any Reward may be imposed at any
              time. You may not rely upon the continued availability of any
              Reward or Reward category or tier.
            </p>

            <p>
              8.4.If you attempt to redeem Flow for a Reward and the Company
              determines that the Reward is unavailable, out of stock, or for
              whatever reason cannot be provided to you, the Company may, at its
              sole and absolute discretion, cancel the redemption or provide you
              with a substitute reward of equal or greater value. No credit,
              reversal, or refund of Flow will be issued for any reason after
              Flow has been redeemed; in other words, once you order a Reward,
              you may not cancel the order or return the Reward for a refund of
              Flow. Some rewards may have eligibility requirements and in that
              case the Company reserves the right to verify your identity (by
              requesting photocopy of your driver's license or state ID card, or
              other proof as we may require) and eligibility qualifications to
              our complete satisfaction prior to crediting Flow or fulfilling
              any Reward request.
            </p>

            <p>
              8.5.Rewards may be emailed to your email address or mailed to the
              U.S. postal address, as applicable, that you provided when you
              registered and created your Service account, or to the email or
              U.S. postal address that our records show your Service account was
              last updated to reflect. Processing times may vary. Rewards that
              are undeliverable or unclaimed for whatever reason (including,
              without limitation, because your Service account information is
              incorrect or outdated) may be forfeited, and the Flow may not be
              refunded.
            </p>

            <p>
              8.6.Rewards may consist of tangible or virtual gift cards, prepaid
              debit cards or other stored value instrument (collectively,{' '}
              <b>Cards</b>) which may be redeemed through one or more specified
              third parties. You acknowledge that the Company does not issue
              Cards nor exercise any control over the terms and conditions under
              which they may be redeemed or the third parties with which they
              may be redeemed, and you agree that the Company shall have no
              obligation or liability to you with respect to problem you may
              experience with respect to use of a Card. Any such problems should
              be addressed to the Card issuer.{' '}
            </p>

            <p>
              8.7.The Company reserves the right at any time to terminate a
              User's account and/or cancel accrued Flow if the Company believes,
              in its sole discretion, that the User has breached any term of
              this Agreement, engaged in any misuse or abuse of the Service or
              has misrepresented any information furnished to the Company.
            </p>

            <p>
              8.8.The Company has the right to terminate the ability to earn
              Flow and redeem Flow for Rewards at any time, provided that the
              Company shall use commercially reasonable efforts to provide at
              least fifteen (15) days prior written notice with respect to any
              such termination.
            </p>

            <p>
              9.
              <span>
                <b>Monitoring Users and Materials</b>
              </span>
              . The Company does not control any materials posted by Users and
              does not have any obligation to monitor such materials for any
              purpose. You acknowledge that you are solely responsible for all
              material that you submit on the Service. If at any time the
              Company chooses, in its sole discretion, to monitor such
              materials, the Company nonetheless assumes no responsibility for
              the content thereof, no obligation to modify or remove any
              inappropriate materials, and no responsibility for the conduct of
              the User submitting any such materials. If you believe that any
              material posted by a User on the Service infringes your
              copyrights, you may follow the procedures specified in our DMCA
              Policy at <Link to="/dmca">http://volkno.com/dmca</Link>.
            </p>

            <p>
              10.
              <span>
                <b>User Submissions</b>
              </span>
              .{' '}
            </p>

            <p>
              10.1.The Service may permit the submission and posting of text and
              other content submitted by you and other users (
              <b>User Submissions</b>), and the hosting, sharing, and/or
              publishing of such User Submissions. You understand that whether
              or not such User Submissions are published, The Company does not
              guarantee any confidentiality with respect to any User
              Submissions.{' '}
            </p>

            <p>
              10.2.You shall be solely responsible for your own User Submissions
              and the consequences of posting or publishing them. In connection
              with User Submissions, you affirm, represent, and/or warrant that:
              (i) you own, or have the necessary licenses, rights, consents, and
              permissions to use and authorize The Company to use, all patent,
              trademark, copyright, or other proprietary rights in and to any
              and all User Submissions to enable inclusion and use of User
              Submissions in the manner contemplated by the Company and this
              Agreement, and (ii) you have the written consent, release, and/or
              permission of each and every identifiable individual person in the
              User Submission to use the name or likeness of each and every such
              identifiable individual person to enable inclusion and use of the
              User Submissions in the manner contemplated by the Company and
              this Agreement.{' '}
            </p>

            <p>
              10.3.For clarity, you shall retain all of your ownership rights in
              your User Submissions. However, by submitting User Submissions to
              the Company, you hereby grant the Company a worldwide,
              non-exclusive, fully paid-up, royalty-free, irrevocable,
              perpetual, sublicenseable, and transferable license to use, sell,
              reproduce, distribute, prepare derivative works of, display,
              perform, and otherwise exploit your User Submissions in connection
              with the Service and the Company's (and its successor's) business,
              including without limitation for promoting and redistributing part
              or all of the Service (and derivative works thereof) in any media
              formats and through any media channels. You also hereby grant to
              each user of the Service a non-exclusive license to access your
              User Submissions through the Service, and to use, reproduce,
              distribute, prepare derivative works of, display, and perform such
              User Submissions as permitted by the functionality of the Service
              and this Agreement. The aforementioned licenses will terminate
              when User or the Company removes User's Submission(s) from the
              Service.{' '}
            </p>

            <p>
              10.4.In connection with User Submissions and any other
              transmission of messages or material facilitated by the Service,
              you further agree that you will not: (i) submit or transmit
              material that is not authorized by the copyright owner, protected
              by trade secret or otherwise subject to third party proprietary
              rights, including privacy and publicity rights, unless you are the
              owner of such rights or have permission from their rightful owner
              to post and sell the material and to grant the Company all of the
              license rights granted herein; (ii) publish or transmit falsehoods
              or misrepresentations that could damage the Company or any third
              party; (iii) submit or transmit material that is unlawful,
              defamatory, libelous, threatening, pornographic, harassing,
              hateful, racially or ethnically offensive or encourages conduct
              that would be considered a criminal offense, give rise to civil
              liability, violate any law or is otherwise inappropriate; (iv)
              post or transmit advertisements or solicitations of business; or
              (v) impersonate another person. If notified by a user or a content
              owner of a User Submission that allegedly does not conform to this
              Agreement, the Company may investigate the allegation and
              determine in good faith and in its sole discretion whether to
              remove the User Submission, which it reserves the right to do at
              any time. Additionally, the Company may, at any time, remove any
              User Submission that in the sole judgment of the Company violates
              this Agreement.{' '}
            </p>

            <p>
              10.5.The Company reserves the right to discontinue any aspect of
              the Service at any time, including the right to discontinue the
              display of any User Submissions either generally or specifically.
            </p>

            <p>
              11.
              <span>
                <b>Prohibited Uses</b>
              </span>
              .
            </p>

            <p>
              11.1.
              <span>
                As a condition of your use of the Service, you will not use the
                Service for any purpose that is unlawful or prohibited by this
                Agreement. Access to Company Materials and the Service from a
                jurisdiction where their contents are illegal is strictly
                prohibited. Users are responsible for complying with all local
                rules, laws, and regulations including, without limitation,
                rules about intellectual property rights, the Internet,
                technology, data, email, or privacy. You are not permitted to
                use the Service if you are (i) located in, under the control of,
                or a national or resident of any country to which the United
                States has embargoed goods or services; (ii) identified as a
                "Specially Designated National"; or (iii) placed on the U.S.
                Department of Commerce's "Denied Persons List or Entity List" or
                any other U.S. export control list, or if the Company's
                provision of such use would otherwise be illegal under any
                applicable law, rule or regulation.{' '}
              </span>
            </p>

            <p>
              11.2.Any use by User of any of Company Materials other than for
              private, non-commercial use is strictly prohibited.{' '}
            </p>

            <p>
              11.3.You may not use the Service in any manner that in our sole
              discretion could damage, disable, overburden, or impair it or
              interfere with any other party's use of the Service. You may not
              intentionally interfere with or damage the operation of the
              Service or any User's enjoyment of it, by any means, including
              uploading or otherwise disseminating viruses, worms, or other
              malicious code. You may not remove, circumvent, disable, damage or
              otherwise interfere with any security-related features of the
              Service, features that prevent or restrict the use or copying of
              any content accessible through the Service, or features that
              enforce limitations on the use of the Service. You may not attempt
              to gain unauthorized access to the Service, or any part of it,
              other accounts, computer systems or networks connected to the
              Service, or any part of it, through hacking, password mining or
              any other means or interfere or attempt to interfere with the
              proper working of the Service or any activities conducted on the
              Service. You may not obtain or attempt to obtain any materials or
              information through any means not intentionally made available
              through the Service. You agree neither to modify the Service in
              any manner or form, nor to use modified versions of the Service,
              including (without limitation) for the purpose of obtaining
              unauthorized access to the Service.{' '}
            </p>

            <p>
              11.4.The Service may contain robot exclusion headers. You agree
              that you will not use any robot, spider, scraper, or other
              automated means to access the Service for any purpose without our
              express written permission or bypass our robot exclusion headers
              or other measures we may use to prevent or restrict access to the
              Service.
            </p>

            <p>
              11.5.You may not utilize framing techniques to enclose any
              trademark, logo, or other Company Materials without our express
              written consent. You may not use any meta tags or any other
              "hidden text" utilizing the Company's name or trademarks without
              our express written consent.{' '}
            </p>

            <p>
              12.
              <span>
                <b>Account Information</b>
              </span>
              . You agree that the information you provide to the Company upon
              purchase or member registration and, at all other times, will be
              true, accurate, current, and complete. You also agree that you
              will ensure that this information is kept accurate and up-to-date
              at all times.
            </p>

            <p>
              13.
              <span>
                <b>Password</b>
              </span>
              . When you register you will be asked to provide a password. As
              you will be responsible for all activities that occur under your
              password, you should keep your password confidential. You are
              solely responsible for maintaining the confidentiality of your
              account and password and for restricting access to your computer,
              and you agree to accept responsibility for all activities that
              occur under your account or password. If you have reason to
              believe that your account is no longer secure (for example, in the
              event of a loss, theft or unauthorized disclosure or use of your
              account ID or password, you will immediately notify the Company.
              You may be liable for the losses incurred by the Company or others
              due to any unauthorized use of your account.
            </p>

            <p>
              14.
              <span>
                <b>User Communications</b>
              </span>
              . Under this Agreement, you consent to receive communications from
              the Company electronically. We will communicate with you by email
              or by posting notices on the Service. You agree that all
              agreements, notices, disclosures, and other communications that we
              provide to you electronically satisfy any legal requirement that
              such communications be in writing.
            </p>

            <p>
              15.
              <span>
                <b>User Service; Feedback</b>
              </span>
              . Please visit our Contact Us information page for further
              assistance.{' '}
            </p>

            <p>
              16.
              <span>
                <b>Availability of Service. </b>
              </span>
              The Company may make changes to or discontinue any of the media,
              contests, web communities, products, or services available within
              the Service at any time, and without notice. The media, products,
              or services on the Service may be out of date, and the Company
              makes no commitment to update these materials on the Service.{' '}
            </p>

            <p>
              17.
              <span>
                <b>Notice</b>
              </span>
              . Except as explicitly stated otherwise, legal notices shall be
              served on the Company's national registered agent or to the email
              address you provide to the Company during the registration
              process. Notice shall be deemed given 24 hours after email is
              sent, unless the sending party is notified that the email address
              is invalid. Alternatively, we may give you legal notice by mail to
              the address provided during the registration or purchase process.
              In such case, notice shall be deemed given three days after the
              date of mailing.
            </p>

            <p>
              18.
              <span>
                <b>Disagreements</b>
              </span>
              . You alone are responsible for your involvement with any other
              Users with whom you may interact in connection with the Service.
              The Company reserves the right, but has no obligation, to monitor
              disagreements between you and such third parties.{' '}
            </p>

            <p>
              19.
              <span>
                <b>Violations</b>
              </span>
              ;{' '}
              <span>
                <b>Termination</b>
              </span>
              . You agree that the Company, in its sole discretion and for any
              or no reason, may terminate this Agreement, any account (or any
              part thereof) you may have with the Service or your use of the
              Service, and remove and discard all or any part of your account or
              any User Submission, at any time. The Company may also in its sole
              discretion and at any time discontinue providing access to the
              Service, or any part thereof, with or without notice. Any of the
              foregoing may also constitute cancellation of any Points which you
              may have accrued at such time. You agree that any termination of
              this Agreement, your access to the Service or any account you may
              have or portion thereof may be effected without prior notice, and
              you agree that the Company shall not be liable to you or any
              third-party for any such termination. The Company does not permit
              copyright infringing activities on the Service, and reserves the
              right to terminate access to the Service, and remove all content
              submitted, by any persons who are found to be repeat infringers.
              Any suspected fraudulent, abusive, or illegal activity that may be
              grounds for termination of your use of the Service may be referred
              to appropriate law enforcement authorities. These remedies are in
              addition to any other remedies the Company may have at law or in
              equity.{' '}
            </p>

            <p>
              20.
              <span>
                <b>Disclaimers</b>
              </span>
              ;{' '}
              <span>
                <b>No Warranties</b>
              </span>
              . THE SERVICE AND ANY THIRD-PARTY, MEDIA, SOFTWARE, SERVICES, OR
              APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR THROUGH THE
              SERVICE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND
              EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE
              PURSUANT TO APPLICABLE LAW, THE COMPANY, AND ITS SUPPLIERS,
              LICENSORS AND PARTNERS, DISCLAIM ALL WARRANTIES, EXPRESS OR
              IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT OF PROPRIETARY RIGHTS.{' '}
            </p>

            <p>
              THE COMPANY, AND ITS SUPPLIERS AND PARTNERS, DO NOT WARRANT THAT
              THE FEATURES CONTAINED IN THE SERVICE WILL BE UNINTERRUPTED OR
              ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICE OR
              THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS.
            </p>

            <p>
              THE COMPANY, AND ITS SUPPLIERS AND PARTNERS, DO NOT WARRANT OR
              MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE
              USE OF THE SERVICE IN TERMS OF IT CORRECTNESS, ACCURACY,
              RELIABILITY, OR OTHERWISE. YOU (AND NOT THE COMPANY NOR ITS
              SUPPLIERS AND PARTNERS) ASSUME THE ENTIRE COST OF ANY NECESSARY
              SERVICING, REPAIR, OR CORRECTION. YOU UNDERSTAND AND AGREE THAT
              YOU ACCESS MEDIA, MATERIAL, OR OTHER DATA THROUGH THE USE OF THE
              SERVICE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE
              SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS
              OF DATA THAT RESULTS FROM SUCH MEDIA, MATERIAL OR DATA.
            </p>

            <p>
              CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
              OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
              APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR
              LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL
              RIGHTS.
            </p>

            <p>
              21.
              <span>
                <b>Indemnification; Hold Harmless</b>
              </span>
              . You agree to indemnify and hold the Company, and its affiliated
              companies, and its suppliers and partners, harmless from any
              claims, losses, damages, liabilities, including attorney's fees,
              arising out of your use or misuse of the Service (including any
              Content), violation of this Agreement or violation of the rights
              of any other person or entity. The Company reserves the right, at
              our own expense, to assume the exclusive defense and control of
              any matter for which you are required to indemnify us and you
              agree to cooperate with our defense of these claims.
            </p>

            <p>
              22.
              <span>
                <b>Limitation of Liability and Damages</b>
              </span>
              . UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO,
              NEGLIGENCE, SHALL THE COMPANY OR ITS AFFILIATES, CONTRACTORS,
              EMPLOYEES, AGENTS, OR THIRD PARTY PARTNERS OR SUPPLIERS, BE LIABLE
              TO YOU FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR
              EXEMPLARY DAMAGES THAT RESULT FROM YOUR USE OR THE INABILITY TO
              USE COMPANY MATERIALS ON THE SERVICE, THE SERVICE ITSELF, OR ANY
              OTHER INTERACTIONS WITH THE COMPANY, EVEN IF THE COMPANY OR A THE
              COMPANY AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES.{' '}
            </p>

            <p>
              IN NO EVENT SHALL THE COMPANY's OR ITS AFFILIATES, CONTRACTORS,
              EMPLOYEES, AGENTS, OR THIRD PARTY PARTNERS OR SUPPLIERS" TOTAL
              LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION
              ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR USE OF THE
              SERVICE (WHETHER IN CONTRACT, TORT, WARRANTY, OR OTHERWISE) EXCEED
              ONE HUNDRED DOLLARS.
            </p>

            <p>
              THESE LIMITATIONS SHALL ALSO APPLY WITH RESPECT TO DAMAGES
              INCURRED BY REASON OF ANY PRODUCTS OR SERVICES SOLD OR PROVIDED TO
              YOU BY THIRD PARTIES OTHER THAN THE COMPANY AND RECEIVED BY YOU
              THROUGH OR ADVERTISED ON THE SERVICE OR RECEIVED BY YOU THROUGH
              ANY LINKS PROVIDED ON THE SERVICE.
            </p>

            <p>
              APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF
              LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
              LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, THE
              COMPANY's LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT
              PERMITTED BY LAW.
            </p>

            <p>
              23.
              <span>
                <b>Arbitration</b>.{' '}
              </span>
            </p>

            <p>
              23.1.
              <span>
                <u>Agreement to Arbitrate</u>. This Section 23 is referred to
                herein as the <b>Arbitration Agreement</b>." The parties agree
                that any and all controversies, claims, or disputes between you
                and Company arising out of, relating to, or resulting from this
                Agreement, shall be subject to binding arbitration pursuant to
                the terms and conditions of this Arbitration Agreement, and not
                any court action (other than a small claims court action to the
                extent the claim qualifies). The Federal Arbitration Act governs
                the interpretation and enforcement of this Arbitration
                Agreement.
              </span>
            </p>

            <p>
              23.2.
              <span>
                <u>Class Action Waiver</u>. THE PARTIES AGREE THAT EACH PARTY
                MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS
                AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH PARTIES AGREE
                OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN
                ONE PERSON'S OR PARTY'S CLAIMS AND MAY NOT OTHERWISE PRESIDE
                OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS
                PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING
                MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF
                THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
                NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S
                INDIVIDUAL CLAIM(S).
              </span>
            </p>

            <p>
              23.3.
              <span>
                <u>Procedures</u>. Arbitration will be conducted by a neutral
                arbitrator in accordance with the American Arbitration
                Association's ("<b>AAA</b>") rules and procedures (the "
                <b>AAA Rules</b>"), as modified by this Arbitration Agreement.
                If there is any inconsistency between the AAA Rules and this
                Arbitration Agreement, the terms of this Arbitration Agreement
                will control unless the arbitrator determines that the
                application of the inconsistent Arbitration Agreement terms
                would not result in a fundamentally fair arbitration. The
                arbitrator must also follow the provisions of this Agreement as
                a court would, including without limitation, the limitation of
                liability provisions in Section 22. You may visit
                http://www.adr.org for information on the AAA and
                http://www.adr.org/fileacase for information on how to file a
                claim against the Company.{' '}
              </span>
            </p>

            <p>
              23.4.
              <span>
                <u>Venue</u>. The arbitration shall be held in the county in
                which you reside or at another mutually agreed location. If the
                value of the relief sought is $10,000 or less, you or Company
                may elect to have the arbitration conducted by telephone or
                based solely on written submissions, which election shall be
                binding on each party, but subject to the arbitrator's
                discretion to require an in-person hearing if the circumstances
                warrant. Attendance at any in-person hearing may be made by
                telephone by either or both parties unless the arbitrator
                requires otherwise.
              </span>
            </p>

            <p>
              23.5.
              <span>
                <u>Governing Law</u>. The arbitrator will decide the substance
                of all claims in accordance with the laws of the state of
                California, without regard to its conflicts of laws rules, and
                will honor all claims of privilege recognized by law. The
                arbitrator shall not be bound by rulings in prior arbitrations
                involving different Service users, but is bound by rulings in
                prior arbitrations involving you to the extent required by
                applicable law.
              </span>
            </p>

            <p>
              23.6.
              <span>
                <u>Costs of Arbitration</u>. Payment of all filing,
                administration, and arbitrator fees will be governed by the
                AAA's Rules. Each party will be responsible for all other fees
                it incurs in connection with the arbitration, including without
                limitation, all attorney fees.
              </span>
            </p>

            <p>
              23.7.
              <span>
                <u>Confidentiality</u>. All aspects of the arbitration
                proceeding, and any ruling, decision or award by the arbitrator,
                will be strictly confidential for the benefit of all parties.
              </span>
            </p>

            <p>
              23.8.
              <span>
                <u>Severability</u>. If a court decides that any term or
                provision of this Arbitration Agreement other than Section 23.2
                is invalid or unenforceable, the parties agree to replace such
                term or provision with a term or provision that is valid and
                enforceable and that comes closest to expressing the intention
                of the invalid or unenforceable term or provision, and this
                Arbitration Agreement shall be enforceable as so modified. If a
                court decides that any of the provisions of Section 23.2 is
                invalid or unenforceable, then the entirety of this Arbitration
                Agreement shall be null and void. The remainder of this
                Agreement will continue to apply.
              </span>
            </p>

            <p>
              24.
              <span>
                <b>Miscellaneous</b>
              </span>
              .{' '}
            </p>

            <p>
              24.1.
              <span>
                <b>Governing Law</b>
              </span>
              . This Agreement shall be governed by and construed in accordance
              with the laws of the State of California, without giving effect to
              any principles of conflicts of law.
            </p>

            <p>
              24.2.
              <span>
                <b>Jurisdiction</b>
              </span>
              . You agree that any action at law or in equity arising out of or
              relating to this Agreement or the Service that is not subject to
              mandatory arbitration as set forth above in Section 23 shall be
              filed only in the state or federal courts in and for Los Angeles
              County, California and you hereby consent and submit to the
              personal jurisdiction of such courts for the purposes of
              litigating any such action.{' '}
            </p>

            <p>
              24.3.
              <span>
                <b>Waiver</b>
              </span>
              . A provision of this Agreement may be waived only by a written
              instrument executed by the party entitled to the benefit of such
              provision. The failure of any party at any time to require
              performance of any provision of this Agreement shall in no manner
              affect such party's right at a later time to enforce the same. A
              waiver of any breach of any provision of this Agreement shall not
              be construed as a continuing waiver of other breaches of the same
              or other provisions of this Agreement.
            </p>

            <p>
              24.4.
              <span>
                <b>Severability</b>
              </span>
              . If any provision of this Agreement shall be unlawful, void, or
              for any reason unenforceable, then that provision shall be deemed
              severable from this Agreement and shall not affect the validity
              and enforceability of any remaining provisions.
            </p>

            <p>
              24.5.
              <span>
                <b>Assignment</b>
              </span>
              . This Agreement and related Guidelines, and any rights and
              licenses granted hereunder, may not be transferred or assigned by
              you, but may be assigned by the Company without restriction.
            </p>

            <p>
              24.6.
              <span>
                <b>Survival</b>
              </span>
              . Sections 2, 3, 5, 6, 7, 8, 9, 10.2, 10.3, 10.4, 10.5 and 11
              through 24 will survive any termination of this Agreement.{' '}
            </p>

            <p>
              24.7.
              <span>
                <b>Headings</b>
              </span>
              . The heading references herein are for convenience purposes only,
              do not constitute a part of this Agreement, and shall not be
              deemed to limit or affect any of the provisions hereof.
            </p>

            <p>
              24.8.
              <span>
                <b>Entire Agreement</b>
              </span>
              . This is the entire agreement between us relating to the subject
              matter herein and shall not be modified except in a writing,
              signed by both parties, or by a change to this Agreement or
              Guidelines made by the Company as set forth in Section 3 above.
            </p>

            <p>
              24.9. Disclosures. The services hereunder are offered by VolKno,
              Inc., located at 8033 Sunset Blvd. #359 West Hollywood, CA 90046,
              email: [privacy@volkno.com], telephone: [1-323-849-0313]. If you
              are a California resident, you may have this same information
              emailed to you by sending a letter to the foregoing address with
              your email address and a request for this information.{' '}
            </p>
          </div>
          {!terms ? (
            <button
              onClick={this.acceptTerms.bind(this)}
              className="btn btn-primary mobile-100"
            >
              Accept Terms
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}
export default connect((state) => ({
  app: state.app,
}))(Terms);
