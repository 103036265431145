import apiFetch from '../utils/fetch';
import { messageActions } from 'modules/react-message';
import cookie from 'react-cookie';

const cfg = process.env;

// constants
export const actionTypes = {
  ATTEMPT: 'account/create/ATTEMPT',
  FAIL: 'account/create/FAIL',
  SUCCESS: 'account/create/SUCCESS',
};

// reducers
const initialState = {
  isCreating: false,
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ATTEMPT:
      return {
        ...state,
        isCreating: true,
      };

    case actionTypes.FAIL:
    case actionTypes.SUCCESS:
      return {
        ...state,
        isCreating: false,
      };

    default:
      return state;
  }
}

// action creators
export function createError() {
  return { type: actionTypes.FAIL };
}

export function createAttempt() {
  return { type: actionTypes.ATTEMPT };
}

export function createSuccess() {
  return { type: actionTypes.SUCCESS };
}

export function createCall(userData, returnURL, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(createAttempt());
    dispatch(messageActions.clearAll());
    return apiFetch(dispatch, cfg.REACT_APP_API_BASE + '/account/create', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: userData.email.trim(),
        password: userData.password,
        promo: cookie.load('promo'),
        returnURL,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          onSuccess();
        } else {
          dispatch(messageActions.displayError(response.json.error));
          dispatch(createError());
          onFailure(response);
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
        dispatch(createError());
      });
  };
}
