import { SurveyComponent } from 'modules/survey-volkno/SurveyComponent';
import { SurveyConfig, SurveyResult } from 'modules/survey-volkno/types';
import React, { useState } from 'react';
import styled from '@emotion/styled';

const StyledSurvey = styled<
  typeof SurveyComponent,
  { height?: string; width?: string }
>(SurveyComponent)`
  padding: 20px;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 99999;
  overflow: auto;
`;

interface VideoSurvey {
  survey: SurveyConfig;
  time: number;
  // Check tolerance in time, i.e. if the time is 10 but the update happens
  // at 10.5 then we should consider that as valid if tolerance is 0.51 or greater
  tolerance: number;
}

export interface VideoSurveyOrchestratorProps {
  videoTimeLocation: number;
  pauseVideo: () => any;
  playVideo: () => any;
  height: number;
  width: number;
  surveys?: VideoSurvey[];
  onSurveyCompleted?: (surveyId: string, results: SurveyResult) => any;
}

const shouldDisplaySurvey = (
  surveyTime: number,
  currentTime: number,
  tolerance: number
) => {
  return currentTime >= surveyTime - tolerance;
};

export const VideoSurveyOrchestrator: React.FC<VideoSurveyOrchestratorProps> = ({
  children,
  pauseVideo,
  playVideo,
  videoTimeLocation,
  surveys = [],
  onSurveyCompleted,
}) => {
  const [visibleSurvey, setVisibleSurvey] = useState<VideoSurvey | null>(null);
  const [surveysCompleted, surveyCompleted] = useState<Set<string | number>>(
    new Set()
  );
  React.useEffect(() => {
    const survey = surveys.find(
      (s) =>
        shouldDisplaySurvey(s.time, videoTimeLocation, s.tolerance) &&
        !surveysCompleted.has(s.survey.id)
    );
    setVisibleSurvey(survey || null);
    if (visibleSurvey) {
      pauseVideo();
    }
  }, [
    surveys,
    videoTimeLocation,
    setVisibleSurvey,
    pauseVideo,
    surveysCompleted,
    visibleSurvey,
  ]);

  const onSurveyEnd = React.useCallback(
    (surveyId: string) => (result: SurveyResult) => {
      surveyCompleted(new Set(surveysCompleted).add(surveyId));
      setVisibleSurvey(null);
      playVideo();

      onSurveyCompleted && onSurveyCompleted(surveyId, result);
    },
    [playVideo, surveysCompleted, surveyCompleted, onSurveyCompleted]
  );

  return (
    <>
      {visibleSurvey && (
        <StyledSurvey
          key={visibleSurvey.survey.id}
          config={visibleSurvey.survey}
          onEnded={onSurveyEnd(visibleSurvey.survey.id)}
        />
      )}
      {children}
    </>
  );
};
