//import { UPDATE_PATH } from 'react-router-redux';
import constants from './constants';

// reducers
const initialState = {
  error: null,
  success: null,
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.DISPLAY_ERROR:
      return {
        ...state,
        error: action.error,
        success: null,
      };

    case constants.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    case constants.DISPLAY_SUCCESS:
      return {
        ...state,
        success: action.success,
        error: null,
      };

    case constants.CLEAR_SUCCESS:
      return {
        ...state,
        success: null,
      };

    case constants.CLEAR_ALL:
      return {
        ...state,
        error: null,
        success: null,
      };

    // case UPDATE_PATH:
    //   return {
    //     ...state,
    //     error: null,
    //     success: null
    //   };

    default:
      return state;
  }
}
