import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as earningsActions from 'ducks/earnings';
import { Modal } from 'react-bootstrap';
import Profile from './Modals/Profile';
import SurveyDone from './Modals/SurveyDone';
import CampaignUnlocked from './Modals/CampaignUnlocked';
import SurveyUnlocked from './Modals/SurveyUnlocked';
import './styles.scss';

const alertMap = {
  PROFILE: Profile,
  SURVEY_DONE: SurveyDone,
  CAMPAIGN_UNLOCKED: CampaignUnlocked,
  SURVEY_UNLOCKED: SurveyUnlocked,
};

class Alerts extends React.Component {
  static propTypes = {
    earnings: PropTypes.object,
    dispatch: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      alertModalOpen: false,
    };
  }
  componentWillMount() {
    this.alertCheck(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.alertCheck(nextProps);
  }
  alertCheck(props) {
    const {
      earnings: { showAlerts = [] },
    } = props;
    if (showAlerts.length) {
      this.setState({
        alertModalOpen: true,
      });
    }
  }
  closeModal() {
    this.setState({
      alertModalOpen: false,
    });
  }
  onAlertShown() {
    const {
      dispatch,
      earnings: { showAlerts, alerts = {} },
    } = this.props;
    const alertData = alerts.data[showAlerts[0]];
    const silent =
      alertData.alert_id && String(alertData.alert_id).indexOf('tmp_') === 0;
    dispatch(earningsActions.alertShown(showAlerts[0], null, silent));
  }
  render() {
    const { alertModalOpen } = this.state;
    const {
      earnings: { showAlerts = [], alerts = {} },
    } = this.props;
    if (showAlerts.length === 0 || !alerts.list || !alerts.list.length) {
      return null;
    }
    const alertData = alerts.data[showAlerts[0]];
    const alertCode = alertData.code;

    if (!alertMap.hasOwnProperty(alertCode)) {
      return null;
    }
    const AlertContent = alertMap[alertCode];

    return (
      <div>
        <Modal
          show={alertModalOpen}
          onExited={this.onAlertShown.bind(this)}
          size="lg"
          className="alert-modal"
          backdrop={'static'}
        >
          <Modal.Header
            closeButton
            onHide={this.closeModal.bind(this)}
          ></Modal.Header>
          <AlertContent
            close={this.closeModal.bind(this)}
            {...(alertData.data || {})}
          />
        </Modal>
      </div>
    );
  }
}

export default connect((state) => ({
  earnings: state.earnings,
}))(Alerts);
