import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import Select from 'react-select';
import cx from 'classnames';
import formsyErrorFix from './formsyErrorFix';
import Datetime from 'react-datetime';
// import moment from 'momentTimezone';

const timezoneOptions = []; /* moment.tz.names().map(tz => ({
  value: tz,
  label: tz,
}));*/

// DateTime for formsy

@formsyErrorFix
class DateTime extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    getErrorText: PropTypes.func,
    setValue: PropTypes.func,
    getValue: PropTypes.func,
    title: PropTypes.any,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    timeFormat: PropTypes.any,
    dateFormat: PropTypes.any,
    viewMode: PropTypes.string,
    tz: PropTypes.string,
    displayTZ: PropTypes.bool,
    isFormDisabled: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      editTimezone: false,
      tz: props.tz || 'America/Los_Angeles',
    };
  }
  onDateTimeChange(value) {
    this.bubbleValue(value);
  }
  onTimezoneChange(tz) {
    this.setState(
      {
        tz: tz,
      },
      this.bubbleValue
    );
  }
  bubbleValue(value) {
    let formValue = value;
    if (typeof value === 'object') {
      formValue = value.clone().format('x');
    }
    this.props.setValue(formValue);
  }
  showTimezonePicker() {
    this.setState({
      editTimezone: true,
    });
  }
  hideTimezonePicker() {
    this.setState({
      editTimezone: false,
    });
  }
  render() {
    const {
      name,
      title,
      className,
      getErrorText,
      getValue,
      isFormDisabled,
      disabled,
      displayTZ,
    } = this.props;
    const { tz, editTimezone } = this.state;
    let value = getValue();
    const hasError = getErrorText();
    const id = name + '-dd';
    const inputProps = {
      name,
      className: 'form-control',
      disabled: isFormDisabled() || disabled,
    };
    const extraProps = {};
    if (this.props.hasOwnProperty('timeFormat')) {
      extraProps.timeFormat = this.props.timeFormat;
    }
    if (this.props.hasOwnProperty('dateFormat')) {
      extraProps.dateFormat = this.props.dateFormat;
    }
    if (this.props.hasOwnProperty('viewMode')) {
      extraProps.viewMode = this.props.viewMode;
    }
    if (typeof value === 'string' && !isNaN(value)) {
      value = Number(value);
    }
    const dtValue = typeof value !== 'object' ? value : value.clone().tz(tz);
    let colClass = 'col-12';
    if (displayTZ) {
      colClass = !editTimezone ? 'col-8' : 'col-5';
    }
    return (
      <div className={cx('form-group', className)} data-test={name}>
        <label htmlFor={id}>{title}</label>
        <div className="row">
          <div className={cx(colClass, { 'has-error': hasError })}>
            <div className="right-inner-addon">
              <i className="fa fa-calendar Fz-18"></i>
              <Datetime
                value={dtValue}
                inputProps={inputProps}
                onChange={this.onDateTimeChange.bind(this)}
                {...extraProps}
              />
            </div>
          </div>
          {displayTZ ? (
            <div
              className={cx({ 'col-4': !editTimezone, 'col-7': editTimezone })}
            >
              {editTimezone ? (
                <div className="row">
                  <div className="col-10">
                    <TimezoneSelect
                      name={'tz-' + id}
                      value={this.state.tz}
                      onChange={this.onTimezoneChange.bind(this)}
                      disabled={isFormDisabled() || disabled}
                    />
                  </div>
                  <div
                    className="col-2 close Mt-20"
                    onClick={this.hideTimezonePicker.bind(this)}
                  >
                    <i className="fa fa-check"></i>
                  </div>
                </div>
              ) : (
                <div className="Mt-15">
                  <div>Timezone:</div>
                  <div
                    className="link"
                    onClick={this.showTimezonePicker.bind(this)}
                  >
                    <strong className="Pr-5">{this.state.tz}</strong>
                    <i className="fa fa-pencil-square-o"></i>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
        <div className="validation-error">{hasError}</div>
      </div>
    );
  }
}

class TimezoneSelectComponent extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    validationError: PropTypes.string,
    setValue: PropTypes.func,
    showError: PropTypes.func,
    showRequired: PropTypes.func,
    isPristine: PropTypes.func,
    isFormDisabled: PropTypes.func,
    getValue: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
    disabled: PropTypes.bool,
  };
  shouldComponentUpdate(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value,
      });
      return false;
    }
    return true;
  }
  onTimezoneChange(value) {
    this.props.setValue(value);
    this.props.onChange(value);
  }
  render() {
    const { name, isFormDisabled, disabled, value } = this.props;
    const id = name + '-dd';
    return (
      <Select
        name={'tz-' + id}
        value={value}
        options={timezoneOptions}
        placeholder={'Timezone'}
        onChange={this.onTimezoneChange.bind(this)}
        clearable={false}
        disabled={isFormDisabled() || disabled}
      />
    );
  }
}
const TimezoneSelect = withFormsy(TimezoneSelectComponent);
export default withFormsy(DateTime);
