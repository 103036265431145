import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import cx from 'classnames';
import formsyErrorFix from './formsyErrorFix';

// Input for formsy

@formsyErrorFix
class Input extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    iconClass: PropTypes.string,
    placeholder: PropTypes.string,
    setValue: PropTypes.func,
    showRequired: PropTypes.func,
    isPristine: PropTypes.func,
    getErrorText: PropTypes.func,
    showError: PropTypes.func,
    getValue: PropTypes.func,
    isFormDisabled: PropTypes.func,
    title: PropTypes.any,
    value: PropTypes.any,
    maxLength: PropTypes.number,
    disabled: PropTypes.bool,
    info: PropTypes.any,
  };
  onChange(e) {
    this.props.setValue(e.currentTarget.value);
  }
  componentDidMount() {
    const { defaultValue, setValue } = this.props;
    if (defaultValue) {
      setValue(defaultValue);
    }
  }
  render() {
    const {
      getValue,
      name,
      title,
      className,
      iconClass,
      type,
      getErrorText,
      showError,
      maxLength,
      isFormDisabled,
      disabled,
      info,
      placeholder,
      ...rest
    } = this.props;
    const errorText = getErrorText();
    const id = name + '-dd';
    const value = getValue() || '';
    return (
      <div
        className={cx('form-group', className, {
          'has-error': errorText && showError(),
        })}
        data-test={name}
      >
        {title ? <label htmlFor={id}>{title}</label> : null}
        <div className={cx('input-wrapper', { 'has-icon': iconClass })}>
          {iconClass ? <span className={iconClass}></span> : null}
          <input
            className="form-control"
            type={type || 'text'}
            name={name}
            placeholder={placeholder}
            onChange={this.onChange.bind(this)}
            maxLength={maxLength}
            value={value}
            checked={type === 'checkbox' && getValue() ? 'checked' : null}
            disabled={isFormDisabled() || disabled}
            {...rest}
          />
        </div>
        {showError() ? (
          <span className="validation-error">{errorText}</span>
        ) : null}
        {info ? <div className="input-info">{info}</div> : null}
      </div>
    );
  }
}

export default withFormsy(Input);
