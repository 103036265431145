/*global FB */
import React from 'react';
import PropTypes from 'prop-types';
import strings from 'config/strings';
import { createMessageComponent } from 'modules/react-message';
import { connect } from 'react-redux';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import Sidebar from 'components/common/Sidebar';
import SidebarPublic from 'components/common/SidebarPublic';
import Alerts from 'components/common/Alerts';
import * as appActions from 'ducks/app';
import * as earningsActions from 'ducks/earnings';
import * as surveysActions from 'ducks/surveys';
import { StickyContainer } from 'react-sticky';
import { logout } from 'modules/react-login/ducks/login';

const MessageBar = createMessageComponent(strings);
const ReactSidebar = require('react-sidebar').default;
let sbEventHandle;

const cfg = process.env;

class TwoColumn extends React.Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    children: PropTypes.element,
    dispatch: PropTypes.func,
    app: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      sidebarDocked: false,
    };
  }

  static contextTypes = {
    url: PropTypes.object,
    router: PropTypes.object,
  };

  componentWillMount() {
    this.loadFacebook();
    this.mql = window.matchMedia(`(min-width: 1050px)`);
    sbEventHandle = this.mediaQueryChanged.bind(this);
    this.mql.addListener(sbEventHandle);
    this.setState({
      sidebarDocked: this.mql.matches,
    });
    this.initAudience();
    if (this.context.url.path === '/verify') {
      this.context.router.push(`/profile?p=address`);
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    window.onmessage = function (e) {
      if (e.data === 'survey') {
        dispatch(appActions.get());
        dispatch(earningsActions.getAll());
        dispatch(surveysActions.getSurveys());
      }
    };
  }

  componentWillUnmount() {
    this.mql.removeListener(sbEventHandle);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.app.audience !== this.props.app.audience) {
      this.initAudience();
    }
    return true;
  }

  initAudience() {
    const { noCache = false } = this.context.url.query;
    const { app, dispatch, isLoggedIn } = this.props;
    if (!isLoggedIn) {
      return;
    }
    const cb = !app.audience
      ? () => {
          dispatch(earningsActions.getAll());
        }
      : () => {};
    dispatch(appActions.get({ noCache }, cb));
    // has audience, run it now instead of waiting
    if (app.audience) {
      dispatch(earningsActions.getAll());
      dispatch(surveysActions.getSurveys());
    }
  }

  mediaQueryChanged() {
    this.setState({
      sidebarDocked: this.mql.matches,
    });
  }

  onSetSidebarOpen(open) {
    this.setState({
      sidebarOpen: open,
    });
  }

  toggleSidebar() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    });
  }

  loadFacebook() {
    if (window.facebookLoaded) {
      FB.init({
        appId: cfg.REACT_APP_FACEBOOKID,
        cookie: true,
        xfbml: false,
        version: 'v2.5',
      });
    } else {
      setTimeout(() => {
        this.loadFacebook();
      }, 50);
    }
  }
  logout() {
    FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        FB.logout(() => {
          this.props.dispatch(
            logout(() => {
              this.context.router.push(`/welcome`);
            })
          );
        });
      } else {
        this.props.dispatch(
          logout(() => {
            this.context.router.push(`/welcome`);
          })
        );
      }
    });
  }
  render() {
    const { sidebarOpen, sidebarDocked } = this.state;
    const {
      location: { pathname },
      app: { busy },
      isLoggedIn,
    } = this.props;
    const sidebarStyles = {
      content: { overflowY: 'scroll', WebkitOverflowScrolling: 'touch' },
    };
    if (busy && isLoggedIn) {
      return <div className="P-20">Loading...</div>;
    }
    return (
      <ReactSidebar
        styles={sidebarStyles}
        sidebar={
          isLoggedIn ? (
            <Sidebar path={pathname} />
          ) : (
            <SidebarPublic path={pathname} />
          )
        }
        open={sidebarOpen}
        docked={sidebarDocked}
        shadow={false}
        contentClassName="wrapper"
        onSetOpen={this.onSetSidebarOpen.bind(this)}
      >
        <StickyContainer>
          <MessageBar />
          <Header
            logout={this.logout.bind(this)}
            isLoggedIn={isLoggedIn}
            toggleSidebar={this.toggleSidebar.bind(this)}
            docked={sidebarDocked}
          />
          <div className="content-area">
            <div className="container-fluid">
              {this.props.children}
              <Alerts />
            </div>
            <div className="mobile-test" />
          </div>
          <Footer />
        </StickyContainer>
      </ReactSidebar>
    );
  }
}
export default connect((state) => ({
  isLoggedIn: state.loginState.login.isLoggedIn,
  app: state.app,
}))(TwoColumn);
