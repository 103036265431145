import styled from '@emotion/styled';

export const RenderedOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const OptionImage = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

export const OptionContainer = styled.div`
  padding: 0.5rem;
  border: 1px solid #cb2128;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: background-color 0.3s;
  cursor: pointer;
  &:hover,
  &.selected {
    border-color: #f36c71;
    background-color: #424242;
  }
`;
export const OptionsListContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const QuestionGrid = styled.div`
  display: grid;
  height: 100%;
  overflow: auto;
  grid-template:
    'questions' auto
    'actions' 40px /
    auto;
  grid-gap: 1rem;
`;
