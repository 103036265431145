import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import cx from 'classnames';

import * as resultsActions from 'ducks/results';
import * as earningsActions from 'ducks/earnings';

import Loader from 'components/common/Loader';

import './styles.scss';

const interestList = [
  'Genre',
  'Cast/Talent',
  'Setting/World',
  'Era/Time Period',
  'Plot',
  'Creators (Director/Producer)',
  'Characters Relationships',
  'Soundtrack/Music',
  'Tone',
  'Pace',
  'Style',
  'Action',
  'Suspense',
  'Thrills',
  'Comedy',
  'Scares',
  'Special Effects/CGI',
];

class InterestDrivers extends Component {
  static propTypes = {
    busy: PropTypes.bool,
    result: PropTypes.object,
    dispatch: PropTypes.func,
    sendExtraFeedback: PropTypes.func,
  };

  state = {
    interests: [],
    comment: '',
  };

  componentWillMount() {
    const {
      params: { mediaId, itemId },
      dispatch,
    } = this.props;

    dispatch(
      resultsActions.getSummaryResults(mediaId, itemId, 'interestdrivers')
    );
  }

  componentWillUnmount() {
    const { dispatch, sendExtraFeedback } = this.props;
    const { interests, comment } = this.state;

    // Send feedback only if any interest is selected
    if (interests.length > 0) {
      sendExtraFeedback(
        'interestdrivers',
        {
          interests,
          comment,
        },
        () => {
          dispatch(earningsActions.getAll());
        }
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const { busy } = this.props;

    if (busy && !nextProps.busy && nextProps.summary) {
      this.setState(
        nextProps.summary.asMutable({
          deep: true,
        })
      );
    }
  }

  toggleInterest = (interest) => () => {
    const { interests } = this.state;
    const interestIndex = interests.indexOf(interest);

    if (interestIndex === -1) {
      interests.push(interest);
    } else {
      interests.splice(interestIndex, 1);
    }

    this.setState({ interests });
  };

  handleChange = (event) => {
    this.setState({
      comment: event.target.value,
    });
  };

  render() {
    const { interests, comment } = this.state;
    const { busy } = this.props;

    return (
      <div className="interest-drivers">
        <h1>Interest Drivers</h1>
        <div className="row first">
          <div className="col-sm-12">
            <div className="Mb-15">
              Please select all tags that drove your interest in the video.
            </div>
            {busy ? (
              <Loader />
            ) : (
              interestList.map((interest, i) => (
                <div
                  key={interest}
                  onClick={this.toggleInterest(interest)}
                  className={cx('tag D-ib Mr-20', {
                    selected: interests.indexOf(interest) > -1,
                  })}
                >
                  {interest}
                </div>
              ))
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="Mb-15">
              Based on the tags selected please provide more detail about what
              specifically drove your interest in the video.
            </div>
            {busy ? (
              <Loader />
            ) : (
              <textarea
                maxLength="1000"
                style={{ width: '100%', height: '125px' }}
                placeholder="Write your feedback here..."
                value={comment}
                onChange={this.handleChange}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  busy: state.results.busy,
  summary: state.results.summary.interestdrivers,
});

export default compose(withRouter, connect(mapStateToProps))(InterestDrivers);
