import React, { useCallback } from 'react';
import { MediaData, MediaItemData } from 'types/media';
import {
  VideoSurveyOrchestrator,
  VideoSurveyOrchestratorProps,
} from './VideoSurveyOrchestrator';
import { SurveyResult } from 'modules/survey-volkno/types';
import { useDispatch } from 'react-redux';
import { submitSurvey } from 'ducks/volkno-surveys/volkno-surveys';
import { useMediaSurveys } from './useMediaSurveys';
interface NewVideoPageProps {
  nextSubpage: (...args: any[]) => any;
  media: MediaData;
  item: MediaItemData;
}

export const VideoSurvey: React.FC<
  NewVideoPageProps & VideoSurveyOrchestratorProps
> = ({
  item,
  media,
  height,
  width,
  videoTimeLocation,
  pauseVideo,
  playVideo,
  children,
}) => {
  const { surveys } = useMediaSurveys(item);
  const dispatch = useDispatch();
  const onSurveyCompleted = useCallback(
    (surveyId: string, result: SurveyResult) => {
      dispatch(
        submitSurvey({
          surveyId,
          result,
          mediaId: media.media_id,
          mediaItemId: item.media_item_id,
        })
      );
    },
    [dispatch, media.media_id, item.media_item_id]
  );

  return (
    <VideoSurveyOrchestrator
      surveys={surveys}
      onSurveyCompleted={onSurveyCompleted}
      pauseVideo={pauseVideo}
      playVideo={playVideo}
      height={height}
      width={width}
      videoTimeLocation={videoTimeLocation}
    >
      {children}
    </VideoSurveyOrchestrator>
  );
};
