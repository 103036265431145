import { messageActions } from 'modules/react-message';
import apiFetch from 'utils/fetch';
import Immutable from 'seamless-immutable';

const cfg = process.env;

// action types
export const actionTypes = {
  IS_BUSY: 'volkno/earnings/IS_BUSY',
  UPDATE_EARNINGS: 'volkno/earnings/UPDATE_EARNINGS',
  ALERT_SHOWN: 'volkno/earnings/ALERT_SHOWN',
  MANUAL_ALERT: 'volkno/earnings/MANUAL_ALERT',
};

// reducers
const initialState = Immutable({
  busy: false,
  total: 0,
  list: [], // array to define order of earnings
  data: {}, // map to lookup earning data by unique code: "HOT_OR_NOT_53" -> { ... }
  group: {}, // map to lookup all earnings by id: 53 -> ["HOT_OR_NOT_53", "TAG_IT_53"],
  alerts: {
    list: [],
    data: {},
  },
  showAlerts: [],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.IS_BUSY:
      return state.set('busy', action.payload);

    case actionTypes.ALERT_SHOWN:
      let arr = state.showAlerts.asMutable();
      let index = arr.indexOf(action.payload);
      arr.splice(index, 1);
      return state.set('showAlerts', arr);

    case actionTypes.MANUAL_ALERT:
      let { alert_id, code, data } = action.payload;
      let showAlertsRaw = state.showAlerts.asMutable({ deep: true });
      let alertsRaw = state.alerts.asMutable({ deep: true });
      showAlertsRaw.push(alert_id);
      if (!alertsRaw.data[alert_id]) {
        alertsRaw.list.push(alert_id);
        alertsRaw.data[alert_id] = {
          alert_id,
          code,
          data,
        };
      }
      return state.set('alerts', alertsRaw).set('showAlerts', showAlertsRaw);

    case actionTypes.UPDATE_EARNINGS:
      return state.merge(
        {
          total: action.payload.total,
          list: action.payload.list,
          data: action.payload.data,
          group: action.payload.group,
          alerts: action.payload.alerts,
          showAlerts: action.payload.showAlerts,
          busy: false,
        },
        { deep: true }
      );

    default:
      return state;
  }
}

// actions
// TODO: does alerts belong in its own duck? YES
export function manualAlert(code, data) {
  return {
    type: actionTypes.MANUAL_ALERT,
    payload: {
      alert_id: 'tmp_' + Date.now(),
      code,
      data,
    },
  };
}
export function alertShown(alertId, code, silent) {
  if (silent) {
    return {
      type: actionTypes.ALERT_SHOWN,
      payload: alertId,
    };
  }
  const payload = alertId
    ? {
        alertId,
      }
    : {
        code,
      };
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/alerts`,
      body: payload,
    };
    apiFetch('post', options, dispatch)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: actionTypes.ALERT_SHOWN,
            payload: resp.json.alertId,
          });
        } else {
          dispatch(messageActions.displayError(resp.json.error));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
export function getAll(cb = () => {}) {
  return (dispatch) => {
    dispatch(messageActions.clearAll());
    dispatch({ type: actionTypes.IS_BUSY, payload: true });
    const options = {
      url: `${cfg.REACT_APP_API_BASE}/earnings`,
    };
    apiFetch('get', options, dispatch)
      .then((resp) => {
        if (resp.status === 200) {
          const results = {
            total: 0,
            list: [],
            data: {},
            group: {},
            alerts: resp.json.alerts,
            showAlerts: [],
          };
          // TODO: maybe move this kind of massaging to api? YES - alerts logic here only depends on earnings total
          resp.json.earnings.forEach((row) => {
            let uniqueCode = row.audience_id + '^' + row.code;
            if (row.media_item_id) {
              uniqueCode += '^' + row.media_item_id;
            } else if (row.debit) {
              uniqueCode += '^' + row.earning_id;
            }
            results.total += row.credit + -row.debit;
            results.list.push(uniqueCode);
            results.data[uniqueCode] = row;
            // look up via media_item_id
            if (row.media_item_id) {
              let groupCode = row.audience_id + '^' + row.media_item_id;
              if (!Array.isArray(results.group[groupCode])) {
                results.group[groupCode] = [];
              }
              results.group[groupCode].push(uniqueCode);
            }
          });
          // handle alerts
          let alertLen =
            (results.alerts.list && results.alerts.list.length) || 0;
          for (let i = alertLen - 1; i >= 0; i--) {
            let alrt = results.alerts.data[results.alerts.list[i]];
            if (alrt.flow_amount <= results.total) {
              results.showAlerts.push(alrt.alert_id);
            }
          }
          results.showAlerts.reverse();
          dispatch({ type: actionTypes.UPDATE_EARNINGS, payload: results });
          cb();
        } else {
          dispatch(messageActions.displayError(resp.json.error));
        }
      })
      .catch((error) => {
        dispatch(messageActions.displayError(error));
      });
  };
}
