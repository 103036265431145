/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import cx from 'classnames';
import get from 'lodash/get';

import { getDevice, getOrientation } from 'utils';
import * as resultsActions from 'ducks/results';
import * as mediaActions from 'ducks/media';
import * as earningsActions from 'ducks/earnings';

import OverallRating from 'components/common/OverallRating';
import Counter from 'components/common/Counter';
import Loader from 'components/common/Loader';

import Actions from './Actions';

import './styles.scss';
import { PosterImage } from 'components/common/PosterImage';

class Summary extends React.Component {
  static propTypes = {
    restart: PropTypes.func,
    media: PropTypes.object,
    item: PropTypes.object,
    app: PropTypes.object,
    recommended: PropTypes.array,
    results: PropTypes.object,
    earnings: PropTypes.object,
    query: PropTypes.object,
    cintURLs: PropTypes.object,
  };

  state = {
    busy: false,
  };

  componentWillMount() {
    const { dispatch, media, item } = this.props;

    dispatch(
      resultsActions.getSummaryResults(
        media.media_id,
        item.media_item_id,
        'overall-rating'
      )
    );
  }

  addFav = () => {
    const { dispatch, media } = this.props;

    dispatch(mediaActions.addFavorite(media.media_id, media.audience_media_id));
  };

  delFav = () => {
    const { dispatch, media } = this.props;

    dispatch(mediaActions.delFavorite(media.media_id, media.audience_media_id));
  };

  onRate = (rating) => {
    const { dispatch } = this.props;

    this.setState({ busy: true });
    this.sendExtraFeedback(
      'overall-rating',
      {
        rating,
      },
      () => {
        this.setState({ busy: false }, () => {
          dispatch(earningsActions.getAll());
          dispatch(
            resultsActions.setLocalResults('overall-rating', { rating })
          );
        });
      },
      () => {
        this.setState({ busy: false });
      }
    );
  };

  sendExtraFeedback = (
    key,
    value,
    sCallback = () => {},
    fCallback = () => {}
  ) => {
    const { media, item, dispatch } = this.props;

    dispatch(
      resultsActions.send(
        {
          media_id: media.media_id,
          media_item_id: item.media_item_id,
          poster_id: media.poster_id,
          page_path: window.location.pathname,
          device: getDevice(),
          device_orientation: getOrientation(),
          results: [
            {
              code: 'SUMMARY',
              challenge_key: key,
              challenge_value: value,
              media_time_secs: 0,
              page_location: 'video_summary',
            },
          ],
        },
        sCallback,
        fCallback,
        true
      )
    );
  };

  render() {
    const {
      recommended,
      restart,
      media,
      item,
      results,
      earnings,
      query,
      cintURLs,
      app: { genres = {}, audienceId },
    } = this.props;
    const { busy } = this.state;
    const { RID: externalUserSessionId } = query; // from CINT (external/public users)
    const mediaId = media.media_id;
    const recommendedItemId = recommended[0]?.items?.list[0];
    const ratingValue = get(results.summary['overall-rating'], 'rating', 5);
    const resultsBusy = results.busy;
    const genre =
      genres.data &&
      genres.data[media.genres[0]] &&
      genres.data[media.genres[0]].name;
    let itemReached = false;
    const itemIndex = media.items.list.indexOf(item.media_item_id);
    const flowEarned = (
      earnings.group[`${audienceId}^${item.media_item_id}`] || []
    ).reduce((prev, curr) => prev + earnings.data[curr].credit, 0);

    return (
      <div>
        <div className="media-summary">
          <div className="Px-30 Py-40">
            <div className="row">
              <div className="col-sm-5">
                <div className="row">
                  <div className="col-4">
                    <PosterImage
                      alt=""
                      src={media.poster_url}
                      overlayText={
                        media.secure_urls
                          ? btoa(this.props.app?.user?.user_id)
                          : null
                      }
                    />
                    {externalUserSessionId ? null : (
                      <>
                        {media.favorite ? (
                          <div className="fav">
                            <i
                              onClick={this.delFav}
                              className="fa fa-star Cur-p"
                            />
                            Favorited
                          </div>
                        ) : (
                          <div className="fav">
                            <i
                              onClick={this.addFav}
                              className="fa fa-star-o Cur-p"
                            />
                            Mark as Favorite
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-8">
                    <div className="Fz-18 C-000 Fw-b">{media.title}</div>
                    <div className="C-000 Mb-20">
                      {media.release_date.split('-')[0]} | {genre}
                    </div>

                    <Actions sendExtraFeedback={this.sendExtraFeedback} />
                  </div>
                </div>
              </div>

              <div className="col-sm-7">
                <div className="row">
                  {externalUserSessionId ? null : (
                    <>
                      <div className="col-sm-6">
                        <div className="flow-earned">
                          <strong>
                            {results.busy || earnings.busy ? (
                              <Loader />
                            ) : (
                              <Counter
                                begin={0}
                                end={flowEarned}
                                time={500}
                                easing="outQuart"
                              />
                            )}
                          </strong>
                          Total flow earned for this round
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="round-info">
                          <div className="out-of">
                            <em>{itemIndex + 1}</em> of{' '}
                            {media.items.list.length}
                          </div>
                          <div className="Px-50">
                            Totals items completed from this collection
                          </div>
                          <div className="dots">
                            {media.items.list.map((itemId) => {
                              const active = !itemReached;
                              if (itemId === item.media_item_id) {
                                itemReached = true;
                              }
                              return (
                                <span
                                  key={'dot_' + itemId}
                                  className={cx({ active: active })}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="Px-10">
                  {externalUserSessionId ? null : <hr />}
                  <h4>Rate this Video</h4>

                  {!resultsBusy && (
                    <OverallRating
                      onRate={this.onRate}
                      value={ratingValue}
                      disabled={busy}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="summary-actions Ta-c">
            <span className="link D-ib Mr-20" onClick={restart}>
              <i className="fa fa-undo" /> Replay
            </span>

            {externalUserSessionId ? (
              <Link
                className="btn btn-primary mobile-100"
                href={cintURLs.successURL}
              >
                Complete
              </Link>
            ) : (
              <>
                {media.items.list[itemIndex + 1] && (
                  <Link
                    className="btn btn-primary mobile-100 Mr-20"
                    onClick={restart}
                    to={`/media/${mediaId}/${media.items.list[itemIndex + 1]}`}
                  >
                    Next Video <i className="fa fa-forward" />
                  </Link>
                )}

                {!media.items.list[itemIndex + 1] &&
                recommended.length > 0 &&
                recommendedItemId ? (
                  <Link
                    className="btn btn-primary mobile-100 Mr-20"
                    onClick={restart}
                    to={`/media/${recommended[0].media_id}/${recommendedItemId}`}
                  >
                    Next Title <i className="fa fa-play" />
                  </Link>
                ) : (
                  <Link
                    className="btn btn-primary mobile-100 Mr-20"
                    onClick={restart}
                    to="/"
                  >
                    Back to Explore
                  </Link>
                )}
              </>
            )}
          </div>
        </div>

        {recommended?.length > 0 && !externalUserSessionId && (
          <div className="media-upcoming">
            <div className="row">
              <div className="col-12 up-next">
                <strong>Other Videos</strong>
              </div>
            </div>
            <div className="row">
              {recommended.map((r) => (
                <div key={'rec_' + r.media_id} className="col-4">
                  <Link
                    to={`/media/${r.media_id}/${r.items.list[0]}`}
                    onClick={restart}
                  >
                    <PosterImage
                      overlayText={
                        r.secure_urls
                          ? btoa(this.props.app?.user?.user_id)
                          : null
                      }
                      alt=""
                      src={r.poster_url}
                    />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  results: state.results,
  earnings: state.earnings,
  cintURLs: state.loginState.login.cintURLs,
});

export default connect(mapStateToProps)(Summary);
