import { strings as reactLoginStrings } from 'modules/react-login';

export default {
  SUCCESS_example: 'Delete this example success message',
  SUCCESS_user_updated: 'Settings updated!',
  SUCCESS_info_saved: 'Information saved!',
  ERROR_invalid_values: 'Invalid values passed to server',
  ERROR_no_db_connection: 'Database connection not established',
  ERROR_query_failed: 'An error occured. Try again later.',
  ERROR_duplicate_key: 'Key exists in database. Field must be unique.',
  ERROR_401: 'You have been logged out due to inactivity.',
  ERROR_bad_aws_resp_400:
    'We are currently experiencing issues ordering your gift card. Please try ordering again in 24 hours.',
  ERROR_file_upload_invalid_success_resp:
    'Invalid response from server (status: 200)',
  ERROR_file_upload_invalid_error_resp: 'Invalid error response from server',
  ERROR_form_errors: 'Please fix the forms before continuing.',
  ERROR_min_order_req:
    'You must meet the minimum order amount when ordering A La Carte items.',
  ERROR_registration_closed:
    'Registration is currently closed. Try again next week.',
  ERROR_invalid_audience_hash:
    'The campaign associated with your account is no longer valid. Please contact support.',
  ERROR_invalid_address_pin: 'The pin you entered is invalid or expired.',
  ERROR_address_updated_too_recently:
    'You are revalidating your address too often.',
  ERROR_address_verification_too_many_tries:
    'Sorry, you have exceeded the amount of failed pin verification attempts. You must start the process over again.',
  ERROR_address_in_use:
    'Sorry, this address is already assigned to another user. If this is incorrect, please contact support.',
  ERROR_address_not_validated:
    'You must validate your address before redeeming your Flow. Go to My Profile > Address Verification to get started.',
  ERROR_profile_required:
    'In order to redeem Flow, you must fill out your profile. Go to My Profile > Settings to get started.',

  ERROR_invalid_confirm_hash: 'The link is invalid, please try again.',
  ERROR_hash_already_used: 'This email link has expired.',
  ERROR_failed_to_activate: 'An error occured when confirming your account.',
  ERROR_failed_to_accept_terms:
    'An error occured when trying to accept terms. Please try again.',
  ERROR_store_closed: 'The store is currently closed. Please try again later.',
  ERROR_too_fast: 'You are giving feedback too quickly.',
  ERROR_max_orders:
    'You have ordered too many gift cards this month. Try again in a week.',
  ERROR_order_too_big: 'Your order is too big. Try a smaller amount.',

  ERROR_image_filetype_error:
    'Only jpg, gif or png are accepted for profile image.',
  ERROR_image_upload_error:
    'An error occured while trying to upload your profile image. Try a different format.',
  ERROR_image_filesize_error: 'Profile image file size cannot exceed 500 kb.',
  ERROR_invalid_media_id: 'Media not found.',
  ERROR_invalid_media_item_id: 'Media item not found.',

  ...reactLoginStrings,
};
