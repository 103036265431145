import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { withFormsy } from 'formsy-react';
import formsyErrorFix from './formsyErrorFix';
import cx from 'classnames';

@formsyErrorFix
class Select extends Component {
  static propTypes = {
    children: PropTypes.any,
    name: PropTypes.string.isRequired,
    setValue: PropTypes.func,
    getErrorText: PropTypes.func,
    optionRenderer: PropTypes.func,
    valueRenderer: PropTypes.func,
    getValue: PropTypes.func,
    isFormDisabled: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    title: PropTypes.any,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    allowCreate: PropTypes.bool,
    multi: PropTypes.bool,
    searchable: PropTypes.bool,
    clearable: PropTypes.bool,
    dataKey: PropTypes.string,
    onChange: PropTypes.func,
  };
  onDDSelect(val) {
    this.props.setValue(val);
    if (typeof this.props.onChange !== 'undefined') {
      this.props.onChange(val);
    }
  }
  shouldComponentUpdate(nextProps) {
    if (this.props.dataKey !== nextProps.dataKey) {
      this.props.setValue('');
    }
    return true;
  }
  getOptions() {
    const { children, dataKey } = this.props;
    if (dataKey) {
      return children[dataKey] || children.default;
    }
    return children.hasOwnProperty('default') ? children.default : children;
  }
  render() {
    const {
      getValue,
      name,
      title,
      className,
      getErrorText,
      isFormDisabled,
      disabled,
      valueRenderer,
      clearable = false,
      allowCreate,
      multi,
      placeholder,
      optionRenderer,
    } = this.props;
    const hasError = getErrorText();
    const id = name + '-dd';
    const searchable = this.props.hasOwnProperty('searchable')
      ? this.props.searchable
      : true;
    const moreOptions = {};
    if (optionRenderer) {
      moreOptions.optionRenderer = optionRenderer;
    }
    return (
      <div
        className={cx('form-group', className, { 'has-error': hasError })}
        data-test={name}
      >
        <label htmlFor={id}>{title}</label>
        <ReactSelect
          name={id}
          value={getValue()}
          options={this.getOptions()}
          placeholder={placeholder || 'Select ' + title}
          onChange={this.onDDSelect.bind(this)}
          clearable={clearable}
          searchable={searchable}
          allowCreate={allowCreate}
          multi={multi}
          backspaceRemoves={false}
          valueRenderer={valueRenderer}
          disabled={isFormDisabled() || disabled}
          {...moreOptions}
        />
        <div className="validation-error">{hasError}</div>
      </div>
    );
  }
}
export default withFormsy(Select);
