import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import DemandIt from '../DemandIt';
import InterestDrivers from '../InterestDrivers';
import TagIt from '../TagIt';
import Comment from '../Comment';

import './styles.scss';

const ActionsComponent = ({ sendExtraFeedback }) => {
  const [state, setState] = React.useState({ open: false, modalType: '' });

  const openModal = React.useCallback(
    (type) => () => {
      setState({
        open: true,
        modalType: type,
      });
    },
    [setState]
  );

  const closeModal = () => {
    setState({
      open: false,
    });
  };

  const { open, modalType } = state;

  return (
    <div className="misc-actions">
      <div>
        <button className="btn btn-default" onClick={openModal('demand_it')}>
          <span className="sprite sprite-demand-it D-ib Va-m Mx-2" />
          Demand It
        </button>

        <button
          className="btn btn-default"
          onClick={openModal('interest_drivers')}
        >
          <span className="sprite sprite-flame-grey D-ib Va-m Mx-2" />
          Interest Drivers
        </button>

        <button className="btn btn-default" onClick={openModal('tag_it')}>
          <span className="sprite sprite-tag-it D-ib Va-m" />
          Tag It
        </button>

        <button className="btn btn-default" onClick={openModal('comment')}>
          <span className="sprite sprite-comment D-ib Va-m" />
          Comment
        </button>
      </div>

      <Modal
        show={open}
        size="lg"
        className="actions-modal"
        onHide={closeModal}
      >
        <Modal.Body>
          <button
            type="button"
            onClick={closeModal}
            className="close modal-close-btn"
          >
            <span aria-hidden="true">×</span>
          </button>

          {modalType === 'demand_it' && (
            <DemandIt sendExtraFeedback={sendExtraFeedback} />
          )}

          {modalType === 'interest_drivers' && (
            <InterestDrivers sendExtraFeedback={sendExtraFeedback} />
          )}

          {modalType === 'tag_it' && (
            <TagIt sendExtraFeedback={sendExtraFeedback} />
          )}

          {modalType === 'comment' && (
            <Comment
              sendExtraFeedback={sendExtraFeedback}
              closeModal={closeModal}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

ActionsComponent.propTypes = {
  sendExtraFeedback: PropTypes.func,
};

export default ActionsComponent;
