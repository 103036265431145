import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginCheckAction } from 'modules/react-login';

export default function requireAuth(Component) {
  class AuthComponent extends React.Component {
    static propTypes = {
      isLoggedIn: PropTypes.bool,
      dispatch: PropTypes.func,
      app: PropTypes.object,
    };
    static contextTypes = {
      url: PropTypes.object,
      router: PropTypes.object,
    };
    componentWillMount() {
      this.redirectCheck(this.props);
      // validate token once on load, rely on api 401 responses from here on out
      this.props.dispatch(loginCheckAction());
    }
    componentWillReceiveProps(nextProps) {
      this.redirectCheck(nextProps);
    }
    redirectCheck(props) {
      const { terms } = props.app.user;
      if (!props.isLoggedIn) {
        window.localStorage.setItem('nextUrl', window.location.pathname);
        this.context.router.push(`/welcome`);
      } else if (!terms && props.location.pathname !== '/terms-accept') {
        this.context.router.push(`/terms-accept`);
      }
    }
    render() {
      if (!this.props.isLoggedIn) {
        return null;
      }
      return <Component {...this.props} />;
    }
  }

  const mapStateToProps = (state) => ({
    isLoggedIn: state.loginState.login.isLoggedIn,
    app: state.app,
  });

  return connect(mapStateToProps)(AuthComponent);
}
