import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input } from 'modules/react-forms';
import * as createActions from '../../ducks/create';
import { Link } from 'react-router';
import { formatErrors } from 'modules/utils-validation';

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginState.login.isLoggedIn,
  isLoggingIn: state.loginState.login.isLoggingIn,
  open: state.open,
});
export class CreateView extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    isLoggingIn: PropTypes.bool,
    path: PropTypes.string,
    app: PropTypes.string,
    open: PropTypes.object,
  };
  static contextTypes = {
    url: PropTypes.object,
    router: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      confirmEmail: false,
    };
  }
  handleCreateCall(data, resetForm, handleErrors) {
    const { dispatch, app } = this.props;
    const host = window.location.protocol + '//' + window.location.hostname;
    const returnURL = host + '/welcome?action=login&confirm=%TOKEN%';
    const onSuccess = () => {
      this.setState({
        confirmEmail: true,
      });
    };
    const onFailure = (resp) => {
      if (resp.status === 406) {
        handleErrors(formatErrors(resp.json.errorData));
      }
    };
    data.app = app;
    dispatch(createActions.createCall(data, returnURL, onSuccess, onFailure));
  }
  render() {
    const { isLoggedIn, isLoggingIn, open } = this.props;
    const { confirmEmail } = this.state;
    if (confirmEmail) {
      return (
        <div className="row">
          <h1>Confirm your email</h1>
          <p>
            You will receive an email from us momentarily. Please click the link
            in that email to activate your account.
          </p>
        </div>
      );
    } else if (isLoggingIn) {
      return <div className="row">Logging in...</div>;
    } else if (open.busy) {
      return <div className="row">Please wait...</div>;
    } else if (!open.isOpen) {
      return (
        <div className="row">
          <h1>Registration Closed</h1>
          <p>
            Sorry, we are not allowing more users to join at this time. Please
            check back weekly for openings.
          </p>
          <p>Existing users can sign in below:</p>
          <Link to="/welcome?action=login" className="btn btn-primary My-10">
            SIGN IN
          </Link>
        </div>
      );
    } else if (isLoggedIn) {
      const nextUrl = window.localStorage.getItem('nextUrl');
      window.localStorage.removeItem('nextUrl');
      this.context.router.push(nextUrl || '/');
      return (
        <div className="row Ta-c Py-30">
          <div>You are logged in!</div>
          Redirecting to: <Link to="/">/</Link>
        </div>
      );
    } else {
      return (
        <div>
          <Form onValidSubmit={this.handleCreateCall.bind(this)}>
            <div className="row">
              <div className="col-sm-12">
                <div className="us-only">
                  <img
                    alt=""
                    src="/images/us-flag.svg"
                    height="12"
                    width="16"
                  />
                  US residents only
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Input
                  type="input"
                  title="Email Address"
                  name="email"
                  iconClass="fa fa-envelope-o"
                  value={undefined}
                  validations="EMAIL"
                  validationError="Enter valid email"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Input
                  type="password"
                  title="Password"
                  name="password"
                  iconClass="fa fa-lock"
                  maxLength={16}
                  value={undefined}
                  validations="PASSWORD"
                  validationError="Enter valid password"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <button type="submit" className="btn btn-primary My-10">
                  Create Account
                </button>
              </div>
            </div>
            {/*<div className="row">*/}
            {/*  <div className="col-sm-12 Ta-c Mb-10 Mt-20 C-777 Fz-11">*/}
            {/*    or Sign in with*/}
            {/*  </div>*/}
            {/*</div>*/}
          </Form>
          {/*<div className="row">*/}
          {/*  <div className="col-sm-12">*/}
          {/*    <FacebookLogin />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      );
    }
  }
}

export default connect(mapStateToProps)(CreateView);
