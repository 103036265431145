export enum SurveyQuestionType {
  MultipleChoice = 'multiple_choice',
  LongText = 'long_text',
  ShortText = 'short_text',
  NumericInput = 'numeric_input',
}

export interface SurveyOption {
  id: string;
  message?: string;
  image?: string;
}

export interface SurveyQuestion {
  id: string;
  message: string;
  type: SurveyQuestionType;
  options?: SurveyOption[];
}

function isTypeOfQuestion<T extends SurveyQuestion>(type: SurveyQuestionType) {
  return function (question: SurveyQuestion): question is T {
    return question.type === type;
  };
}

export interface MultipleChoiceQuestion extends SurveyQuestion {
  type: SurveyQuestionType.MultipleChoice;
  options: SurveyOption[];
  randomize?: boolean;
  minAnswersSelected?: number;
  maxAnswersSelected?: number;
}

export const isMultipleChoiceQuestion = isTypeOfQuestion<
  MultipleChoiceQuestion
>(SurveyQuestionType.MultipleChoice);

export interface NumericInputQuestion extends SurveyQuestion {
  type: SurveyQuestionType.NumericInput;
  multipleOf?: number;
  min?: number;
  max?: number;
}

export const isNumericInputQuestion = isTypeOfQuestion<NumericInputQuestion>(
  SurveyQuestionType.NumericInput
);

export interface SurveyConfig {
  id: string;
  questions: SurveyQuestion[];
}

export type MultiChoiceAnswer = string[];
export type NumericInputAnswer = number;
export type TextAnswer = string;
export type SurveyAnswer = MultiChoiceAnswer | NumericInputAnswer | TextAnswer;
export interface SurveyResult {
  [questionId: string]: SurveyAnswer;
}
