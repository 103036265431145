import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';

// formsy hidden element

class InputHidden extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    getValue: PropTypes.func,
  };
  render() {
    const { getValue, name } = this.props;
    return (
      <input type="hidden" name={name} value={getValue()} data-test={name} />
    );
  }
}

export default withFormsy(InputHidden);
