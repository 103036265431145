import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Circle } from 'rc-progress';
import './styles.scss';
import { PosterImage } from 'components/common/PosterImage';

let tm;

class Description extends Component {
  static propTypes = {
    nextSubpage: PropTypes.func,
    media: PropTypes.object,
  };

  state = {
    percent: 0,
  };

  componentDidMount() {
    this.increase();

    this.to = setTimeout(() => {
      this.props.nextSubpage();
    }, 8000);
  }

  componentWillUnmount() {
    clearTimeout(this.to);
    clearTimeout(tm);
  }

  increase = () => {
    let percent = this.state.percent + 0.18;

    if (percent > 100) {
      percent = 100;
      clearTimeout(tm);
      return;
    }

    this.setState({ percent });
    tm = setTimeout(this.increase, 10);
  };

  render() {
    const { media, nextSubpage, isLoggedIn } = this.props;
    const { percent } = this.state;
    const user_id = this.props.app?.user?.user_id;

    return (
      <div className="media-desc page-margin">
        <div className="desc-box">
          <PosterImage
            alt=""
            src={media.poster_url}
            overlayText={media.secure_urls ? btoa(user_id) : null}
          />
          <div className="box-info">
            <div className="W-50 Mx-a">
              <Circle
                percent={percent}
                strokeWidth="8"
                strokeColor="#fff"
                trailWidth="8"
                trailColor="#6b6767"
              />
            </div>
            <div className="Fz-22 Mb-5">Now loading:</div>
            <div className="Fz-18">{media.title}</div>
          </div>
        </div>

        <div className="instructions">
          <strong>Remember!</strong> Provide feedback anytime during the video
          by pressing hot or not and the emojis to best describe what you
          like/dislike and how your feel.
        </div>

        <p className="Ta-c">
          <span
            className={cx('link', { 'V-h': !isLoggedIn })}
            onClick={isLoggedIn ? nextSubpage : () => {}}
          >
            Let's go!
          </span>
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  isLoggedIn: state.loginState.login.isLoggedIn,
});

export default connect(mapStateToProps)(Description);
