import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import * as appActions from 'ducks/app';
import * as mediaActions from 'ducks/media';

class CampaignUnlocked extends React.Component {
  static propTypes = {
    app: PropTypes.object,
    close: PropTypes.func,
  };
  static contextTypes = {
    router: PropTypes.object,
  };
  constructor(props) {
    super(props);
    const {
      app: { audiences = {} },
    } = props;
    const newAudienceId = (audiences.list || []).find(
      (userAudienceId) => !audiences.data[userAudienceId].notified
    );
    const { hash } = audiences.data[newAudienceId || audiences.list[0]];
    this.state = {
      alertModalOpen: false,
      hash,
    };
  }
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(
      appActions.audienceNotified(() => {
        dispatch(appActions.get());
      })
    );
  }
  goToCampaign() {
    const { dispatch, close } = this.props;
    const { hash } = this.state;
    dispatch(mediaActions.clear());
    dispatch(appActions.setAudience(hash));
    this.context.router.push('/?r=' + Date.now());
    close();
  }
  render() {
    return (
      <div>
        <h1 className="Mt-30 Ta-c">
          <i className="fa fa-unlock-alt" />
          Congratulations!
          <br />
        </h1>
        <div className="Fz-22">You've unlocked a new campaign!</div>
        <Modal.Footer>
          <div className="alert-actions">
            <button
              className="btn btn-primary"
              onClick={this.goToCampaign.bind(this)}
            >
              Go to Campaign
            </button>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}
export default connect((state) => ({
  app: state.app,
}))(CampaignUnlocked);
