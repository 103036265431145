import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../ducks/login';
import { Link } from 'react-router';
import cx from 'classnames';
import ForgotView from './views/ForgotView';
import LoginView from './views/LoginView';
import ResetView from './views/ResetView';
import CreateView from './views/CreateView';

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginState.login.isLoggedIn,
});
export class CoreLayout extends React.Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    path: PropTypes.string,
    action: PropTypes.string.isRequired,
    app: PropTypes.string.isRequired,
    dispatch: PropTypes.func,
  };
  static defaultProps = {
    path: '/welcome',
  };
  static contextTypes = {
    url: PropTypes.object,
  };
  logOut = () => {
    this.props.dispatch(logout());
  };
  render() {
    const { path, app, action } = this.props;

    let childView;
    switch (action) {
      case 'forgot':
        childView = <ForgotView path={path} app={app} />;
        break;
      case 'reset':
        childView = <ResetView path={path} app={app} />;
        break;
      case 'login':
        childView = <LoginView path={path} app={app} />;
        break;
      default:
        childView = <CreateView path={path} app={app} />;
    }
    return (
      <div className="login-box">
        <div className="login-tabs">
          <Link
            className={cx({ selected: action === 'create' })}
            to={`${path}?action=create`}
          >
            Create Account
          </Link>
          <Link
            className={cx({ selected: action !== 'create' })}
            to={`${path}?action=login`}
            data-cy="sign-in-tab-btn"
          >
            Sign In
          </Link>
        </div>
        <div className="login-container">{childView}</div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(CoreLayout);
