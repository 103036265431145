import { combineReducers } from 'redux';
import login from './login';
import forgot from './forgot';
import reset from './reset';
import create from './create';

export default combineReducers({
  login,
  forgot,
  reset,
  create,
});
