import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import cx from 'classnames';

import './styles.scss';

const defaultTags = [
  // neg
  'Hate It',
  'Awful',
  'Flop',
  // neutral
  'Ok',
  'Like It',
  'Alright',
  // pos
  'Love It',
  'Excellent',
  'Amazing',
];
const extendedTags = [
  // neg
  'Hate It',
  'Boring',
  'Stupid',
  'Weak',
  'Annoying',
  'Flop',
  // neutral
  'Like It',
  'Ok',
  'Mediocre',
  'Interesting',
  'Silly',
  'Basic',
  // pos
  'Love It',
  'Awesome',
  'Funny',
  'Inspiring',
  'Creative',
  'Hit',
];

class ChallengeTagIt extends Component {
  static propTypes = {
    location: PropTypes.string,
    tags: PropTypes.array,
    extended: PropTypes.bool,
    tagsPerCol: PropTypes.number,
    syncTags: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    extended: false,
    tagsPerCol: 3,
  };

  constructor(props) {
    super(props);

    const { tagsPerCol } = props;
    const tags = props.extended ? extendedTags : defaultTags;
    this.state = {
      tagConfig: [
        {
          type: 'negative',
          tags: tags.slice(0, tagsPerCol),
        },
        {
          type: 'neutral',
          tags: tags.slice(tagsPerCol, tagsPerCol * 2),
        },
        {
          type: 'positive',
          tags: tags.slice(tagsPerCol * 2, tagsPerCol * 3),
        },
      ],
    };
  }

  handleChange = (tags, changed, changedIndexes) => {
    const { syncTags } = this.props;

    syncTags(tags);
    const action = tags[changedIndexes[0]] !== changed[0] ? 'removed' : 'added';
    this.addFeedback(action, changed[0]);
  };

  addFeedback(action, tag) {
    const { location, onChange } = this.props;

    if (typeof onChange === 'function') {
      onChange('TAG_IT', action, { tag }, location);
    }
  }

  toggleTag(tag) {
    const { tags, syncTags } = this.props;
    const tagIndex = tags.indexOf(tag);

    if (tagIndex === -1) {
      this.addFeedback('added', tag);
      tags.push(tag);
    } else {
      this.addFeedback('removed', tag);
      tags.splice(tagIndex, 1);
    }

    syncTags(tags);
  }

  render() {
    const { tags, extended } = this.props;
    const { tagConfig } = this.state;

    return (
      <div className="challenge-tagit slidein-onhover">
        <div className="row mobile-0px-margin">
          {!extended && (
            <div className="col-sm-6 mobile-5px-padding hide-fullscreen-mobile">
              <TagsInput
                onlyUnique
                maxTags={20}
                value={tags}
                onChange={this.handleChange}
                inputProps={{
                  className: 'react-tagsinput-input',
                  placeholder: '+ Type to add a tag',
                  maxLength: 30,
                }}
              />
            </div>
          )}

          <div
            className={cx('mobile-0px-padding', {
              'col-sm-6': !extended,
              'col-sm-12': extended,
            })}
          >
            <div className="row mobile-0px-margin tag-defaults">
              {tagConfig.map((tagCfg) => (
                <div
                  key={'tag_type_' + tagCfg.type}
                  className={cx('col-4 mobile-5px-padding', tagCfg.type)}
                >
                  {tagCfg.tags.map((tag, index) => (
                    <div
                      key={tagCfg.type + '_tag_' + index}
                      onClick={this.toggleTag.bind(this, tag)}
                      className={cx('tag', {
                        selected: tags.indexOf(tag) !== -1,
                      })}
                    >
                      {tag}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChallengeTagIt;
