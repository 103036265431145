import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import cx from 'classnames';
import * as surveysActions from 'ducks/surveys';
import './styles.scss';

const options = [
  {
    key: 'NEW',
    value: 'New',
  },
  {
    key: 'COMPLETE',
    value: 'Complete',
  },
];

class Surveys extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    surveys: PropTypes.object,
  };
  static contextTypes = {
    url: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: 'NEW',
    };
  }
  handleClick(selectedKey) {
    this.setState({
      tabSelected: selectedKey,
    });
  }
  componentWillMount() {
    this.props.dispatch(surveysActions.getSurveys());
  }
  render() {
    const { surveys } = this.props;
    const { tabSelected } = this.state;
    const surveyList = surveys.list.reduce((prev, surveyId) => {
      const sData = surveys.data[surveyId];
      const completed = !!sData.completed;
      if (
        (tabSelected === 'COMPLETE' && completed) ||
        (tabSelected === 'NEW' && !completed)
      ) {
        prev.push(
          <tr key={`survey_${surveyId}`}>
            <td colSpan={2}>
              <div className="row">
                <div className="col-md-7 mobile-center">
                  <h4>{sData.title}</h4>
                </div>
                <div className="col-md-5 Ta-r mobile-center earnings">
                  {completed ? (
                    <i className="fa fa-check-circle Mr-10" />
                  ) : null}
                  <span className="Fw-b">{sData.flow} Flow</span>
                </div>
                <div className="col-md-12">
                  <p>{sData.description}</p>
                  <Link
                    to={`/surveys/${surveyId}`}
                    className={cx('btn mobile-100 My-20', {
                      'btn-primary': !completed,
                      'btn-info': completed,
                    })}
                  >
                    {completed ? 'Revisit' : 'Start'} Survey
                  </Link>
                </div>
              </div>
            </td>
          </tr>
        );
      }
      return prev;
    }, []);
    return (
      <div className="page-surveys page-margin">
        <h2>My Surveys</h2>
        <div className="biline">Complete surveys to earn more Flow!</div>
        <Nav
          variant="pills"
          activeKey={tabSelected}
          onSelect={this.handleClick.bind(this)}
        >
          {options.map((option) => (
            <NavItem key={'navkey_' + option.key} eventKey={option.key}>
              {option.value}
            </NavItem>
          ))}
        </Nav>
        {surveyList.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Survey Name</th>
                <th className="Ta-r">Flow</th>
              </tr>
            </thead>
            <tbody>{surveyList}</tbody>
          </table>
        ) : (
          <div className="Fz-18">
            {tabSelected === 'COMPLETE' ? (
              <span>You have not completed any surveys yet.</span>
            ) : (
              <span>There are no surveys for you to complete.</span>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default connect((state) => ({
  app: state.app,
  surveys: state.surveys,
}))(Surveys);
