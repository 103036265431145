import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as surveyActions from 'ducks/survey';
import { Form, Select, Input } from 'modules/react-forms';
import { formatErrors } from 'modules/utils-validation';
import * as earningsActions from 'ducks/earnings';
import Loader from 'components/common/Loader';
import Option from './Option';
import './styles.scss';
const defaultMostOften = [
  {
    value: 'Theater',
    label: 'Theater',
  },
  {
    value: 'TV',
    label: 'TV',
  },
  {
    value: 'Computer',
    label: 'Computer',
  },
  {
    value: 'Tablet',
    label: 'Tablet',
  },
  {
    value: 'Phone',
    label: 'Phone',
  },
  {
    value: 'Game console',
    label: 'Game console',
  },
  {
    value: 'Set-top box',
    label: 'Set-top box',
  },
  {
    value: 'Other',
    label: 'Some other way',
  },
];

class ProfilePreferences extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    survey: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      top3Shows: [],
      top3Movies: [],
      top3Podcasts: [],
    };
  }
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(surveyActions.getUserPrefs());
  }
  shouldComponentUpdate(nextProps) {
    if (this.props.survey.busy && !nextProps.survey.busy) {
      this.setFavoriteState(nextProps);
    }
    return true;
  }
  setFavoriteState(props) {
    let top3Shows = [];
    try {
      top3Shows = JSON.parse(props.survey.user_prefs.top_3_shows);
    } catch {}
    let top3Movies = [];
    try {
      top3Movies = JSON.parse(props.survey.user_prefs.top_3_movies);
    } catch {}
    let top3Podcasts = [];
    try {
      top3Podcasts = JSON.parse(props.survey.user_prefs.top_3_podcasts);
    } catch {}

    this.setState({
      top3Shows,
      top3Movies,
      top3Podcasts,
    });
  }
  updateFavorite(key, index, value) {
    const newVal = { ...this.state };
    if (!newVal[key]) {
      newVal[key] = [];
    }
    newVal[key][index] = value;
    this.setState(newVal);
  }
  handleSubmit(data, resetForm, handleErrors) {
    const payload = {
      content_sources: data.content_sources.map((o) => (o.value ? o.value : o)),
      content_sources_6_mos: data.content_sources_6_mos.map((o) =>
        o.value ? o.value : o
      ),
      favorite_content: data.favorite_content.map((o) =>
        o.value ? o.value : o
      ),
      streaming_platforms: data.streaming_platforms.map((o) =>
        o.value ? o.value : o
      ),
      most_often: data.most_often,
    };
    payload.top_3_shows = this.state.top3Shows;
    payload.top_3_movies = this.state.top3Movies;
    payload.top_3_podcasts = this.state.top3Podcasts;

    const { dispatch } = this.props;
    function onSuccess() {
      dispatch(earningsActions.getAll());
    }
    function onFailure(resp) {
      if (resp.status === 406) {
        handleErrors(formatErrors(resp.json.errorData));
      }
    }
    dispatch(surveyActions.updateUserPrefs(payload, onSuccess, onFailure));
  }
  render() {
    const {
      survey: { user_prefs = {}, busy },
    } = this.props;
    const mostOftenDb = JSON.parse(user_prefs.most_often || '[]');
    const mostOften = mostOftenDb.length
      ? mostOftenDb.map((s) => ({
          value: s,
          label: s,
        }))
      : defaultMostOften;

    if (busy) {
      return <Loader />;
    }
    return (
      <Form
        onValidSubmit={this.handleSubmit.bind(this)}
        className="profile-preferences"
      >
        <p className="Fz-20 Mb-40">
          Tell us about yourself to earn flow and help us optimize your
          experience!
        </p>
        <div className="row Mb-20">
          <div className="col-sm-8">
            <Select
              title="What's your favorite content?"
              name="favorite_content"
              placeholder="Please select up to 5"
              value={JSON.parse(user_prefs.favorite_content || '[]')}
              multi
              valueRenderer={(o) => <span>{o.value}</span>}
              clearable
            >
              {[
                {
                  value: 'Movies',
                  label:
                    'Movies (e.g., The Godfather, Pretty Woman, ET, Finding Nemo, etc.)',
                },
                {
                  value: 'TV Shows',
                  label:
                    'TV Shows: (Family Guy, Breaking Bad, Pretty Little Liars, etc.)',
                },
                {
                  value: 'TV Networks',
                  label: 'TV Networks: (HBO, AMC, FX, etc.)',
                },
                {
                  value: 'Digital',
                  label:
                    'Digital Shows from digital networks like Hulu, Netflix, YouTube Red or Amazon',
                },
                {
                  value: 'Other Online',
                  label: 'Other online videos (e.g., YouTube, Vimeo, Twitch)',
                },
                {
                  value: 'Books',
                  label:
                    'Books: (Hunger Games, Outliers, Good to Great, Ready Player One)',
                },
                {
                  value: 'Games',
                  label: 'Games: (Warcraft, Mario Cart, Minecraft, Fallout)',
                },
              ]}
            </Select>
          </div>
        </div>
        <div className="row Mb-20">
          <div className="col-sm-8">
            <Select
              title="Which of the following streaming platforms do you regularly use to watch TV shows or movies?"
              name="streaming_platforms"
              placeholder="Select all that apply"
              value={JSON.parse(user_prefs.streaming_platforms || '[]')}
              multi
              valueRenderer={(o) => <span>{o.value}</span>}
              clearable
            >
              {[
                {
                  value: 'Roku',
                  label: 'Roku',
                },
                {
                  value: 'HBO Max',
                  label: 'HBO Max',
                },
                {
                  value: 'Disney+',
                  label: 'Disney+',
                },
                {
                  value: 'Hulu',
                  label: 'Hulu',
                },
                {
                  value: 'Paramount+',
                  label: 'Paramount+',
                },
                {
                  value: 'Discovery+',
                  label: 'Discovery+',
                },
                {
                  value: 'Amazon Prime Video',
                  label: 'Amazon Prime Video',
                },
                {
                  value: 'Peacock',
                  label: 'Peacock',
                },
                {
                  value: 'CBS All Access',
                  label: 'CBS All Access',
                },
                {
                  value: 'PlutoTV',
                  label: 'PlutoTV',
                },
                {
                  value: 'Tubi',
                  label: 'Tubi',
                },
                {
                  value: 'AppleTV+',
                  label: 'AppleTV+',
                },
                {
                  value: 'Netflix',
                  label: 'Netflix',
                },
                {
                  value: 'Other',
                  label: 'Other',
                },
              ]}
            </Select>
          </div>
        </div>
        <div className="row Mb-50">
          <div className="col-sm-8">
            <label>
              How often do you watch these types of video content?
              <br />
              <em>
                Please rank them by how frequent you watch each one. The top
                being the most frequent.
              </em>
            </label>
            <Option value={mostOften} />
          </div>
        </div>
        <div className="row Mb-20">
          <div className="col-sm-8">
            <Select
              title="In the past 3 months, where have you watched content?"
              name="content_sources"
              placeholder="Please select all that apply."
              value={JSON.parse(user_prefs.content_sources || '[]')}
              multi
              valueRenderer={(o) => <span>{o.value}</span>}
              clearable
            >
              {[
                {
                  value: 'Broadcast TV',
                  label: 'Broadcast TV network (e.g., ABC, NBC or FOX)',
                },
                {
                  value: 'Basic Cable TV',
                  label: 'Basic cable network (e.g., AMC, TNT or MTV)',
                },
                {
                  value: 'Premium Cable TV',
                  label: 'Premium cable subscription (e.g., HBO, Showtime)',
                },
                {
                  value: 'On-Demand / Pay-Per-View',
                  label: 'Cable video on demand or pay-per-view',
                },
                {
                  value: 'Subscription Service',
                  label:
                    'Internet subscription service (e.g., Netflix, Amazon Prime, Hulu)',
                },
                {
                  value: 'Digital Purchase',
                  label:
                    'Digital download or purchase to own (e.g., Amazon Instant Video or iTunes)',
                },
                {
                  value: 'Free Streaming',
                  label:
                    'Free digital streaming - (e.g., YouTube, Vimeo, Twitch)',
                },
                {
                  value: 'Rented DVD/Blu-ray',
                  label: 'Rented on DVD or Blu-ray',
                },
                {
                  value: 'Purchased DVD/Blu-ray',
                  label: 'Purchased on DVD or Blu-ray',
                },
                {
                  value: 'Recorded',
                  label: 'Recorded on a DVR',
                },
                {
                  value: 'Other',
                  label: 'None of the above',
                },
              ]}
            </Select>
          </div>
        </div>
        <div className="row Mb-20">
          <div className="col-sm-8">
            <Select
              title="In the past 6 months, which of the following ways have you used to watch video content?"
              name="content_sources_6_mos"
              placeholder="Please select all that apply."
              value={JSON.parse(user_prefs.content_sources_6_mos || '[]')}
              multi
              valueRenderer={(o) => <span>{o.value}</span>}
              clearable
            >
              {[
                {
                  value: 'Theater',
                  label: 'Movie theater',
                },
                {
                  value: 'Computer',
                  label: 'Desktop or laptop computer',
                },
                {
                  value: 'Tablet',
                  label: 'Tablet',
                },
                {
                  value: 'Phone',
                  label: 'Mobile phone',
                },
                {
                  value: 'Gaming console',
                  label: 'Gaming console (e.g., Xbox or Playstation)',
                },
                {
                  value: 'Set-top box',
                  label:
                    'Set-top box (e.g., cable, satellite or other TV service including devices like Roku and Apple TV)',
                },
              ]}
            </Select>
          </div>
        </div>
        <div className="row Mb-20">
          <div className="col-sm-8">
            <Input
              title="What are your favorite three current series or TV shows you are watching right now? Current series are shows that are currently still airing new episodes. Please enter at least 1 and no more than 3 TV shows."
              name="top3Shows1"
              value={this.state?.top3Shows?.[0] || undefined}
              setValue={(value) => {
                this.updateFavorite('top3Shows', 0, value);
              }}
              type="text"
              placeholder="Enter series or tv show"
              validations="TEXT"
              validationError="Enter first favorite show"
            />
            <Input
              name="top3Shows2"
              value={this.state?.top3Shows?.[1] || undefined}
              setValue={(value) => {
                this.updateFavorite('top3Shows', 1, value);
              }}
              type="text"
              placeholder="Enter series or tv show"
              validations="TEXT"
            />
            <Input
              name="top3Shows3"
              value={this.state?.top3Shows?.[2] || undefined}
              setValue={(value) => {
                this.updateFavorite('top3Shows', 2, value);
              }}
              type="text"
              placeholder="Enter series or tv show"
              validations="TEXT"
            />
          </div>
        </div>
        <div className="row Mb-20">
          <div className="col-sm-8">
            <Input
              title="What are the top 3 movies you are most looking forward to in the next 6 months? Please enter at least 1 and no more than 3 movies coming out in the next 6 months that you are looking forward to seeing."
              name="top3Movies1"
              value={this.state.top3Movies?.[0] || undefined}
              setValue={(value) => {
                this.updateFavorite('top3Movies', 0, value);
              }}
              type="text"
              placeholder="Enter favorite movie"
              validations="TEXT"
              validationError="Enter first favorite movie"
            />
            <Input
              name="top3Movies2"
              value={this.state.top3Movies?.[1] || undefined}
              setValue={(value) => {
                this.updateFavorite('top3Movies', 1, value);
              }}
              type="text"
              placeholder="Enter favorite movie"
              validations="TEXT"
            />
            <Input
              name="top3Movies3"
              value={this.state.top3Movies?.[2] || undefined}
              setValue={(value) => {
                this.updateFavorite('top3Movies', 2, value);
              }}
              type="text"
              placeholder="Enter favorite movie"
              validations="TEXT"
            />
          </div>
        </div>
        <div className="row Mb-20">
          <div className="col-sm-8">
            <Input
              title="What are your favorite three podcasts to listen to right now? Please enter at least 1 and no more than 3 TV shows. Leave blank if you don't have any."
              name="top3Podcasts1"
              value={this.state.top3Podcasts?.[0] || undefined}
              setValue={(value) => {
                this.updateFavorite('top3Podcasts', 0, value);
              }}
              type="text"
              placeholder="Enter favorite podcast"
              validations="TEXT"
              validationError="Enter first favorite podcast"
            />
            <Input
              name="top3Podcasts2"
              value={this.state.top3Podcasts?.[1] || undefined}
              setValue={(value) => {
                this.updateFavorite('top3Podcasts', 1, value);
              }}
              type="text"
              placeholder="Enter favorite podcast"
              validations="TEXT"
            />
            <Input
              name="top3Podcasts3"
              value={this.state.top3Podcasts?.[2] || undefined}
              setValue={(value) => {
                this.updateFavorite('top3Podcasts', 2, value);
              }}
              type="text"
              placeholder="Enter favorite podcast"
              validations="TEXT"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <button type="submit" className="btn btn-primary My-10 mobile-100">
              Save
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

export default connect((state) => ({
  survey: state.survey,
}))(ProfilePreferences);
