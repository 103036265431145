import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

class Profile extends React.Component {
  static propTypes = {
    close: PropTypes.func,
  };
  static contextTypes = {
    router: PropTypes.object,
  };
  goToProfile() {
    this.props.close();
    setTimeout(() => {
      this.context.router.push(`/profile`);
    }, 1000);
  }
  render() {
    return (
      <div>
        <h1 className="Mt-30">You're almost there!</h1>
        <p>
          Fill out your <strong>Profile</strong> now and receive bonus flow, and
          you'll have enough for a <strong>$5 gift card</strong>!
        </p>
        <Modal.Footer>
          <div className="alert-actions">
            <button
              className="btn btn-primary"
              onClick={this.goToProfile.bind(this)}
            >
              Fill out Profile
            </button>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}
export default connect()(Profile);
