import React from 'react';
import { Link } from 'react-router';
import './styles.scss';

export default function () {
  return (
    <div className="page-privacy">
      <div className="page-margin">
        <h2>Privacy Policy</h2>

        <p className="Ta-r Mt-30">
          <b>
            <i>Effective Date</i>
          </b>
          : May 03, 2022
        </p>

        <p>
          <b>
            <u>VolKno Inc.</u>
          </b>
        </p>

        <p>
          This Privacy Policy ("<b>Policy</b>") describes how VolKno, Inc. and
          its affiliates ("Company" or "we" or "our") treat information
          collected or provided in connection with an end user's ("you" or
          "user") use of our website located at http://www.volkno.com (the{' '}
          <b>"Site"</b>).{' '}
        </p>

        <p>
          <b>
            1.<span></span>
          </b>
          <b>
            <u>Effective Date; Changes</u>
          </b>
          . This Policy applies to all information collected by or provided to
          us in connection with the Site. When we make any material changes to
          this Policy, we will change the Effective Date above and will inform
          you by e-mail sent to the address associated with your Site account
          and/or through the Site the next time you login. We will treat your
          continued use of the Site following such notice as your acceptance of
          the changes.{' '}
        </p>

        <p>
          <b>
            2.<span></span>
          </b>
          <b>
            <u>Third Parties</u>
          </b>
          . The Site may contain, and we may send to registered users, offers
          from third parties or links to third party websites. This Policy does
          not apply to information that you may provide to or that may be
          collected by third parties. We encourage you to request that such
          third parties provide you with their applicable privacy policies and
          other terms and conditions before engaging with or making a purchase
          from such third parties.{' '}
        </p>

        <p>
          <b>
            3.<span></span>
          </b>
          <b>
            <u>Collected Information</u>
          </b>
        </p>

        <p>
          <b>Information You Submit. </b>When you log into or create an account
          with the Site, we collect the information you provide in the
          applicable form. This information includes personally identifiable
          information such as your e-mail address and password. Information you
          submit to the Site also includes your responses to and interactions
          with content that you view on the Site, which will include such
          personally identifiable information as you may choose to include. When
          we refer to "personally identifiable information" in this Policy, we
          mean information that we can directly associate with a specific person
          or entity without additional information. To the extent you decline to
          share certain personally identifiable information with us, we may not
          be able to provide some or all of the features and functionalities
          found on the Site.
        </p>

        <p>
          <b>Information Collected Automatically</b>. When you use the Site, we
          automatically gather certain non-personally identifiable information
          from you. This includes usage information, such as information on
          when, how often and for how long you use the Site and the content you
          view. This also includes server log data such as the IP address,
          browser type, or device ID of your computer or mobile device, or the
          webpage you were visiting before you came to our website. As described
          below, we collect this information through a number of means,
          including server logs, cookies and other online tracking mechanisms.
          We may associate such non-personally identifiable information with the
          personally identifiable information that you provide in connection
          with the Site. But if we do so, we will treat the combined information
          as personally identifiable information.{' '}
        </p>

        <p>
          <b>Social Networking Information. </b>You may create a Site account by
          logging in through a supported social networking site such as Facebook
          (such sites, "Social Services"). Integration with Social Services
          allows you to automatically populate your Site profile with
          information and content from your Social Service account profile. We
          will use, store and disclose such information and content in
          accordance with this Policy. Please remember that the manner in which
          Social Services use, store and disclose your information and content
          (including with respect to any choices you may have to limit or
          control disclosure to us) is governed by the terms and conditions and
          privacy policies of each Social Service and any applicable privacy
          settings you may be able to control, and not the Company.
        </p>

        <p>
          <b>Retailer Information</b>. When you use Flow you have earned on the
          Site to obtain gift cards and then use those gift cards to make
          purchases from the applicable retailer, we may receive information
          from the retailer regarding what you purchased. We may use that
          information in the same way as we use any information submitted by you
          on the Site.{' '}
        </p>

        <p>
          <b>Online Tracking and Cookies. </b>The Site may use web beacons,
          cookies and other online tracking mechanisms to collect information.
          "Beacons" (also often referred to as "pixels") are tiny graphics on a
          web page (or in an e-mail that includes web content) designed to track
          when a page is viewed. "Cookies" are small text files containing a
          string of alphanumeric characters that are downloaded by your web
          browser or mobile device when you visit a website. We may use both
          session cookies and persistent cookies when you access and use the
          Site. Cookies may be used, for example, to remember your user account
          information and your preferences, to customize the interface of the
          Site for you and to assist us in measuring and analyzing Site traffic.
          A session cookie disappears after you close your browser. A persistent
          cookie remains after you close your browser and may be used by your
          browser on subsequent visits to the Site. Persistent cookies can be
          removed. Please review your browser's documentation to learn the best
          way to modify your cookie settings. You can take steps to limit
          tracking by erasing cookies from your computer's hard drive and by
          setting your browser to block all cookies or warn you before a cookie
          is stored. We partner with certain third parties to collect
          non-personally identifiable information. These third parties may use
          web logs, web beacons or other online tracking mechanisms, and they
          may set and access cookies on your computer or other device. Means of
          opting out of this third-party data collection are discussed below in
          paragraph 8.{' '}
        </p>

        <p>
          <b>Analytics</b>. We use certain third party services to assist us in
          analysis, auditing, research, and reporting regarding the Site. These
          third parties may use web logs or web beacons, and they may set and
          access cookies on your computer or other device. In particular, we use
          Google Analytics to help collect and analyze certain information for
          the purposes discussed in this Policy. You may opt out of the use of
          cookies by Google Analytics at<u> </u>
          <span>
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>
          </span>
          .
        </p>

        <p>
          <b>Data Privacy and Data Management</b>. You may delete your account
          or request deletion of your collected data at any time. Learn more
          about it at <Link to="/data-management">Data Management</Link>
        </p>

        <p>
          <b>
            4.<span></span>
          </b>
          <b>
            <u>Creation and Use of User Profiles. </u>
          </b>
          We analyze, process and use information (including information
          regarding your interactions with and responses to content and
          information we receive from retailers, as described above) to create a
          profile that includes inferences about your personal characteristics,
          behaviors, likes and dislikes. We may then use that information to
          determine what content you see on the Site and to analyze how
          different categories of users respond to different kinds of content
          available on our Site. For example, we may conclude based on the data
          that we have that a given user is likely to be a female between the
          ages of 34 and 55 who is a college graduate, and then analyze whether
          similar users react the same way to a given piece of content. We may
          then provides the results of such analyses to the owners of content on
          our Site and others, although we would not provide raw data concerning
          your interactions with and responses to content to third parties in a
          manner that identifies you personally (other than as set forth in
          paragraphs 7 and 13 below). We may also use and disclose profile
          information for advertising purposes as outlined paragraph 8 below.{' '}
        </p>

        <p>
          <b>
            5.<span></span>
          </b>
          <b>
            <u>Use of Information</u>
          </b>
        </p>

        <p>
          We use the personally identifiable and non-personally identifiable
          information as follows:
        </p>

        <p>
          &bull; We will use submitted information for the purposes for which
          you provided the information, including, for example, to create and
          maintain a Site account for you or respond to a question that you
          e-mail to us. We also use submitted information and collected
          information as necessary to provide the features and functionality of
          the Site to you.
        </p>

        <p>
          &bull; We may send you a newsletter and/or other e-mail communications
          regarding the Site.{' '}
        </p>

        <p>
          &bull; We may analyze collected information relating to your use of
          the Site in order to help us improve the Site and develop and improve
          other products and services.{' '}
        </p>

        <p>
          &bull; We may use submitted information and collected information to
          help troubleshoot problems, provide you with required notices, enforce
          our Terms of Service or to alert you to changes in our policies or
          agreements that may affect your use of the Site.
        </p>

        <p>
          &bull; We may aggregate and analyze non-personally identifiable Site
          content submitted by you and other users, and distribute and publicize
          these analyses on the Site and elsewhere.
        </p>

        <p>
          &bull; We may combine personally identifiable information collected
          through the Site with other information that we collect about you in
          other contexts'such as our communications with you via e-mail, phone
          or postal mail. We will handle such combined information in accordance
          with this Policy.
        </p>

        <p>
          <b>
            6.<span></span>
          </b>
          <b>
            <u>How We Disclose Information</u>
          </b>
          .{' '}
        </p>

        <p>
          We disclose the personally identifiable and non-personally
          identifiable information that we collect about you as follows:{' '}
        </p>

        <p>
          &bull; To the extent you post comments, profile information, or other
          content on the Site, it will be viewable by other Site users in
          accordance with the Site's functionality.
        </p>

        <p>
          &bull; We may provide advertisers and other third parties with
          aggregated, non-personally identifiable information about our user
          base and its usage patterns.{' '}
        </p>

        <p>
          &bull; We may disclose non-personally identifiable information to
          third party partners in furtherance of our business arrangements with
          them, including without limitation to jointly offer a product or
          service to you or create interoperability between our products and
          services and the products and services of such partners.{' '}
        </p>

        <p>
          &bull; The Company uses contractors and third party service providers
          to provide the Site to our users and to help us provide services in
          connection with the Site. For example, we use third parties to help us
          target and implement our email communications and direct mailings.
          Such third parties may receive information from us for use for such
          purposes.
        </p>

        <p>
          &bull; We also may share your personal or non-personal information
          with other third parties when necessary to fulfill your requests for
          services; to complete a transaction that you initiate; or to meet the
          terms of any agreement that you have with us or our partners.
        </p>

        <p>
          &bull; We may report aggregated, de-identified data relating to
          activity on this Site to third parties or the general public. We may
          combine such data with data obtained from third party sources in order
          to generate such reports.{' '}
        </p>

        <p>
          <b>
            7.<span></span>
          </b>
          <b>
            <u>Legal Requirements and Other Special Circumstances</u>
          </b>
          . We will use and disclose information where we, in good faith,
          believe that the law or legal process (such as a court order, search
          warrant or subpoena) requires us to do so; to investigate, prevent, or
          take action regarding illegal activities, suspected fraud, or
          violations of our terms and conditions; or in other circumstances
          where we believe is necessary to protect the rights or property of
          Company, our users, and third parties.
        </p>

        <p>
          <b>
            8.<span></span>
          </b>
          <b>
            <u>Online Behavioral Advertising</u>
          </b>
          . The Site may enable third-party tracking mechanisms to collect your
          non-personally identifiable information for use in online behavioral
          advertising. For example, third parties may use the fact that you
          visited the Site to target online ads for Company services to you on
          non-Company websites. In addition, our third-party advertising
          networks might use information relating to your use of the Site
          (including profile information we create as outlined in paragraph 4
          above) to help target Company and/or non-Company advertisements to
          when you visit non-Company websites. For information about behavioral
          advertising practices, including privacy and confidentiality, visit
          the{' '}
          <span>
            <a href="http://www.networkadvertising.org/understanding-online-advertising">
              Network Advertising Initiative website
            </a>
          </span>{' '}
          or the{' '}
          <span>
            <a href="http://www.aboutads.info/choices">
              Digital Advertising Alliance website
            </a>
          </span>
          .
        </p>

        <p>
          The use of online tracking mechanisms by third parties is subject to
          those third parties" own privacy policies, and not this Policy. If you
          prefer to prevent third parties from setting and accessing cookies on
          your computer, you may set your browser to block cookies.
          Additionally, you may remove yourself from the targeted advertising of
          companies within the Network Advertising Initiative by{' '}
          <span>
            <a href="http://www.networkadvertising.org/choices/">
              opting out here
            </a>
          </span>
          , or of companies participating in the Digital Advertising Alliance
          program by{' '}
          <span>
            <a href="http://www.aboutads.info/choices">opting out here</a>
          </span>
          . Like many websites, the Site does not currently respond to "do not
          track" browser headers, but you can limit tracking through these
          third-party programs by taking the steps discussed above.
        </p>

        <p>
          <b>
            9.<span></span>
          </b>
          <b>
            <u>Viewing and Amending Information</u>
          </b>
          . You can log into your Site account and view or amend your user
          account information at any time. You may also at any time delete your
          entire Site account by emailing us at{' '}
          <a href="mailto:accountdelete@volkno.com">accountdelete@volkno.com</a>
          .
          <br />
          Please note that while changes to your account information are
          reflected promptly in active user databases, our servers may retain
          previously provided information. Note that changes to, or deletion of,
          your Site account user information or does not affect any other
          information collected by the Company, which the Company may retain and
          continue to use or disclose in accordance with this Policy.{' '}
        </p>

        <p>
          <b>
            10.<span></span>
          </b>
          <b>
            <u>Choices Regarding Third Party Offers</u>
          </b>
          . To the extent we send you commercial e-mails regarding third party
          marketing or promotional materials, we will give you the ability to
          opt-out of receiving such e-mails in accordance with applicable law.
          We will not provide your personally identifiable information to third
          parties for their own marketing purposes without your consent.
        </p>

        <p>
          <b>
            11.<span></span>
          </b>
          <b>
            <u>Security</u>
          </b>
          . The personally identifiable information we collect about you is
          stored in limited access servers. We will maintain reasonable
          safeguards to protect the security of these servers and your
          personally identifiable information. However, no security measures are
          100% effective and we cannot guarantee the security of your personally
          identifiable information.{' '}
        </p>

        <p>
          <b>
            12.<span></span>
          </b>
          <b>
            <u>Transfer As Corporate Asset</u>
          </b>
          . In the event of a merger, sale of capital stock or assets,
          reorganization, consolidation or similar transaction involving
          Company, the information we possess (including personally identifiable
          information) shall be transferred as a corporate asset to the
          acquiring entity, provided that such entity will continue to handle
          such information in accordance with this Policy.{' '}
        </p>

        <p>
          <b>
            13.<span></span>
          </b>
          <b>
            <u>Transfer to the U.S. or other Countries</u>
          </b>
          . The Company is established in and uses facilities in the United
          States. Your information will be stored and processed in the United
          States or other countries where Company has facilities. By using the
          Site, you consent to the transfer of information outside of your
          country, even if your country has more rigorous data protection
          standards.
        </p>

        <p>
          <b>
            14.<span></span>
          </b>
          <b>
            <u>Children Under 13</u>
          </b>
          . The Company does not knowingly collect information from children
          under 13 and the Site is intended for adults over the age of 18. If
          you are a parent and believe that your child under the age of 13 has
          used the Site and provided personally identifiable information to us
          through the Site, please contact us at support@volkno.com and we will
          work to delete that Site account and any such personally identifiable
          information.{' '}
        </p>

        <p>
          <b>
            15.<span></span>
          </b>
          <b>
            <u>Contacting Us</u>
          </b>
          . If you have any questions about this Policy, please contact us at
          support@volkno.com or at:
        </p>

        <p>
          <span>
            <br />
          </span>
          VolKno, Inc.
          <span>
            <br />
          </span>
          Attn: Privacy Policy Inquiries
          <span>
            <br />
          </span>
          8033 Sunset Blvd. #359{' '}
          <span>
            <br />
          </span>
          West Hollywood, CA 90046
        </p>
      </div>
    </div>
  );
}
