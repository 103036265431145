import cookie from 'react-cookie';

import { logout as logoutAction } from '../modules/react-login/ducks/login';
import * as appActions from 'ducks/app';

export const getHeaders = (options, noCache = false) => {
  const noCacheHeaders = {};
  if (String(noCache) === 'true') {
    noCacheHeaders['x-apicache-bypass'] = true;
  }

  const authHeaders = {
    Authorization: 'Bearer',
    VolknoAudience: '',
  };

  // TODO: since it's a cookie now, arent extra headers unncessary?
  if (cookie.load('jwtToken')) {
    authHeaders['Authorization'] = 'Bearer ' + cookie.load('jwtToken');
  }

  if (cookie.load('audience')) {
    authHeaders['VolknoAudience'] = cookie.load('audience');
  }

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...authHeaders,
    ...(options.headers || {}),
    ...noCacheHeaders,
  };
};

export default function apiFetch(method, options, dispatch, noCache = false) {
  let url = typeof options === 'string' ? options : options.url;

  if (!url) {
    throw new Error('No url passed to fetch');
  }

  if (options.query) {
    url += '?';
    Object.keys(options.query).forEach(
      (key) => (url += key + '=' + options.query[key] + '&')
    );
  }

  return new Promise((resolve, reject) => {
    const fetchOptions = {
      method: method,
      headers: getHeaders(options, noCache),
      body: JSON.stringify(options.body),
    };

    fetch(url, fetchOptions)
      .then(
        (response) =>
          new Promise((jsonResolve, jsonReject) => {
            if (response.status === 401) {
              jsonReject(401);
            } else {
              response
                .json()
                .then((jsonData) => {
                  if (jsonData.error === 'invalid_audience_hash') {
                    dispatch(appActions.clearAudience());

                    setTimeout(() => {
                      window.location.reload(true);
                    }, 0);
                  }

                  jsonResolve({
                    status: response.status,
                    json: jsonData,
                  });
                })
                .catch(jsonReject);
            }
          })
      )
      .then(resolve)
      .catch((error) => {
        if (error === 401) {
          dispatch(logoutAction());
        } else {
          reject(error);
        }
      });
  });
}
